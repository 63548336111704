import Favico from 'favico.js'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { showNotification } from 'src/stores/actionCreators/notifications'
import {
  setNavigationNotificationList,
  setNavigationNotificationCount,
} from 'src/stores/actionCreators/navigationNotifications'
import { useTranslation } from 'react-i18next'
import { getYourOpenTasks, userGetUserData } from 'src/stores/actionCreators'
import { promisifyAction, permissionTo } from 'src/utils'
import { WEBSOCKETS_URL } from 'src/actionCable'
import { tasksPermissions } from '../TopNavigation.constants'

const favicon = new Favico({ animation: 'popFade' })

const TopNavigationUserChannel = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const showNotificationAsync = promisifyAction(dispatch, showNotification)

  const getAccountDataAsync = promisifyAction(dispatch, userGetUserData)

  const getYourOpenTasksAsync = promisifyAction(dispatch, getYourOpenTasks)

  useEffect(() => {
    if (permissionTo(tasksPermissions)) {
      getYourOpenTasksAsync()
    }
  }, [])

  useEffect(() => {
    const url = new URL(WEBSOCKETS_URL)
    url.protocol = url.protocol === 'https:' ? 'wss:' : 'ws:'
    const ws = new WebSocket(url)
    ws.onopen = function () {
      ws.send(
        JSON.stringify({
          command: 'subscribe',
          identifier: '{"channel":"UserChannel"}',
        })
      )
    }
    ws.onmessage = function (msg) {
      handleReceivedNotifications(JSON.parse(msg.data).message)
    }
  }, [])

  const handleReceivedNotifications = (response: any) => {
    if (response?.message_type === 'unread_notifications') {
      const {
        comments,
        count,
      }: {
        comments: INavigationNotification[]
        count: number
      } = response.message.data
      dispatch(setNavigationNotificationList(comments))
      dispatch(setNavigationNotificationCount(count))
      favicon.badge(count)
    } else if (response?.message_type === 'sync_shipment_groups') {
      showNotificationAsync({
        message: t(
          'account.tabs.shipments.notification.shipments_synced',
          'Existing shipments have been synced with your selected watch settings.'
        ),
        severity: 'success',
      })
      getYourOpenTasksAsync()
      getAccountDataAsync()
    }
  }

  return null
}

export default TopNavigationUserChannel
