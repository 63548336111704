import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { useTranslation } from 'react-i18next'

const AddressBookHeader = () => {
  const { t } = useTranslation()
  return (
    <Box mb={2}>
      <Typography
        variant="h3"
        children={t('address_book.page_heading', 'Addresses')}
      />
    </Box>
  )
}

export default AddressBookHeader
