import { useMemo } from 'react'
import { DateTime } from 'luxon'
import OverviewTableText from '../OverviewTableText'
import { getDisplayDate, getDateObjectFromISOString } from '../../utils'
import DateTimeCellDatePicker from './DateTimeCellDatePicker'
import { getCellIsEditable } from './utils'

const inputFormat = 'dd-MM-yyyy HH:mm'

export interface DateTimeCellProps {
  cell: {
    getValue: () => any
  }
  testId?: string
  minDate?: string
  maxDate?: string
  missingValueText?: string
  onClose?: (formattedSelectedValue: string | null) => void
  isLoading?: boolean
}

export const DateTimeCell = ({
  cell,
  testId = '',
  minDate = undefined,
  maxDate = undefined,
  missingValueText = 'Add',
  onClose,
  isLoading = false,
}: DateTimeCellProps) => {
  const value = cell.getValue() ? cell.getValue() : null

  const displayDate = getDisplayDate({ date: value })

  const onPickerClose = (selectedValue: DateTime | null) => {
    const formattedSelectedValue: string | null =
      selectedValue?.toFormat(inputFormat) ?? null

    const formattedValue: string | null = value
      ? getDateObjectFromISOString(value).toFormat(inputFormat)
      : null

    if (formattedSelectedValue === formattedValue) {
      return
    }

    if (onClose) {
      onClose(formattedSelectedValue)
    }
  }

  const cellIsEditable = useMemo(() => {
    return getCellIsEditable(cell)
  }, [cell])

  if (cellIsEditable) {
    return (
      <DateTimeCellDatePicker
        value={value}
        testId={testId}
        maxDate={maxDate}
        minDate={minDate}
        onClose={onPickerClose}
        inputFormat={inputFormat}
        displayDate={displayDate}
        isLoading={isLoading}
        addButtonText={missingValueText}
      />
    )
  }
  return <OverviewTableText data-testid={testId} text={displayDate} />
}
