import {
  FunctionComponent,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react'
import Typography from '@mui/material/Typography'
import { Paper, Button } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { stringify, parse } from 'query-string'
import { History, LocationState } from 'history'
import { pickBy, cloneDeep, isEmpty, merge, keys } from 'lodash'
import { useTranslation } from 'react-i18next'
import Dropzone from 'react-dropzone'
import Tabs from 'src/stories/Tabs'
import Input from 'src/components/Common/Input'
import PurchaseOrdersTable from 'src/components/PurchaseOrders/PurchaseOrdersTable'
import PurchaseOrdersFilters from 'src/components/PurchaseOrders/PurchaseOrdersFilters'
import TableActions from 'src/components/PurchaseOrders/TableActions'
import PurchaseModalWindow from 'src/components/PurchaseOrders/PurchaseModalWindow'
import Pagination from 'src/components/Common/Pagination'
import BlankState from 'src/components/Common/BlankState'
import ConfirmDialog from 'src/components/ConfirmDialog'
import InlineNotification from 'src/components/Common/InlineNotification'
import UserChannelClient from 'src/components/SocketHandlers/UserChannelClient'
import useLocalStorage from '../../hooks/useLocalStorage'

import {
  purchaseOrdersGetData,
  purchaseOrdersGetFiltersData,
  purchaseOrdersGetMeta,
  purchaseOrdersOpenItem,
  incotermsGetData,
  addressesGetData,
  purchaseOrdersClear,
  countriesGetNotFormattedCountries,
  deletePurchaseOrder,
  clearAddModalCount,
  purchaseOrderDismissStatuses,
} from '../../stores/actionCreators'
import { promisifyAction } from '../../utils'

import { tabList } from './tabList'

import './styles.scss'

export interface IPurchaseFilters {
  scope: string
  search: string
  order_by: string
  seller: any[]
  pol: any[]
  exceptions: string[]
  statuses: string[]
  purchase_orders_per_page: number
  page: number
  ex_factory_date_start: string
  ex_factory_date_end: string
  order_date_start: string
  order_date_end: string
  cargo_ready_date_start: string
  cargo_ready_date_end: string
}

const sortingOptions = (t) => [
  {
    name: t('purchase_orders.sorting_options.date_asc', 'Order date'),
    value: 'order_date_asc',
    icon: 'sort-arrow',
  },
  {
    name: t('purchase_orders.sorting_options.date_desc', 'Order date'),
    value: 'order_date_desc',
    icon: 'sort-arrow down',
  },
  {
    name: t(
      'purchase_orders.sorting_options.cargo_ready_date_asc',
      'Cargo ready date'
    ),
    value: 'cargo_ready_date_asc',
    icon: 'sort-arrow',
  },
  {
    name: t(
      'purchase_orders.sorting_options.cargo_ready_date_desc',
      'Cargo ready date'
    ),
    value: 'cargo_ready_date_desc',
    icon: 'sort-arrow down',
  },
  {
    name: t('purchase_orders.sorting_options.newest_first', 'Newest first'),
    value: 'newest_first',
    icon: '',
  },
  {
    name: t('purchase_orders.sorting_options.last_updated', 'Last updated'),
    value: 'last_updated',
    icon: '',
  },
]

const initialFilters: IPurchaseFilters = {
  scope: 'pending',
  search: '',
  order_by: 'newest_first',
  purchase_orders_per_page: 20,
  page: 1,
  seller: [],
  exceptions: [],
  statuses: [],
  pol: [],
  ex_factory_date_start: '',
  ex_factory_date_end: '',
  order_date_start: '',
  order_date_end: '',
  cargo_ready_date_start: '',
  cargo_ready_date_end: '',
}

interface ISocketResponse {
  [x: string]: any
}

interface IProps {
  history: History<LocationState>
  location: Location
}

const PurchaseOrders: FunctionComponent<IProps> = (props) => {
  const { t } = useTranslation()
  const [isPurchaseModalOpen, setIsPurchaseModalOpen] = useState<boolean>(false)
  const [busy, setBusy] = useState<boolean>(true)
  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true)
  const [confirmDeleteIsOpen, setConfirmDeleteIsOpen] = useState<boolean>(false)
  const [filters, setFilters] = useState<IPurchaseFilters>(initialFilters)
  const [purchaseOrder, setPurchaseOrder] = useState<IPurchaseOrder | null>(
    null
  )
  const [uploadStatuses, setUploadStatuses] = useState<any>(null)
  const [filesArr, setFilesArr] = useState<File[]>([])
  const [isDropzoneActive, setIsDropzoneActive] = useState<boolean>(false)
  const dispatch = useDispatch()
  const getOrdersAsync = promisifyAction(dispatch, purchaseOrdersGetData)
  const getMetaAsync = promisifyAction(dispatch, purchaseOrdersGetMeta)
  const getFiltersDataAsync = promisifyAction(
    dispatch,
    purchaseOrdersGetFiltersData
  )
  const getIncotermsAsync = promisifyAction(dispatch, incotermsGetData)
  const clearModalCounter = promisifyAction(dispatch, clearAddModalCount)
  const addressesGetDataAsync = promisifyAction(dispatch, addressesGetData)
  const countriesGetDataAsync = promisifyAction(
    dispatch,
    countriesGetNotFormattedCountries
  )
  const purchaseOrderDismissStatusesAsync = promisifyAction(
    dispatch,
    purchaseOrderDismissStatuses
  )

  const [savedSorting, setSavedSorting] = useLocalStorage(
    'ordersSorting',
    filters.order_by
  )

  const deletePurchaseOrderAsync = promisifyAction(
    dispatch,
    deletePurchaseOrder
  )

  const {
    filtersData,
    scopesCount,
    openedPurchaseOrderId,
    ordersCount,
    triggerOrdersFetchCounter,
    triggerOpenAddCounter,
    configuredPOUpload,
    isBuyer,
    selectedLineIds,
  } = useSelector((state: IGlobalState) => ({
    filtersData: state.purchaseOrders.filtersData,
    scopesCount: state.purchaseOrders.scopesCount,
    openedPurchaseOrderId: state.purchaseOrders.openItem,
    ordersCount: state.purchaseOrders.totalCount,
    triggerOrdersFetchCounter: state.purchaseOrders.triggerOrdersFetchCounter,
    triggerOpenAddCounter: state.purchaseOrders.triggerOpenAddCounter,
    configuredPOUpload: state.user.configured_po_upload,
    isBuyer: state.user.can_buy,
    selectedLineIds: state.purchaseOrders.selectedLineIds,
  }))

  useEffect(() => {
    const locationSearch = parseLocationSearch()
    if (locationSearch.page) {
      locationSearch.page = parseInt(locationSearch.page)
    }

    if (!isEmpty(locationSearch)) {
      setFilters({
        ...filters,
        ...locationSearch,
      })
      if (locationSearch.open_item) {
        dispatch(purchaseOrdersOpenItem(parseInt(locationSearch.open_item)))
      }
    }
    const fetchInitialDataAsync = async () => {
      await getFiltersDataAsync()
      await getMetaAsync(pickBy(filters))
      await getIncotermsAsync()
      await addressesGetDataAsync()
      await countriesGetDataAsync()
    }

    fetchInitialDataAsync()
    return () => {
      dispatch(purchaseOrdersOpenItem(null))
    }
  }, [])

  useEffect(() => {
    props.history?.push({
      search: stringify(pickBy(filters), { arrayFormat: 'bracket' }),
    })
    const fetchOrdersAsync = async () => {
      if (isFirstLoad) {
        setIsFirstLoad(false)
      } else {
        setBusy(true)
        await getOrdersAsync(pickBy(filters))
        setBusy(false)
      }
      await getMetaAsync(pickBy(filters))
    }

    fetchOrdersAsync()
  }, [filters, isFirstLoad, triggerOrdersFetchCounter])

  useEffect(() => {
    props.history?.push({
      search: stringify(
        pickBy(merge(filters, { open_item: openedPurchaseOrderId })),
        { arrayFormat: 'bracket' }
      ),
    })
  }, [openedPurchaseOrderId])

  useEffect(() => {
    if (triggerOpenAddCounter) {
      setIsPurchaseModalOpen(true)
    }

    return function () {
      clearModalCounter()
    }
  }, [triggerOpenAddCounter])

  useEffect(() => {
    setSorting(savedSorting)
  }, [savedSorting])

  const parseLocationSearch = (): any => {
    return parse(props.location?.search, {
      arrayFormat: 'bracket',
    })
  }

  const updatedTabList = useMemo(() => {
    return cloneDeep(tabList(t)).map((tab: ITabProps) => {
      if (scopesCount) {
        tab.label = `${tab.label} (${scopesCount[tab.value]})`
      }
      return tab
    })
  }, [scopesCount, tabList])

  const setSorting = useCallback(
    async (sorting: string) => {
      setSavedSorting(sorting)
      setFilters({
        ...filters,
        order_by: sorting,
      })
    },
    [filters]
  )

  const onChangePage = (page: number): void => {
    setFilters({
      ...filters,
      page,
    })
  }

  const handleScopeChange = (value: string): void => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      scope: value,
      page: 1,
    }))
    dispatch(purchaseOrdersClear())
  }

  const handleFiltersChange = (data: any): void => {
    setFilters({
      ...filters,
      ...data,
      page: 1,
    })
  }

  const resetAllFilters = (): void => {
    setFilters({ ...initialFilters })
  }

  const resetFilter = (data: any): void => {
    setFilters({
      ...filters,
      ...data,
    })
  }

  const openPurchaseOrderModal = (): void => {
    setIsPurchaseModalOpen(true)
  }

  const onCloseWindow = (): void => {
    setIsPurchaseModalOpen(false)
    setPurchaseOrder(null)
    setFilesArr([])
  }

  const fetchData = async (): Promise<any> => {
    await getMetaAsync(pickBy(filters))
    await getFiltersDataAsync()
    await getOrdersAsync(pickBy(filters))
    await addressesGetDataAsync()
  }

  const setCurrentPurchaseOrder = async (
    purchaseOrder: IPurchaseOrder
  ): Promise<any> => {
    await setPurchaseOrder(purchaseOrder)
    openPurchaseOrderModal()
  }

  const deleteOpenedPurchaseOrder = async (
    purchaseOrder: IPurchaseOrder
  ): Promise<any> => {
    await setPurchaseOrder(purchaseOrder)
    setConfirmDeleteIsOpen(true)
  }

  const confirmDeletePurchaseOrder = async (): Promise<any> => {
    await deletePurchaseOrderAsync(purchaseOrder ? purchaseOrder.id : 0)
    setConfirmDeleteIsOpen(false)
    setPurchaseOrder(null)
    fetchData()
  }

  const onReceived = (data: ISocketResponse): void => {
    if (data.message_type === 'purchase_order_upload_update') {
      setUploadStatuses(data.message)
    }
  }

  const closeStatus = async (status: string): Promise<any> => {
    await purchaseOrderDismissStatusesAsync(status)
  }

  const refreshPage = (): void => {
    closeStatus('completed')
    fetchData()
  }

  const contactLink = (): void => {
    window.location.href = 'mailto:CS@shypple.com'
  }

  const displayUploadStatuses = (): React.ReactNode => {
    if (uploadStatuses) {
      return keys(uploadStatuses).map((status: string) => {
        switch (status) {
          case 'in_progress':
            const inProgressMessage: string = configuredPOUpload
              ? `${
                  (uploadStatuses['in_progress'] || []).length
                } orders are being processed. This will take approximately 3-5 minutes.`
              : 'Thanks for uploading your orders. We are processing your information and will let you know when you can start.'
            return (
              <InlineNotification
                color="info"
                show={true}
                showClose={true}
                onClose={closeStatus.bind(null, 'in_progress')}
                message={inProgressMessage}
              />
            )
          case 'completed':
            return (
              <InlineNotification
                color="success"
                show={true}
                showClose={true}
                action={refreshPage}
                actionName="Refresh"
                onClose={closeStatus.bind(null, 'completed')}
                message={`${uploadStatuses['completed'].length} out of ${uploadStatuses.total_uploads} orders have been processed.`}
              />
            )
          case 'failed':
            return (
              <InlineNotification
                color="error"
                show={true}
                showClose={true}
                action={contactLink}
                actionName="Contact Shypple"
                onClose={closeStatus.bind(null, 'failed')}
                message={`An error occurred while processing your orders: ${uploadStatuses['failed'][0].file_name}`}
              />
            )
          default:
            return <></>
        }
      })
    } else {
      return
    }
  }

  const onDrop = (files: File[]): void => {
    if (files != null && files.length > 0) {
      setFilesArr([...files, ...filesArr])
    }
    openPurchaseOrderModal()
    setIsDropzoneActive(false)
  }

  const onDragEnter = (): void => {
    setIsDropzoneActive(true)
  }

  const onDragLeave = (): void => {
    setIsDropzoneActive(false)
  }

  const blankStateIcons = (t) => [
    {
      icon: 'file',
      text: t('purchase_orders.empty_state.file_icon_text', 'Upload order'),
    },
    {
      icon: 'loading',
      text: t(
        'purchase_orders.empty_state.loading_icon_text',
        'Assign to shipment'
      ),
    },
    {
      icon: 'pencil',
      text: t(
        'purchase_orders.empty_state.pencil_icon_text',
        'Provide all booking details'
      ),
    },
  ]

  return (
    <article className="purchase-orders-page">
      <UserChannelClient onReceived={onReceived} />
      <Dropzone
        onDrop={onDrop}
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        multiple={true}
      >
        {({ getRootProps }) => (
          <section>
            <div {...getRootProps()} className="relative">
              {isDropzoneActive && (
                <div className="shipment-docs-page__documents-overlay">
                  <i className="icon folder" />
                  <div className="shipment-docs-page__documents-overlay--title">
                    {t(
                      'purchase_orders.dropzone.title',
                      'Release your documents'
                    )}
                  </div>
                  <div className="shipment-docs-page__documents-overlay--text">
                    {t(
                      'purchase_orders.dropzone.description',
                      'Add files by dropping them in this window'
                    )}
                  </div>
                </div>
              )}
              <header className="purchase-orders-page__header">
                <Typography
                  variant="h3"
                  children={t('purchase_orders.title', 'Order Management')}
                />
                <div>
                  {isBuyer && (
                    <Button
                      variant="contained"
                      onClick={openPurchaseOrderModal}
                      data-testid="add-purchase-order"
                    >
                      {t('purchase_orders.buyer.add_order_button', 'Add order')}
                    </Button>
                  )}
                </div>
              </header>
              <div className="purchase-orders-page__upload-statuses">
                {displayUploadStatuses()}
              </div>
              <Paper variant="elevation">
                <PurchaseOrdersFilters
                  busy={busy}
                  filtersValues={filters}
                  filtersOptions={filtersData}
                  setFilter={handleFiltersChange}
                  resetFilter={resetFilter}
                  resetAllFilters={resetAllFilters}
                  isBuyer={isBuyer}
                />
              </Paper>

              <Paper variant="elevation">
                {!!selectedLineIds.length && <TableActions />}
                <Tabs
                  value={filters.scope}
                  onChange={handleScopeChange}
                  tabs={updatedTabList}
                  variant="scrollable"
                >
                  <Input.DropDown
                    theme="shipmentsPage"
                    value={filters.order_by || initialFilters.order_by}
                    onChange={setSorting}
                    options={sortingOptions(t)}
                  />
                </Tabs>
                <section className="page__purchase-orders">
                  {ordersCount !== 0 && (
                    <PurchaseOrdersTable
                      busy={busy}
                      setCurrentPurchaseOrder={setCurrentPurchaseOrder}
                      deleteOpenedPurchaseOrder={deleteOpenedPurchaseOrder}
                      fetchData={fetchData}
                      isBuyer={isBuyer}
                    />
                  )}
                  {ordersCount === 0 && (
                    <BlankState.Action
                      theme="PO"
                      type="button"
                      buttonText={t(
                        'purchase_orders.empty_state.action_button',
                        'Add order'
                      )}
                      buttonAction={openPurchaseOrderModal}
                      iconsArray={blankStateIcons(t)}
                    />
                  )}
                </section>
                {!!ordersCount && (
                  <Pagination
                    onChangePage={onChangePage}
                    totalItemsNumber={ordersCount as number}
                    itemsPerPage={filters.purchase_orders_per_page}
                    currentPage={filters.page}
                  />
                )}
              </Paper>
            </div>
          </section>
        )}
      </Dropzone>
      <PurchaseModalWindow
        open={isPurchaseModalOpen}
        close={onCloseWindow}
        fetchData={fetchData}
        purchaseOrder={purchaseOrder}
        files={filesArr}
      />
      <ConfirmDialog
        title={t('purchase_orders.modals.delete_order.title', 'Confirmation')}
        message={t(
          'purchase_orders.modals.delete_order.description',
          'You are about to permanently delete this order. Please press confirm to proceed.'
        )}
        isOpen={confirmDeleteIsOpen}
        confirm={confirmDeletePurchaseOrder}
        reject={setConfirmDeleteIsOpen.bind(null, false)}
        onClose={setConfirmDeleteIsOpen.bind(null, false)}
      />
    </article>
  )
}

export default PurchaseOrders
