import { useGetOrganizationsAsync } from 'src/services/Api/organizations'
import { IOrganizationsResponse } from 'src/@types/endpoints/organizations'
import AutocompleteAsyncBase from './AutocompleteAsyncBase'

const TransportersAutocompleteAsync = ({ token, onDelete }) => {
  const getOptions = (data: IOrganizationsResponse) => {
    return (
      data?.map((organization) => ({
        label: organization.name,
        value: organization.id,
      })) ?? []
    )
  }

  return (
    <AutocompleteAsyncBase
      token={token}
      onDelete={onDelete}
      params={{ role: 'transporter' }}
      formatter={getOptions}
      fetchFunction={useGetOrganizationsAsync}
    />
  )
}

export default TransportersAutocompleteAsync
