import { ChangeEvent, KeyboardEvent, FocusEvent, useRef, useState } from 'react'
import { Box, Popover } from '@mui/material'
import OverviewTableText from '../OverviewTableText'
import { TextareaAutosize } from './styled'

const NotesEditableCell = ({ cell, onPopoverClose, missingValueText }) => {
  const textAreaRef = useRef(null)
  const [notes, setNotes] = useState(cell.getValue())
  const [cellWidth, setCellWidth] = useState(0)
  const [cellHeight, setCellHeight] = useState(0)
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const open = Boolean(anchorEl)
  const id = open ? 'notes-popover' : undefined

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)

    const anchorWidth = event.currentTarget.getBoundingClientRect().width
    const anchorHeight = event.currentTarget.getBoundingClientRect().height

    if (cellWidth !== anchorWidth) {
      setCellWidth(anchorWidth)
    }
    if (cellHeight !== anchorHeight) {
      setCellHeight(anchorHeight)
    }
  }

  const handleClose = (
    _event: KeyboardEvent<HTMLTextAreaElement>,
    reason: string
  ) => {
    setAnchorEl(null)
    if (reason === 'escapeKeyDown') {
      setNotes(cell.getValue())
    } else {
      onPopoverClose(notes)
    }
  }

  const handleTextAreaFocus = (e: FocusEvent<HTMLTextAreaElement>) => {
    e.target.setSelectionRange(e.target.value.length, e.target.value.length)
    e.target.scrollTop = e.target.scrollHeight
  }

  const handleTextAreaKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (['Enter', 'NumpadEnter', 'Tab'].includes(e.code)) {
      handleClose(e, 'enter')
    }
  }

  const handleTextAreaChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setNotes(e.target.value)
  }

  return (
    <>
      <Box
        p={1}
        width="100%"
        height="100%"
        onClick={handleClick}
        display="flex"
        alignItems="center"
        className="filled-cell editable"
        data-testid="notes-editable-cell"
      >
        <OverviewTableText
          text={notes ? notes : missingValueText}
          color={notes ? 'primary.main' : 'text.secondary'}
        />
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        TransitionProps={{ timeout: 0 }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          elevation: 0,
          variant: 'outlined',
          square: true,
          sx: {
            width: cellWidth,
            minHeight: cellHeight,
            maxHeight: '400px',
            padding: 0,
            display: 'flex',
            alignItems: 'center',
            outlineWidth: '1px',
            outlineStyle: 'solid',
            outlineColor: 'grey.400',
            outlineOffset: '-1px',
          },
        }}
        data-testid="notes-popover"
      >
        <TextareaAutosize
          autoFocus={open}
          maxRows={8}
          value={notes}
          ref={textAreaRef}
          onChange={handleTextAreaChange}
          onFocus={handleTextAreaFocus}
          onKeyDown={handleTextAreaKeyDown}
        />
      </Popover>
    </>
  )
}

export default NotesEditableCell
