import { useTranslation } from 'react-i18next'
import { useRef, useState, useEffect, Fragment } from 'react'
import { Box, Typography, IconButton } from '@mui/material'
import { ChevronLeft, ChevronRight } from '@mui/icons-material'
import CloseIcon from '@mui/icons-material/Close'
import { getBulkActionItems } from './bulkActionItems'

const BulkEditBar = ({ onClose, tableRows, permittedBulkEditableColumns }) => {
  const { t } = useTranslation()
  const scrollContainerRef = useRef<HTMLDivElement>(null)
  const [showLeftButton, setShowLeftButton] = useState(false)
  const [showRightButton, setShowRightButton] = useState(false)

  const updateScrollButtons = () => {
    const container = scrollContainerRef.current
    if (container) {
      setShowLeftButton(container.scrollLeft > 0)
      setShowRightButton(
        container.scrollLeft + container.clientWidth < container.scrollWidth
      )
    }
  }

  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: -100, behavior: 'smooth' })
    }
  }

  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: 100, behavior: 'smooth' })
    }
  }

  // Attach event listeners for scrolling
  useEffect(() => {
    const container = scrollContainerRef.current
    if (!container) return

    // Update button visibility when scrolling
    container.addEventListener('scroll', updateScrollButtons)

    // Resize Observer to check if scroll is needed
    const resizeObserver = new ResizeObserver(updateScrollButtons)
    resizeObserver.observe(container)

    // Initial check
    updateScrollButtons()

    // Cleanup event listeners
    return () => {
      container.removeEventListener('scroll', updateScrollButtons)
      resizeObserver.disconnect()
    }
  }, [])

  const bulkActionItems = getBulkActionItems(
    {
      tableRows,
      permittedBulkEditableColumns,
    },
    t
  )

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        px: 2,
        py: 1,
        backgroundColor: 'grey.50',
        borderTop: '1px solid',
        borderTopColor: 'grey.200',
        overflow: 'hidden',
      }}
    >
      <Typography variant="overline" sx={{ flexShrink: 0, mr: 2 }}>
        {t('data_table_component.rows_selected', {
          count: tableRows.length,
          defaultValue: '{{count}} rows selected',
        })}
      </Typography>

      {showLeftButton && (
        <IconButton onClick={scrollLeft} size="small" color="primary">
          <ChevronLeft fontSize="small" />
        </IconButton>
      )}

      <Box
        ref={scrollContainerRef}
        sx={{
          display: 'flex',
          gap: 2,
          overflowX: 'auto',
          whiteSpace: 'nowrap',
          flexGrow: 1,
          '::-webkit-scrollbar': { display: 'none' },
        }}
      >
        {bulkActionItems.map((item) => (
          <Fragment key={item.accessorKey}>{item.component}</Fragment>
        ))}
      </Box>

      {showRightButton && (
        <IconButton onClick={scrollRight} size="small" color="primary">
          <ChevronRight fontSize="small" />
        </IconButton>
      )}

      <IconButton onClick={onClose} size="small" color="primary">
        <CloseIcon />
      </IconButton>
    </Box>
  )
}

export default BulkEditBar
