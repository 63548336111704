import { memo, useMemo, useState } from 'react'
import { uniqBy } from 'lodash'
import { FieldValues, useFormContext } from 'react-hook-form'
import { Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import CategoryAutocomplete from 'src/components/Overview/Search/FormElements/CategoryAutocomplete'
import { FilterOptions } from './Search/types'
import RecentFiltersManager from './Search/RecentSearches'
import { getSearchToken, getSearchOptions } from './utils'

const getDefaultTokens = (
  filterParams: FieldValues,
  filterOptions: FilterOptions[]
) => {
  return filterOptions.reduce((acc: FilterOptions[], option: FilterOptions) => {
    if (filterParams[option.value] && filterParams[option.value].length > 0) {
      acc.push(option)
    }
    return acc
  }, [])
}

const Search = () => {
  const { t } = useTranslation()
  const options = useMemo(() => getSearchOptions(t), [t])
  const recentSearches = new RecentFiltersManager()
  const { setValue, getValues } = useFormContext()

  const filters = getValues()
  const search = getValues('search')

  const defaultSearchToken: FilterOptions[] =
    search && search.length > 0 ? [getSearchToken(search)] : []

  const defaultTokens = getDefaultTokens(filters, [...options])

  const [tokens, setTokens] = useState<FilterOptions[]>([
    ...defaultSearchToken,
    ...defaultTokens,
  ])

  const onCategoryChange = (value: FilterOptions | null) => {
    if (!value) return
    if (value.group === 'recent_searches') {
      setValue(value.value, value.data)

      const selectedToken =
        value.value === 'search'
          ? [getSearchToken(value.data as string)]
          : getDefaultTokens({ [value.value]: value.data }, [...options])

      const newTokens: FilterOptions[] = uniqBy(
        [...tokens, ...selectedToken],
        (token) => token.value
      )

      setTokens(newTokens)
      return
    }
    const newTokens = uniqBy([...tokens, value], 'value')
    if (value.input) {
      setValue('search', value.input)
      recentSearches.addRecentSearch(value.input)
    }
    setTokens(newTokens)
  }

  const onTokenDelete = (tokenValue: string) => {
    const newTokens = tokens.filter((token) => token.value !== tokenValue)

    setTokens(newTokens)
  }

  return (
    <Stack
      mt={0}
      rowGap={1}
      spacing={1}
      flexGrow={1}
      flexWrap="wrap"
      direction="row"
      alignItems="center"
    >
      {tokens.map((token) => {
        const TokenComponent = token.component
        return (
          <TokenComponent
            key={token.value}
            token={token}
            onDelete={onTokenDelete}
          />
        )
      })}
      <Stack direction="row" flexGrow={1}>
        <CategoryAutocomplete
          tokens={tokens}
          options={options}
          onCategoryChange={onCategoryChange}
        />
      </Stack>
    </Stack>
  )
}

export default memo(Search)
