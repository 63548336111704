import { useTranslation } from 'react-i18next'
import { SingleSelect } from 'src/stories/Lab/Select/SingleSelect'

const TransportOrderDialogFormAddressesAddressType = ({ value, onChange }) => {
  const { t } = useTranslation()

  const addressTypeOptions = [
    {
      id: 'pickup',
      label: t('transports.drawer.address_book_type.pickup', 'Pick-up'),
    },
    {
      id: 'delivery',
      label: t('transports.drawer.address_book_type.delivery', 'Delivery'),
    },
    {
      id: 'depot',
      label: t('transports.drawer.address_book_type.depot', 'Depot'),
    },
    {
      id: 'extra_stop',
      label: t('transports.drawer.address_book_type.extra_stop', 'Extra stop'),
    },
    {
      id: 'terminal',
      label: t('transports.drawer.address_book_type.terminal', 'Terminal'),
    },
  ]
  return (
    <SingleSelect
      value={value}
      placeholder={t('common.select', 'Select')}
      options={addressTypeOptions}
      onChange={onChange}
    />
  )
}

export default TransportOrderDialogFormAddressesAddressType
