import { Link, Typography } from '@mui/material'
import { Trans } from 'react-i18next'

type DocTypeCodes =
  | 'terms_and_conditions'
  | 'forwarding_conditions'
  | 'warehousing_condition'

const noDocTypeCodeText = {
  forwarding_conditions: 'Dutch Forwarding Conditions by FENEX',
  terms_and_conditions: 'General Terms & Conditions',
  warehousing_condition: '',
}

interface PublicDocumentProps {
  seller: IOrganizationWithTerms
  code: DocTypeCodes
}

const ShipmentBookingTermsAndConditionsPublicDocument = ({
  code,
  seller,
}: PublicDocumentProps) => {
  const doc = seller?.public_documents?.find(
    (item) => item.document_type.code === code
  )

  if (!doc) {
    return <>{noDocTypeCodeText[code]}</>
  }

  if (code === 'warehousing_condition') {
    return (
      <Trans
        i18nKey={'shipment_booking.terms_and_conditions.warehousing'}
        defaults=" The <warehousingConditionLink />, latest version, are applicable to all orders which exclusively concern the warehousing of property."
        components={{
          warehousingConditionLink: (
            <Link href={doc.file_url} rel="noopener noreferrer" target="_blank">
              <Typography
                display="inline"
                variant="body1"
                className="hyperlink bold"
              >
                Dutch Warehousing Conditions
              </Typography>
            </Link>
          ),
        }}
      />
    )
  }

  return (
    <Link href={doc.file_url} rel="noopener noreferrer" target="_blank">
      <Typography display="inline" variant="body1" className="hyperlink bold">
        {doc.name}
      </Typography>
    </Link>
  )
}

export default ShipmentBookingTermsAndConditionsPublicDocument
