import {
  useGetAddressesAsync,
  useGetConnectionsAsync,
} from 'src/services/Api/common'
import uniq from 'lodash/uniq'
import { TFunction } from 'i18next'
import {
  getFormattedAddressData,
  getFormattedTransporterData,
} from 'src/components/Overview/Table/EditableCells/utils'
import { tableSortingKeys } from '../../constants'
import BulkEditBarAsyncSelect from './BulkEditBarAsyncSelect'
import BulkEditBarDateTime from './BulkEditBarDateTime'

export const getBulkActionItems = (
  { tableRows, permittedBulkEditableColumns },
  t: TFunction<'translation', undefined>
) => {
  const containerIds = tableRows.map((row) => row.original.id)

  const shipmentOwnerOrganizationIds = uniq(
    tableRows.map((row) => row?.original?.shipment_owner_organization_id)
  )
  const items = [
    {
      accessorKey: tableSortingKeys.pickupTransporter,
      component: (
        <BulkEditBarAsyncSelect
          containerIds={containerIds}
          label={t(
            'vessels_page.data_table.column_headers.pickup_transporter',
            'Transporter (pickup)'
          )}
          formatter={getFormattedTransporterData}
          fetchFunction={useGetConnectionsAsync}
          fetchParams={{ role: 'transporter', per_page: 10 }}
          updateParams={{ service: 'pickup' }}
        />
      ),
    },
    {
      accessorKey: tableSortingKeys.deliveryTransporter,
      component: (
        <BulkEditBarAsyncSelect
          containerIds={containerIds}
          label={t(
            'vessels_page.data_table.column_headers.delivery_transporter',
            'Transporter (delivery)'
          )}
          formatter={getFormattedTransporterData}
          fetchFunction={useGetConnectionsAsync}
          fetchParams={{ role: 'transporter', per_page: 10 }}
          updateParams={{ service: 'delivery' }}
        />
      ),
    },
    {
      accessorKey: tableSortingKeys.containerPickupAddress,
      component: (
        <BulkEditBarAsyncSelect
          containerIds={containerIds}
          label={t(
            'vessels_page.data_table.column_headers.pickup_address',
            'Address (pickup)'
          )}
          formatter={getFormattedAddressData}
          fetchFunction={useGetAddressesAsync}
          fetchParams={{
            page: 1,
            per_page: 10,
            organization_id: shipmentOwnerOrganizationIds?.[0] ?? '',
          }}
          buttonDisabled={shipmentOwnerOrganizationIds.length > 1}
          buttonTooltip={t(
            'data_table_component.bulk_edit.tooltips.pickup_address_disabled',
            'Pickup address can’t be the same for multiple organizations.'
          )}
          updateField="address_id"
          updateParams={{ service: 'pickup' }}
        />
      ),
    },
    {
      accessorKey: tableSortingKeys.containerDeliveryAddress,
      component: (
        <BulkEditBarAsyncSelect
          containerIds={containerIds}
          label={t(
            'vessels_page.data_table.column_headers.delivery_address',
            'Address (delivery)'
          )}
          formatter={getFormattedAddressData}
          fetchFunction={useGetAddressesAsync}
          fetchParams={{
            page: 1,
            per_page: 10,
            organization_id: shipmentOwnerOrganizationIds?.[0] ?? '',
          }}
          buttonDisabled={shipmentOwnerOrganizationIds.length > 1}
          buttonTooltip={t(
            'data_table_component.bulk_edit.tooltips.delivery_address_disabled',
            'Delivery address can’t be the same for multiple organizations.'
          )}
          updateField="address_id"
          updateParams={{ service: 'delivery' }}
        />
      ),
    },
    {
      accessorKey: tableSortingKeys.containerPickupTime,
      component: (
        <BulkEditBarDateTime
          containerIds={containerIds}
          label={t(
            'vessels_page.data_table.column_headers.pickup_time',
            'Pickup date'
          )}
          updateField="pickup_delivery_time"
          updateParams={{ service: 'pickup' }}
        />
      ),
    },
    {
      accessorKey: tableSortingKeys.containerDeliveryTime,
      component: (
        <BulkEditBarDateTime
          containerIds={containerIds}
          label={t(
            'vessels_page.data_table.column_headers.delivery_time',
            'Delivery date'
          )}
          updateField="pickup_delivery_time"
          updateParams={{ service: 'delivery' }}
        />
      ),
    },
  ]
  return items.filter((item) =>
    permittedBulkEditableColumns.includes(item.accessorKey)
  )
}
