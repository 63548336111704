import { UseQueryOptions } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { endpoints, queryKeys } from 'src/services/endpoints'
import { apiClient } from 'src/services/http-common'
import { useQueryAsync } from '../hooks'
import { createQueryString } from '../utils'

export type serviceDepartmentsResponse = string[][]

export const useGetServiceDepartmentsAsync = (
  options?: UseQueryOptions<
    serviceDepartmentsResponse,
    AxiosError,
    serviceDepartmentsResponse
  >
) => {
  const { serviceDepartments: key } = queryKeys

  const getServiceDepartments = (payload: object = {}) => {
    const queryString = createQueryString(payload)
    const url = endpoints.serviceDepartments + queryString

    return apiClient.get<any, serviceDepartmentsResponse>(url)
  }

  return useQueryAsync([key], getServiceDepartments, options)
}
