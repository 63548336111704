import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { styled } from '@mui/system'
import { Typography } from '@mui/material'
import OverviewTableText from '../OverviewTableText'
import { getCellIsEditable } from './utils'
import ShipmentCommentsDialog from './ShipmentCommentsDialog'

const TruncatedTypography = styled(Typography)({
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 2,
  whiteSpace: 'normal',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  wordBreak: 'break-word',
  '&:hover': {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  width: '100%',
})

const ShipmentComments = ({ cell, row }) => {
  const { t } = useTranslation()
  const shipmentId = row?.original?.shipment_id ?? null
  const shipmentTitle = row?.original?.shipment_title ?? null

  const [open, setOpen] = useState(false)

  const isEditable = useMemo(() => getCellIsEditable(cell), [cell])

  const comment = cell.getValue()

  const onClose = () => {
    setOpen(false)
  }

  const onClick = () => {
    setOpen(true)
  }

  if (!isEditable) {
    return <OverviewTableText text={comment} />
  }

  return (
    <>
      {!!comment && (
        <TruncatedTypography
          onClick={onClick}
          data-testid="shipment-comments-trigger"
        >
          {comment}
        </TruncatedTypography>
      )}
      {!comment && (
        <Typography
          onClick={onClick}
          color="text.secondary"
          sx={{ cursor: 'pointer', width: '100%' }}
        >
          {t('common.buttons.add', 'Add')}
        </Typography>
      )}
      {open && (
        <ShipmentCommentsDialog
          open={open}
          onClose={onClose}
          shipmentId={shipmentId}
          shipmentTitle={shipmentTitle}
        />
      )}
    </>
  )
}

export default ShipmentComments
