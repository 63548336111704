import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import FilterListIcon from '@mui/icons-material/FilterList'
import { FunctionComponent, useState, useEffect, useRef } from 'react'
import {
  Paper,
  Box,
  Typography,
  Button,
  InputLabel,
  SelectChangeEvent,
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import Input from 'src/components/Common/Input/MuiInput'
import Autocomplete from 'src/stories/SearchBar'
import { MultiSelect } from 'src/stories/Lab/Select/MultiSelect'
import { useGetPorts, useGetStatusTypes } from 'src/services/Api/common'
import './styles.scss'
import { FormDatePicker } from '../Filters/FormElements/FormDatePicker'

export interface ITasksFilters {
  page: number
  search: string
  due_date_start: string | undefined
  due_date_end: string | undefined
  loading_port_id: number | null
  discharge_port_id: number | null
  task_status_type_ids: ITaskStatusType[]
  tasks_per_page: number
}

interface IProps {
  onChangeFilters: (name, value) => void
  onChangeDateFilter: (date) => void
  filters: ITasksFilters
  resetFilters: () => void
  disableFilters: boolean
}

export const TasksFilters: FunctionComponent<IProps> = (props) => {
  const isFirstRun = useRef(true)
  const [pol, setPol] = useState<IPlaceSuggestion | null>(null)
  const [pod, setPod] = useState<IPlaceSuggestion | null>(null)
  const [landingPortValue, setLandingPortValue] = useState('')
  const [dischargePortValue, setDischargePortValue] = useState('')

  const { data: tasksStatusTypes } = useGetStatusTypes()
  const { data: dischargePorts } = useGetPorts({ input: dischargePortValue })
  const { data: landingPorts } = useGetPorts({ input: landingPortValue })

  const tasksStatusTypesOptions =
    tasksStatusTypes?.map((tasksStatusType) => ({
      id: tasksStatusType.id,
      label: tasksStatusType.name,
    })) || []

  useEffect(() => {
    if (!props.filters.loading_port_id) {
      setPol(null)
    }
    if (!props.filters.discharge_port_id) {
      setPod(null)
    }
  }, [props.filters.loading_port_id, props.filters.discharge_port_id])

  useEffect(() => {
    if (!isFirstRun.current) {
      props.onChangeFilters('loading_port_id', pol?.port_id ?? null)
    }
  }, [pol])

  useEffect(() => {
    if (!isFirstRun.current) {
      props.onChangeFilters('discharge_port_id', pod?.port_id ?? null)
    }
  }, [pod])

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false
      return
    }
  })

  const handleSearchChange = (value: string) => {
    props.onChangeFilters('search', value)
  }

  const pickFilterOption = (option: any, fieldName?: string) => {
    if (fieldName && props.filters[fieldName]) {
      props.onChangeFilters(fieldName, option)
    }
  }
  const [showFilters, setShowFilters] = useState(false)

  const toggleFilters = () => {
    setShowFilters(!showFilters)
  }

  const onChangeTaskStatusType = (event: SelectChangeEvent<unknown>) => {
    pickFilterOption(event.target.value, 'task_status_type_ids')
  }

  const onResetTaskStatusType = () => {
    pickFilterOption([], 'task_status_type_ids')
  }

  return (
    <>
      <div className="header-with-actions" data-testid="task-manager-component">
        <Typography mt={3} variant="h4" children="Task manager" />
        <div>
          {showFilters && (
            <Button
              variant="text"
              onClick={props.resetFilters}
              disabled={props.disableFilters}
              startIcon={<CloseRoundedIcon />}
              sx={{ mr: 1.5 }}
            >
              Clear filters
            </Button>
          )}
          <Button
            variant="outlined"
            onClick={toggleFilters}
            startIcon={<FilterListIcon />}
            size="large"
          >
            {`${showFilters ? 'Hide' : 'Show'} filters`}
          </Button>
        </div>
      </div>
      {showFilters && (
        <Paper
          variant="elevation"
          sx={{ padding: '16px 16px 0px 16px', marginBottom: 2 }}
        >
          <Box mb={2} display="flex" alignItems="center">
            <Box mb={2} width={300} pr={2}>
              <Input
                name="search"
                label="Search"
                endAdornment={<SearchIcon />}
                value={props.filters.search}
                onChange={handleSearchChange}
                placeholder="Search by type, status, etc"
                debouncevalue={600}
              />
            </Box>
            <Box mb={2} width={210} pr={2}>
              <InputLabel>Tasks status type</InputLabel>
              <MultiSelect
                data-testid="task-manager-filters-task-status-type-select"
                value={props.filters.task_status_type_ids}
                placeholder="Filter by type"
                options={tasksStatusTypesOptions}
                onChange={onChangeTaskStatusType}
                onReset={onResetTaskStatusType}
                SelectDisplayProps={{
                  className: 'task-manager-filters-task-status-type-trigger',
                }}
              />
            </Box>
            <Box mb={2} width={420} pr={2}>
              <FormDatePicker
                onChange={props.onChangeDateFilter}
                label="Due date"
                name={['due_date_start', 'due_date_end']}
                qs={props.filters}
              />
            </Box>
            <Box mb={2} width={210} pr={2}>
              <Autocomplete
                label="Port of loading"
                placeholder="Search port, address..."
                value={pol || null}
                optionLabel={(option) => option.main_text}
                onChange={setPol}
                onInputChange={(_, selectedOptionLabel) =>
                  setLandingPortValue(selectedOptionLabel)
                }
                options={landingPorts?.data?.places || []}
              />
            </Box>
            <Box mb={2} width={210} pr={2}>
              <Autocomplete
                label="Port of discharge"
                placeholder="Search port, address..."
                value={pod || null}
                optionLabel={(option) => option.main_text}
                onChange={setPod}
                options={dischargePorts?.data?.places || []}
                onInputChange={(_, selectedOptionLabel) =>
                  setDischargePortValue(selectedOptionLabel)
                }
              />
            </Box>
          </Box>
        </Paper>
      )}
    </>
  )
}
