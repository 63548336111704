import { Box, Grid, Paper, Typography, Button } from '@mui/material'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { FormContext } from '..'
import ContainersBlock from './ContainersBlock'
import Party from './Party'
import ReferencesBlock from './ReferencesBlock'
import ShipmentInfoBlock from './ShipmentInfoBlock'

const ItemWrapper = ({ children }) => (
  <Box
    sx={{
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    }}
  >
    {children}
  </Box>
)

const ShippingInstructions = ({ title }) => {
  const { t } = useTranslation()
  const { instruction, shipmentId, bookingId } = useContext(FormContext)
  const history = useHistory()

  return (
    <>
      <Paper className="shipping-instructions" sx={{ position: 'relative' }}>
        <Box
          sx={{
            position: 'sticky',
            top: '56px',
            backgroundColor: 'var(--common-white)',
            zIndex: 1,
          }}
        >
          <Box
            pl={3}
            pr={3}
            pt={3}
            display="flex"
            justifyContent="space-between"
          >
            <Typography
              variant="h3"
              gutterBottom
              data-testid="shipping-instruction-title"
            >
              {t(
                'shipping_instructions.titles.review_shipment',
                '1. Review shipment information - {{bookingTitle}}',
                { bookingTitle: title }
              )}
            </Typography>
            <Box>
              <Button
                sx={{ mr: 2 }}
                size="medium"
                variant="outlined"
                onClick={() =>
                  history.push(`/shipments/${shipmentId}/documentation`)
                }
              >
                {t('common.actions.back', 'Back')}
              </Button>
              <Button
                size="medium"
                variant="contained"
                onClick={() =>
                  history.push(
                    `/shipments/${shipmentId}/bookings/${bookingId}/shipping-instructions/steps/2`
                  )
                }
              >
                {t('common.actions.next', 'Next')}
              </Button>
            </Box>
          </Box>
          <Box pl={3} pr={3} pb={3}>
            <Typography gutterBottom>
              {t(
                'shipping_instructions.messages.review_details',
                'Review the content and add details where needed.'
              )}
            </Typography>
            <Typography>
              {t(
                'shipping_instructions.messages.mandatory_fields',
                'The fields marked with asterisk (*) are mandatory for Shipping instructions.'
              )}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            position: 'sticky',
            width: '100%',
            height: '10px',
            top: '179px',
            boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.07)',
          }}
        />
        <Box
          sx={{
            backgroundColor: 'white',
            height: '10px',
            position: 'absolute',
            width: '100%',
          }}
        />
        <Grid container spacing={3} p={3}>
          <Grid item xs={6}>
            <ItemWrapper>
              <ReferencesBlock references={instruction.references} />
            </ItemWrapper>
          </Grid>
          <Grid item xs={6}>
            <ShipmentInfoBlock instruction={instruction} />
          </Grid>
          {instruction.parties
            .filter((party) =>
              ['notify', 'consignee', 'shipper'].includes(party.role_type)
            )
            .map((party) => (
              <Grid item xs={6} key={party.role_type}>
                <ItemWrapper>
                  <Party party={party} />
                </ItemWrapper>
              </Grid>
            ))}
          <Grid item xs={12}>
            <ContainersBlock cargos={instruction.cargos} editable={true} />
          </Grid>
        </Grid>
      </Paper>
    </>
  )
}

export default ShippingInstructions
