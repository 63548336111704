import { Typography, TypographyProps } from '@mui/material'

const OverviewTableText: React.FC<{
  text: string | null | undefined
  variant?: TypographyProps['variant']
  'data-testid'?: string
  color?: string
}> = ({ text, variant = 'body1', color = 'primary.main', ...otherProps }) => {
  return (
    <Typography noWrap variant={variant} color={color} {...otherProps}>
      {text && text.length > 0 ? text : '-'}
    </Typography>
  )
}

export default OverviewTableText
