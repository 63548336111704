import { queryClient } from 'src/services/http-common'
import { queryKeys } from 'src/services/endpoints'
import {
  IAddressesResponse,
  IConnectionsResponse,
} from 'src/@types/endpoints/common'
import { OverviewAllowedColumnsAndFiltersResponse } from 'src/services/Api/overview/types'
import { getAllowedColumnsObject } from '../../utils'

export const getAllowedColumns = () => {
  const allowedFiltersAndColumnsResponse:
    | OverviewAllowedColumnsAndFiltersResponse
    | undefined = queryClient.getQueryData([
    queryKeys.overviewAllowedFiltersAndColumns,
  ])

  return getAllowedColumnsObject(allowedFiltersAndColumnsResponse)
}

export const getCellIsEditable = (cell) => {
  const allowedFiltersAndColumns = getAllowedColumns()

  const accessorKey = cell.column.columnDef.accessorKey ?? ''
  return allowedFiltersAndColumns[accessorKey]?.includes('edit') ?? false
}

export const getCellIsBulkEditable = (accessorKey: string) => {
  const allowedFiltersAndColumns = getAllowedColumns()

  return allowedFiltersAndColumns[accessorKey]?.includes('edit') ?? false
}

export const getFormattedTransporterData = (data: IConnectionsResponse) => {
  return data.list.map((item) => ({
    label: item.name,
    value: item.organization_id,
  }))
}

export const getFormattedAddressData = (data: IAddressesResponse) => {
  return data.data.map((item) => ({
    label: item.name,
    value: item.id,
  }))
}
