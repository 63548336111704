import { FunctionComponent, useMemo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import Typography from '@mui/material/Typography'
import { Box, Paper } from '@mui/material'
import { TimelinePortHeader } from 'src/components/ShipmentTimeline/TimelinePortHeader'
import ContentDropDown from 'src/components/Common/ContentDropDown'
import MilestonesProgressBar from '../MilestonesProgressBar'
import { TimelinePortDate } from '../TimelinePortDate'

interface IProps {
  shipment: IOverviewShipment
  milestones: IMilestone[]
}

const TimelinePOL: FunctionComponent<IProps> = ({ shipment, milestones }) => {
  const { t } = useTranslation()
  const hasUnreachedMilestones: boolean = useMemo(
    () => milestones.some((x) => !x.reached),
    [milestones]
  )

  const isForcedOpen = useMemo(
    () =>
      (!!milestones.length || !!shipment.actual_departure) &&
      (shipment.shipment_phase !== 'destination' || hasUnreachedMilestones),
    [shipment, milestones]
  )

  const headerContent = (opened: boolean): React.ReactNode => (
    <TimelinePortHeader
      dateLabel={t('shipment.timeline.port_date.etd', 'ETD')}
      opened={opened}
      shipment={shipment}
      milestones={milestones}
      dateKey="estimated_departure"
      flightNumber={
        shipment.shipment_legs ? shipment.shipment_legs[0].voyage_flight_no : ''
      }
      showChevron={!!milestones.length || !!shipment.actual_departure}
    />
  )

  const informationBlock = useCallback((): React.ReactNode => {
    if (!shipment.actual_departure) {
      return null
    }

    return (
      <Box px={2} pb={2}>
        <TimelinePortDate
          label={t('shipment.timeline.port_date.atd', 'ATD')}
          modality={shipment.modality}
          date={shipment.actual_departure}
          statusSequence={shipment.status_sequence}
          shipment={shipment}
          showExactDate={true}
        />
      </Box>
    )
  }, [shipment])

  const bodyContent = (): React.ReactNode => (
    <>
      {informationBlock()}
      {!!milestones.length && (
        <MilestonesProgressBar
          milestones={milestones}
          shipmentId={shipment.id}
        />
      )}
    </>
  )

  return (
    <>
      <div className="shipment-timeline--phases-item-title">
        <Typography variant="h6">
          {shipment.type === 'sea' && shipment.loading_port_code !== 'UNKWN'
            ? t('shipment.timeline.port_of', {
                defaultValue: 'Port of {{portName}}',
                portName: shipment.loading_port,
              })
            : shipment.loading_port}
        </Typography>
      </div>

      <div className="shipment-timeline--phases-item-wrapper">
        <Paper variant="elevation">
          <ContentDropDown
            className="shipment-timeline--phases-item-dropdown"
            header={headerContent(true)}
            headerCollapsed={headerContent(false)}
            body={bodyContent()}
            disableCollapse={!milestones.length && !shipment.actual_departure}
            forcedOpen={isForcedOpen}
          />
        </Paper>
      </div>
    </>
  )
}

export default TimelinePOL
