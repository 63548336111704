import { FunctionComponent, useMemo, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { Chip, Typography, Link, Paper } from '@mui/material'
import { capitalize } from 'lodash'
import { push } from 'connected-react-router'
import { Dispatch } from 'redux'
import { useTranslation } from 'react-i18next'
import ContentDropDown from 'src/components/Common/ContentDropDown'
import { permissionTo } from '../../../utils'

import MilestonesProgressBar from '../MilestonesProgressBar'

interface IProps {
  phase: IShipmentPhase
  shipment: IOverviewShipment
  milestones: IMilestone[]
}

const statusToColor = {
  requested: 'default',
  estimated: 'default',
  proposed: 'default',
  confirmed: 'success',
}

const TimelinePickup: FunctionComponent<IProps> = ({
  phase,
  shipment,
  milestones,
}) => {
  const { t } = useTranslation()
  const dispatch: Dispatch = useDispatch()

  const toContainersTab = useCallback(() => {
    dispatch(push(`/shipments/${shipment.id}/containers`))
  }, [shipment])

  const pickupAddress: React.ReactNode = useMemo(() => {
    if (!shipment.pickup_requested || !shipment.pickup_address_name) {
      return (
        <Typography
          variant="body1"
          children={t(
            'shipment.timeline.service_not_requested',
            'Not requested'
          )}
        />
      )
    }

    if (~shipment.pickup_address_name.indexOf('not set')) {
      return (
        <Link variant="body1" component="button" onClick={toContainersTab}>
          {t('common.buttons.add_address', 'Add address')}
        </Link>
      )
    }

    return (
      <Link variant="body1" component="button" onClick={toContainersTab}>
        {shipment.pickup_address_name}
      </Link>
    )
  }, [shipment])

  const headerContent = (opened: boolean): React.ReactNode => (
    <div
      className={`timeline-dropdown-header dropdown-header-content ${
        opened ? 'opened' : ''
      }`}
    >
      <div className="shipment-timeline--phases-header-wrapper">
        <div className="date-block">
          <Typography
            variant="body1Strong"
            data-testid="timeline-cargo-closing-date"
            children={t('shipment.timeline.cargo_closing', 'Cargo closing:')}
          />
          <Typography
            variant="body1"
            children={t('shipment.timeline.closing_datetime', {
              defaultValue: '{{val, DATE_MED}}',
              val: shipment.closing_datetime,
            })}
          />
        </div>
        <span className="status-tag-container">
          <Chip
            size="small"
            color={statusToColor[shipment.closing_datetime_status]}
            label={capitalize(shipment.closing_datetime_status)}
          />
          {milestones.length ? (
            <i className={`icon chevron ${opened ? '' : 'collapsed'}`} />
          ) : (
            ''
          )}
        </span>
      </div>
    </div>
  )

  const bodyContent = (): React.ReactNode => (
    <MilestonesProgressBar milestones={milestones} shipmentId={shipment.id} />
  )

  const hasUnreachedMilestones: boolean = useMemo(
    () => milestones.some((x) => !x.reached),
    [milestones]
  )

  const isForcedOpen = useMemo(
    () =>
      !!milestones.length &&
      (shipment.shipment_phase === 'origin' || hasUnreachedMilestones),
    [shipment, milestones]
  )

  return (
    <>
      <div className="shipment-timeline--phases-item-title">
        <Typography
          variant="h6"
          children={t('shipment.timeline.headings.pickup', 'Pickup')}
        />
      </div>
      {permissionTo('shipments.containers_|_cargo.pickup.view') ? (
        <div className="shipment-timeline--phases-item-link">
          {pickupAddress}
        </div>
      ) : (
        ''
      )}
      <div className="shipment-timeline--phases-item-wrapper">
        <Paper variant="elevation">
          <ContentDropDown
            className="shipment-timeline--phases-item-dropdown"
            header={headerContent(true)}
            headerCollapsed={headerContent(false)}
            body={bodyContent()}
            disableCollapse={!milestones.length}
            forcedOpen={isForcedOpen}
          />
        </Paper>
      </div>
    </>
  )
}

export default TimelinePickup
