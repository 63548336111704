import { FunctionComponent } from 'react'
import Table from 'src/components/Common/Table'
import BlankPurchaseOrderRow from 'src/components/PurchaseOrders/BlankPurchaseOrderRow'

type Props = {
  totalWeight?: number
  totalVolume?: number
  busy: boolean
}

const PurchaseOrderRowTotals: FunctionComponent<Props> = ({
  totalWeight,
  totalVolume,
  busy,
}) => {
  return busy ? (
    <BlankPurchaseOrderRow index={999} />
  ) : (
    <Table.StripedTableRow index={999}>
      <Table.StripedTableCell className="short-50"></Table.StripedTableCell>
      <Table.StripedTableCell className="x-medium"></Table.StripedTableCell>
      <Table.StripedTableCell className="wide"></Table.StripedTableCell>
      <Table.StripedTableCell className="medium"></Table.StripedTableCell>
      <Table.StripedTableCell className="medium"></Table.StripedTableCell>
      <Table.StripedTableCell className="left-aligned paragraph__medium black strong">
        {totalVolume}m3
      </Table.StripedTableCell>
      <Table.StripedTableCell className="left-aligned paragraph__medium black strong">
        {totalWeight}kg
      </Table.StripedTableCell>
      <Table.StripedTableCell className="short"></Table.StripedTableCell>
      <Table.StripedTableCell className="short"></Table.StripedTableCell>
    </Table.StripedTableRow>
  )
}

export default PurchaseOrderRowTotals
