import { DateTime } from 'luxon'
import {
  Box,
  CircularProgress,
  ClickAwayListener,
  Typography,
} from '@mui/material'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { useEffect, useMemo, useState } from 'react'
import { getDateObjectFromISOString } from '../../utils'
import { SelectInput } from './styled'

const DateTimeCellDatePicker = ({
  value,
  testId,
  maxDate,
  minDate,
  onClose,
  displayDate,
  isLoading = false,
  addButtonText = 'Add',
  inputFormat = 'dd-MM-yyyy HH:mm',
}: {
  value: string | null
  testId: string
  maxDate?: string
  minDate?: string
  onClose: (date: DateTime | null) => void
  addButtonText?: string
  inputFormat?: string
  displayDate: string | null
  isLoading?: boolean
}) => {
  const [isEditMode, setIsEditMode] = useState<boolean>(false)

  const dateObject: DateTime | null = value
    ? getDateObjectFromISOString(value)
    : null

  const [localValue, setLocalValue] = useState<DateTime | null>(dateObject)

  const handleChange = (date: DateTime | null) => {
    setLocalValue(date)
  }

  const handleClick = () => {
    setIsEditMode(true)
  }

  const handleClose = () => {
    setIsEditMode(false)
    onClose(localValue)
  }

  const handleClickAway = () => {
    if (isEditMode) {
      setIsEditMode(false)
    }
  }

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.code === 'Enter' || e.code === 'NumpadEnter' || e.code === 'Tab') {
      handleClose()
    }
    if (e.code === 'Escape') {
      setIsEditMode(false)
    }
  }

  const datePickerMinDate = useMemo(() => {
    return minDate ? getDateObjectFromISOString(minDate) : undefined
  }, [minDate])

  const datePickerMaxDate = useMemo(() => {
    return maxDate ? getDateObjectFromISOString(maxDate) : undefined
  }, [maxDate])

  const buttonText = useMemo(() => {
    return value ? displayDate : addButtonText
  }, [displayDate, addButtonText])

  useEffect(() => {
    if (isEditMode && dateObject !== localValue) {
      setLocalValue(dateObject)
    }
  }, [isEditMode])

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box
        className={`filled-cell editable`}
        width="100%"
        height="100%"
        display="flex"
        alignItems="center"
        justifyContent="start"
      >
        {!isEditMode && (
          <Typography
            onClick={handleClick}
            component="div"
            variant="body1"
            sx={{
              cursor: 'pointer',
              px: 1,
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              color: 'text.secondary',
            }}
            children={
              isLoading ? (
                <CircularProgress color="inherit" size={18} />
              ) : (
                buttonText
              )
            }
          />
        )}
        {isEditMode && (
          <LocalizationProvider dateAdapter={AdapterLuxon}>
            <DateTimePicker
              onClose={handleClose}
              autoFocus
              closeOnSelect={false}
              ampm={false}
              loading={isLoading}
              displayWeekNumber
              format={inputFormat}
              minDate={datePickerMinDate}
              maxDate={datePickerMaxDate}
              value={localValue}
              onChange={handleChange}
              data-testid={`${testId}-editable-date-picker`}
              slots={{
                textField: SelectInput,
              }}
              slotProps={{
                inputAdornment: {
                  sx: {
                    '& svg': {
                      color: 'primary.main',
                    },
                  },
                },
                textField: {
                  onKeyDown: handleKeyDown,
                  inputProps: {
                    'data-testid': `${testId}-editable-date-picker-input`,
                  },
                  sx: {
                    '& ::placeholder': {
                      color: 'primary.main',
                    },
                  },
                },
                desktopPaper: {
                  onKeyDown: handleKeyDown,
                },
              }}
            />
          </LocalizationProvider>
        )}
      </Box>
    </ClickAwayListener>
  )
}

export default DateTimeCellDatePicker
