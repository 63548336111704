import { Dialog, DialogContent } from 'src/stories/Dialogs'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'

const TransportOrderLoadingDialog = () => {
  return (
    <Dialog maxWidth="md" fullWidth open={true} PaperComponent={Box}>
      <DialogContent>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          color="grey.50"
        >
          <CircularProgress color="inherit" />
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default TransportOrderLoadingDialog
