import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import MuiLink from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import { Chip } from '@mui/material'
import { getFirstDayOfDemurrage } from 'src/utils/helpers'
import { IContainerDemurrageAndDetention } from '.'

interface IContainerDemurrageAndDetentionTopSection
  extends Pick<IContainerDemurrageAndDetention, 'demurrageDates'> {
  hasAgreement: boolean
}

const ContainerDemurrageAndDetentionTopSection: React.FC<IContainerDemurrageAndDetentionTopSection> = ({
  hasAgreement,
  demurrageDates,
}) => {
  const { t } = useTranslation()
  const demurrageDate =
    demurrageDates.actualDayOfDemurrage || demurrageDates.destinationDemurrage

  const dayOfDemurrage = getFirstDayOfDemurrage(
    t,
    demurrageDates.destinationDemurrage,
    demurrageDates.actualDayOfDemurrage
  )
  const isActual = demurrageDates.actualDayOfDemurrage
  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="baseline">
        <Typography mb={2} variant="h6" children="Demurrage and detention" />
        {hasAgreement && (
          <MuiLink variant="body1" component={Link} to="/demurrage-detention">
            {t(
              'shipment_containers.demurrage_and_detention.top_section.view_agreements',
              'View agreements'
            )}
          </MuiLink>
        )}
      </Box>
      <Box mb={1}>
        <Typography
          variant="body1Strong"
          children={t(
            'shipment_containers.demurrage_and_detention.top_section.first_day_of_demurrage',
            'First day of demurrage'
          )}
        />
        <Box display="flex" alignItems="center" mt={0.5}>
          <Typography variant="body1" children={dayOfDemurrage} mr={1} />
          {demurrageDate && (
            <Chip
              label={
                isActual
                  ? t(
                      'shipment_containers.demurrage_and_detention.top_section.actual',
                      'Actual'
                    )
                  : t(
                      'shipment_containers.demurrage_and_detention.top_section.estimated',
                      'Estimated'
                    )
              }
              color={isActual ? 'success' : 'secondary'}
              size="small"
            />
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default ContainerDemurrageAndDetentionTopSection
