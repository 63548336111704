import { Button, Typography } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { CargoWindow } from 'src/components/PickupAndDelivery/CargoWindow'
import { apiClient } from 'src/services/http-common'
import { createQueryString } from 'src/services/utils'
import { showNotification } from 'src/stores/actionCreators/notifications'
import { FormContext } from '..'

const CargoWrapper = ({ containerId, onClose }) => {
  const { shipmentId, bookingId, refetchInstruction } = useContext(FormContext)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { data, isLoading } = useQuery<any, AxiosError, any>(
    ['containers', containerId],
    () =>
      apiClient.get<any, any>(
        `/containers/${containerId}` +
          createQueryString({ booking_id: bookingId })
      ),
    { cacheTime: 0 }
  )

  const bookings = useQuery<any, AxiosError, any>(
    ['bookings', shipmentId],
    () => apiClient.get<any, any>(`shipments/${shipmentId}/bookings`)
  )

  if (isLoading || bookings.isLoading) return null

  return (
    <CargoWindow
      open
      close={onClose}
      cargoItems={data.cargo}
      bookings={bookings.data.bookings}
      containerId={containerId}
      onSave={async () => {
        await refetchInstruction()
        dispatch(
          showNotification({
            message: t(
              'shipping_instructions.notifications.shipment_updated',
              'Shipment information updated'
            ),
            severity: 'success',
          })
        )
      }}
    />
  )
}

const ContainersBlock = ({ cargos, editable = false }) => {
  const { t } = useTranslation()
  const [currentContainer, setCurrentContainer] = useState(null)

  const showCargo = (id) => {
    setCurrentContainer(id)
  }

  return (
    <>
      <Typography variant="h6" gutterBottom>
        {t('shipping_instructions.headers.containers', 'Containers')}
      </Typography>
      <table>
        <thead>
          <tr>
            <th>
              {t(
                'shipping_instructions.sub_headers.marks_and_numbers',
                'Marks and numbers'
              )}
            </th>
            <th>
              {t(
                'shipping_instructions.sub_headers.number_of_packages',
                'Number of packages'
              )}
            </th>
            <th>
              {t(
                'shipping_instructions.sub_headers.goods_description',
                'Goods description'
              )}
            </th>
            <th>{t('shipping_instructions.sub_headers.weight', 'Weight')}</th>
            <th>{t('shipping_instructions.sub_headers.volume', 'Volume')}</th>
          </tr>
        </thead>
        <tbody>
          {cargos.map((cargo, index) => (
            <tr key={index}>
              <td style={{ whiteSpace: 'pre-line' }}>
                {cargo.marks_and_numbers}
              </td>
              <td width="100px">{cargo.packages}</td>
              <td style={{ whiteSpace: 'pre-line' }}>
                {cargo.goods_description}
              </td>
              <td width="100px">
                <strong>{cargo.weight_kg}</strong>
              </td>
              <td width="100px">
                <strong>{cargo.volume_cbm}</strong>
              </td>
              <td width="150px">
                {editable && cargo.row_type === 'cargo' && (
                  <Button
                    variant="outlined"
                    data-testid="edit-goods-button"
                    onClick={() => showCargo(cargo.container_id)}
                  >
                    {t(
                      'shipment_containers.cargo_window.edit_goods',
                      'Edit goods'
                    )}
                  </Button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {currentContainer && (
        <CargoWrapper
          containerId={currentContainer}
          onClose={() => setCurrentContainer(null)}
        />
      )}
    </>
  )
}

export default ContainersBlock
