import { useState, MouseEvent } from 'react'
import Menu from '@mui/material/Menu'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { useGetOverviewInlandTransportAsync } from 'src/services/Api/overview'
import { OverviewInlandTransportResponse } from 'src/services/Api/overview/types'
import RowActionMenuItemsTransportOrderDialog from 'src/components/Overview/Table/RowActionMenuItems/TransportOrderDialog'
import TransportOrderEmailDialog from 'src/components/Overview/Table/RowActionMenuItems/TransportOrderEmailDialog'
import TransportOrderLoadingDialog from 'src/components/Overview/Table/RowActionMenuItems/TransportOrderLoadingDialog'
import RowActionMenuItemsTransportOrder from 'src/components/Overview/Table/RowActionMenuItems/TransportOrder'
import { IconButton } from '@mui/material'

const RowActionMenu = ({ row }) => {
  const [mainDialogOpen, setMainDialogOpen] = useState(false)
  const [service, setService] = useState<'pickup' | 'delivery'>('pickup')
  const [emailDialogOpen, setEmailDialogOpen] = useState(false)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const menuOpen = Boolean(anchorEl)

  const [inlandTransport, setInlandTransport] = useState<
    OverviewInlandTransportResponse['data'] | null
  >(null)

  const inlandTransportId: number | null = inlandTransport?.id ?? null

  const {
    fetchAsync: getInlandTransport,
    isFetching: isFetchingInlandTransport,
  } = useGetOverviewInlandTransportAsync()

  const containerNumber =
    inlandTransport?.container?.number ??
    inlandTransport?.container?.container_index ??
    ''

  const fetchInlandTransportAndUpdateLocalState = () => {
    if (!inlandTransportId) {
      return
    }
    getInlandTransport({ id: inlandTransportId }).then((response) => {
      if (response) {
        setInlandTransport(response.data)
      }
    })
  }

  const handleClickOnMenuItem = (transportService: 'pickup' | 'delivery') => {
    setService(transportService)
    const id =
      row?.original?.[`${transportService}_inland_transport_id`] ?? null
    getInlandTransport({
      id,
    }).then((response) => {
      handleClose()
      if (response) {
        setInlandTransport(response.data)
        setMainDialogOpen(true)
      }
    })
  }

  const closeDialog = () => {
    setMainDialogOpen(false)
  }

  const openEmailDialog = () => {
    closeDialog()
    setEmailDialogOpen(true)
  }

  const closeEmailDialog = () => {
    setEmailDialogOpen(false)
    closeDialog()
  }

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      <IconButton
        id={`row-action-button-${row.id}`}
        aria-controls={mainDialogOpen ? `row-action-menu-${row.id}` : undefined}
        aria-haspopup="true"
        aria-expanded={mainDialogOpen ? 'true' : undefined}
        onClick={handleClick}
        color="primary"
        size="small"
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id={`row-action-menu-${row.id}`}
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': `row-action-button-${row.id}`,
        }}
      >
        <RowActionMenuItemsTransportOrder
          service="pickup"
          key="pickup_transport_order"
          onClick={() => {
            handleClickOnMenuItem('pickup')
          }}
          isLoading={isFetchingInlandTransport}
        />
        <RowActionMenuItemsTransportOrder
          service="delivery"
          key="delivery_transport_order"
          onClick={() => {
            handleClickOnMenuItem('delivery')
          }}
          isLoading={isFetchingInlandTransport}
        />
      </Menu>
      {isFetchingInlandTransport && !inlandTransport && (
        <TransportOrderLoadingDialog />
      )}
      {mainDialogOpen && inlandTransport && (
        <RowActionMenuItemsTransportOrderDialog
          open={mainDialogOpen}
          service={service}
          containerNumber={containerNumber}
          onClose={closeDialog}
          inlandTransport={inlandTransport}
          onInlandTransportUpdate={fetchInlandTransportAndUpdateLocalState}
          onSendEmailButtonClick={openEmailDialog}
        />
      )}
      {emailDialogOpen && inlandTransport && (
        <TransportOrderEmailDialog
          open={emailDialogOpen}
          onClose={closeEmailDialog}
          id={inlandTransportId}
          transporterEmail={
            inlandTransport?.transporter_organization?.inland_transport_email ??
            ''
          }
          containerNumber={containerNumber}
          service={service}
          documents={inlandTransport?.container?.documents ?? []}
        />
      )}
    </div>
  )
}

export default RowActionMenu
