import { useState } from 'react'
import { Link } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import Chip from '@mui/material/Chip'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import FormGroup from '@mui/material/FormGroup'
import Typography from '@mui/material/Typography'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import { getSportRatesSearchUrl } from './utils'
import { initialSpotRates } from './SABSearchResultAirSpotRates.constants'

const SABSearchResultAirSpotRates = () => {
  const [spotRates, setSpotRates] = useState(initialSpotRates)

  const handleSportRateChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSpotRates(
      [...spotRates].map((spotRate) =>
        spotRate.name === event.target.name
          ? { ...spotRate, checked: !spotRate.checked }
          : spotRate
      )
    )
  }

  return (
    <>
      <Typography mt={4} mb={2.5} variant="h4" children="Spot rates" />
      <Paper variant="outlined" sx={{ p: 2.5 }}>
        <Grid container spacing={1} sx={{ alignItems: 'center' }}>
          <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
            <Typography children="Select your desired criteria to proceed." />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={8}
            lg={6}
            xl={6}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: { md: 'end', lg: 'start', xl: 'center' },
            }}
          >
            <FormControl component="fieldset" variant="standard">
              <FormGroup aria-label="position" row>
                {spotRates.map((spotRate) => {
                  const { label, color, checked, name } = spotRate
                  return (
                    <FormControlLabel
                      key={name}
                      control={
                        <Checkbox
                          name={name}
                          checked={checked}
                          onChange={handleSportRateChange}
                        />
                      }
                      label={
                        <Chip
                          label={label}
                          color={color}
                          sx={{ cursor: 'pointer' }}
                        />
                      }
                    />
                  )
                })}
              </FormGroup>
            </FormControl>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={3}
            xl={3}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'end',
            }}
          >
            <Button
              variant="outlined"
              size="large"
              children="Request quote"
              component={Link}
              to={getSportRatesSearchUrl(spotRates)}
            />
          </Grid>
        </Grid>
      </Paper>
    </>
  )
}

export default SABSearchResultAirSpotRates
