import { FunctionComponent, useCallback } from 'react'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import { useTranslation } from 'react-i18next'
import { TimelinePortHeader } from 'src/components/ShipmentTimeline/TimelinePortHeader'
import ContentDropDown from 'src/components/Common/ContentDropDown'
import MilestonesProgressBar from '../MilestonesProgressBar'
import { TimelinePortDate } from '../TimelinePortDate'

interface IProps {
  shipment: IOverviewShipment
  milestones: IMilestone[]
}

const TimelinePOD: FunctionComponent<IProps> = ({ shipment, milestones }) => {
  const { shipment_legs: legs } = shipment
  const flightNumber = legs ? legs[legs.length - 1].voyage_flight_no : ''
  const { t } = useTranslation()

  const headerContent = (opened: boolean): React.ReactNode => (
    <TimelinePortHeader
      dateLabel={t('shipment.timeline.port_date.eta', 'ETA')}
      opened={opened}
      shipment={shipment}
      milestones={milestones}
      dateKey="estimated_arrival"
      flightNumber={flightNumber}
      showEtaCheck={true}
      showChevron={!!milestones.length || !!shipment.actual_arrival}
    />
  )

  const informationBlock = useCallback((): React.ReactNode => {
    if (
      !shipment.actual_arrival &&
      !shipment.destination_demurrage_starting_from &&
      !shipment.delivery_available_from
    ) {
      return null
    }

    return (
      <div className="additional-information ml-15">
        {shipment.actual_arrival ? (
          <TimelinePortDate
            label={t('shipment.timeline.port_date.ata', 'ATA')}
            modality={shipment.modality}
            date={shipment.actual_arrival}
            statusSequence={shipment.status_sequence}
            shipment={shipment}
            showExactDate={true}
          />
        ) : (
          ''
        )}
        {shipment.delivery_available_from ? (
          <p>
            {t('shipment.timeline.delivery_available_from', {
              defaultValue: 'Delivery available from: {{val, DATE_MED}}',
              val: shipment.delivery_available_from,
            })}
          </p>
        ) : (
          ''
        )}
      </div>
    )
  }, [shipment])

  const bodyContent = (): React.ReactNode => (
    <>
      {informationBlock()}
      {!!milestones.length && (
        <MilestonesProgressBar
          milestones={milestones}
          shipmentId={shipment.id}
        />
      )}
    </>
  )

  return (
    <>
      <div className="shipment-timeline--phases-item-title">
        <Typography variant="h6">
          {shipment.type === 'sea' && shipment.discharge_port_code !== 'UNKWN'
            ? t('shipment.timeline.port_of', {
                defaultValue: 'Port of {{portName}}',
                portName: shipment.discharge_port,
              })
            : shipment.discharge_port}
        </Typography>
      </div>
      <div className="shipment-timeline--phases-item-wrapper">
        <Paper variant="elevation">
          <ContentDropDown
            className="shipment-timeline--phases-item-dropdown"
            header={headerContent(true)}
            headerCollapsed={headerContent(false)}
            body={bodyContent()}
            disableCollapse={!milestones.length && !shipment.actual_arrival}
            forcedOpen={!!milestones.length || !!shipment.actual_arrival}
          />
        </Paper>
      </div>
    </>
  )
}

export default TimelinePOD
