export const bookingTitle = (booking: IBooking) => {
  const name = booking.shipper.shipment_address?.name
  if (name) return `${booking.booking_index} ${name}`
  return booking.booking_index || 'Booking'
}

export const setProperty = (
  obj: Record<string, any>,
  path: string,
  value: any
) => {
  const [head, ...rest] = path.split('.')

  return {
    ...obj,
    [head]: rest.length ? setProperty(obj[head], rest.join('.'), value) : value,
  }
}

export const movementTypeOptions = (t) => [
  {
    id: 'door_to_door',
    title: t('common.movement_types.door_to_door', 'Door to door'),
  },
  {
    id: 'door_to_port',
    title: t('common.movement_types.door_to_port', 'Door to port'),
  },
  {
    id: 'port_to_door',
    title: t('common.movement_types.port_to_door', 'Port to door'),
  },
  {
    id: 'port_to_port',
    title: t('common.movement_types.port_to_port', 'Port to port'),
  },
]

export const freightPayableOptions = (t) => [
  { id: 'prepaid', title: t('common.freight_payable.prepaid', 'Prepaid') },
  {
    id: 'collected',
    title: t('common.freight_payable.collected', 'Collected'),
  },
]
