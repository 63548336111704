import { useState } from 'react'
import { DateTime } from 'luxon'
import { Box, Button } from '@mui/material'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { UseDateFieldProps } from '@mui/x-date-pickers/DateField'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { useUpdateInlandTransport } from 'src/services/Api/overview'
import {
  FieldSection,
  DateValidationError,
  BaseSingleInputFieldProps,
} from '@mui/x-date-pickers/models'
import { customFields, tableCellMutationKey } from '../../constants'

interface ButtonFieldProps
  extends UseDateFieldProps<DateTime, false>,
    BaseSingleInputFieldProps<
      DateTime | null,
      DateTime,
      FieldSection,
      false,
      DateValidationError
    > {
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>
  isUpdating?: boolean
  label: string
}

const ButtonField = (props: ButtonFieldProps) => {
  const {
    id,
    label,
    setOpen,
    disabled,
    isUpdating = false,
    InputProps: { ref } = {},
    inputProps: { 'aria-label': ariaLabel } = {},
  } = props

  return (
    <Button
      id={id}
      ref={ref}
      variant="text"
      disabled={disabled}
      loading={isUpdating}
      aria-label={ariaLabel}
      data-testid="bulk-edit-date-time-picker-button"
      onClick={() => setOpen?.((prev) => !prev)}
    >
      {label}
    </Button>
  )
}

const BulkEditBarDateTimePicker = ({
  label,
  updateParams,
  containerIds,
  updateField = 'pickup_delivery_time',
}) => {
  const [open, setOpen] = useState(false)
  const [localValue, setLocalValue] = useState<DateTime | null>(null)

  const {
    mutateAsync: updateInlandTransport,
    isLoading: isUpdating,
  } = useUpdateInlandTransport({
    retry: 0,
  })

  const updateValues = async () => {
    if (localValue === null) {
      return
    }

    const formattedSelectedValue: string | null =
      localValue?.toFormat('dd-MM-yyyy HH:mm') ?? null

    await updateInlandTransport({
      mutationKey: [tableCellMutationKey],
      params: {
        custom_fields: customFields,
        inland_transports: containerIds.map((id: string) => ({
          container_id: id,
          ...updateParams,
          [updateField]: formattedSelectedValue,
        })),
      },
    })

    setLocalValue(null)
  }

  const handleClose = () => {
    setOpen(false)
    updateValues()
  }

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <Box>
        <DateTimePicker
          open={open}
          ampm={false}
          displayWeekNumber
          closeOnSelect={false}
          onClose={handleClose}
          value={localValue}
          slots={{ field: ButtonField }}
          data-testid="bulk-edit-date-time-picker"
          slotProps={{ field: { setOpen, isUpdating, label } as any }}
          onChange={(newValue) => {
            setLocalValue(newValue)
          }}
        />
      </Box>
    </LocalizationProvider>
  )
}

export default BulkEditBarDateTimePicker
