import { useMemo } from 'react'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import ToolbarInternalButtons from 'src/components/Common/Table/DataTable/Toolbar/ToolbarInternalButtons'
import Views from 'src/components/Overview/Views'
import Search from 'src/components/Overview/Search'
import SearchGroups from '../../SearchGroups'
import BulkEditBar from './BulkEditBar'

const CustomToolbar = ({
  table,
  rowSelection,
  onDismiss = () => {},
  permittedBulkEditableColumns,
}) => {
  const someRowsSelected = useMemo(() => {
    return Object.values(rowSelection).some((selected) => selected)
  }, [rowSelection])

  const tableRows = table.getSelectedRowModel()?.rows ?? []

  return (
    <>
      <Stack spacing={1} justifyContent="center">
        <Box px={2} pt={2}>
          <Stack
            spacing={2}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Views />
            <Stack direction="row" spacing={2} alignItems="center">
              <SearchGroups />
              <ToolbarInternalButtons table={table} />
            </Stack>
          </Stack>
        </Box>
        <Box px={2} pb={2} pt={1}>
          <Search />
        </Box>
        <Collapse in={someRowsSelected} data-testid="bulk-edit-bar-collapsible">
          <BulkEditBar
            onClose={onDismiss}
            tableRows={tableRows}
            permittedBulkEditableColumns={permittedBulkEditableColumns}
          />
        </Collapse>
      </Stack>
      <Divider sx={{ mb: 1 }} />
    </>
  )
}

export default CustomToolbar
