import { SyntheticEvent } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Autocomplete, TextField } from '@mui/material'
import { useGetAddressesAsync } from 'src/services/Api/common'
import BaseAutocompleteWrapper from 'src/components/Common/AutocompleteWrappers'
import { RenderedAutocompleteProps } from 'src/components/Common/AutocompleteWrappers/types'
import { getFormattedAddressData } from '../EditableCells/utils'

const TransportOrderDialogFormAddressesAddressSelect = ({
  shipmentOwnerOrganization,
  onChange,
  value,
}) => {
  const { t } = useTranslation()

  const { userOrganizationId } = useSelector((globalState: IGlobalState) => ({
    userOrganizationId: globalState.user.organizationId,
  }))

  return (
    <BaseAutocompleteWrapper
      formatter={getFormattedAddressData}
      fetchFunction={useGetAddressesAsync}
      defaultValue={value ? [value] : []}
      params={{
        page_size: '100',
        organization_ids: [shipmentOwnerOrganization, userOrganizationId],
      }}
      WrappedAutocompleteComponent={(props: RenderedAutocompleteProps) => {
        return (
          <Autocomplete
            fullWidth
            value={value}
            loading={props.loading}
            options={props.options}
            onInputChange={(
              _event: SyntheticEvent<Element, Event>,
              search: string
            ) => {
              props.onInputChange(search)
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={t('common.select', 'Select')}
              />
            )}
            noOptionsText={props.noOptionsText}
            onChange={(_event, newValue) => {
              onChange(newValue)
            }}
          />
        )
      }}
    />
  )
}

export default TransportOrderDialogFormAddressesAddressSelect
