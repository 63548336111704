import { FunctionComponent, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import Tooltip from '@mui/material/Tooltip'
import { Link } from 'react-router-dom'
import { Link as MuiLink, Checkbox } from '@mui/material'
import { uniqBy, union } from 'lodash'
import Table from 'src/components/Common/Table'

import { setPOSelect } from '../../../stores/actionCreators'
import { convertDatePickerToUniversalFormat } from '../../../utils/helpers'
import { promisifyAction } from '../../../utils'

import './styles.scss'

interface IProps {
  item: IPurchaseOrder
  opened: boolean
  editItem: () => void
  forShipment?: boolean
}

const PurchaseOrderRowHeader: FunctionComponent<IProps> = (props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { selectedLineIds } = useSelector((state: IGlobalState) => ({
    selectedLineIds: state.purchaseOrders.selectedLineIds || [],
  }))

  const setPOSelectAsync = promisifyAction(dispatch, setPOSelect)

  const [openMultipleShipments, setOpenMultipleShipments] = useState<boolean>(
    false
  )
  const { item } = props

  const sellerName: string | React.ReactNode = !!item.seller.address ? (
    item.seller.address.name
  ) : (
    <MuiLink variant="body1" component="button" onClick={props.editItem}>
      {t('purchase_orders.row_header.add_button', 'Add')}
    </MuiLink>
  )

  const sellerTooltip: string = !!item.seller.address
    ? item.seller.address.name
    : ''
  const loadingPort: string = !!item.loading_port ? item.loading_port.name : '-'
  const loadingPortTooltip: string = !!item.loading_port
    ? item.loading_port.name
    : ''
  const incoterm: string = !!item.incoterm ? item.incoterm.code : '-'

  const checkmarkTooltip = useMemo(() => {
    switch (true) {
      case item.to_plan && !!item.seller:
        return t(
          'purchase_orders.row_header.tooltips.products_need_to_be_planned',
          'You have one or multiple products that need to be planned'
        )
      case !item.to_plan && !!item.seller:
        return t(
          'purchase_orders.row_header.tooltips.products_all_planned',
          'All products in this PO are planned'
        )
      case !item.seller.address:
        return t(
          'purchase_orders.row_header.tooltips.missing_info',
          'This order has missing information.'
        )
      default:
        return ''
    }
  }, [item, t])

  const multipleLinks = (shipments: IShipmentLink[]): React.ReactElement => {
    return (
      <>
        {shipments.map((shipmentLink: IShipmentLink) => {
          return (
            <MuiLink
              variant="body1"
              component={Link}
              key={shipmentLink.id}
              to={`/shipments/${shipmentLink.id}/orders?open_item=${item.id}`}
            >
              SF{shipmentLink.id}
            </MuiLink>
          )
        })}
      </>
    )
  }

  const assignedShipments: React.ReactNode = useMemo(() => {
    const uniqueShipments = uniqBy(item.shipments || [], 'id')

    switch (uniqueShipments.length) {
      case 0:
        return '-'
      case 1:
        return (
          <MuiLink
            variant="body1"
            component={Link}
            to={`/shipments/${uniqueShipments[0].id}/orders?open_item=${item.id}`}
          >
            SF{uniqueShipments[0].id}
          </MuiLink>
        )
      default:
        return (
          <Tooltip
            title={multipleLinks(uniqueShipments)}
            placement="top-end"
            classes={{
              tooltip: 'multiple-links-tooltip mui-override',
            }}
          >
            <MuiLink
              variant="body1"
              component="button"
              onClick={setOpenMultipleShipments.bind(null, true)}
            >
              {t(
                'purchase_orders.row_header.multiple_assigned_shipments',
                'Multiple'
              )}
            </MuiLink>
          </Tooltip>
        )
    }
  }, [item, openMultipleShipments])

  const isSelected = item.purchase_order_lines.every((x) =>
    selectedLineIds.includes(x.id)
  )
  const indeterminate =
    !isSelected &&
    item.purchase_order_lines.some((x) => selectedLineIds.includes(x.id))

  const onCheckboxChange = (e) => {
    e.preventDefault()
    e.stopPropagation()
    const lineIds = item.purchase_order_lines.map((x) => x.id)
    if (isSelected) {
      setPOSelectAsync(selectedLineIds.filter((x) => !lineIds.includes(x)))
    } else {
      setPOSelectAsync(union(selectedLineIds, lineIds))
    }
  }

  return (
    <Table.StripedTableRow className={isSelected ? 'selected' : ''}>
      <Table.StripedTableCell className="short-50 visible">
        <div className="purchase-order-checkbox">
          <Checkbox
            checked={isSelected}
            indeterminate={indeterminate}
            onClick={onCheckboxChange}
          />
        </div>
      </Table.StripedTableCell>
      {!props.forShipment && (
        <Table.StripedTableCell
          className={`left-aligned to-plan x-short ${
            !item.to_plan && 'active'
          } ${!item.seller && 'warning'}`}
        >
          <Tooltip title={checkmarkTooltip} placement="right">
            <i className={`icon ${!item.to_plan ? 'success' : 'big-check'}`} />
          </Tooltip>
        </Table.StripedTableCell>
      )}
      <Table.StripedTableCell className="left-aligned x-medium">
        <Tooltip title={item.purchase_order_number} placement="top">
          <div className="text-wrapper">{item.purchase_order_number}</div>
        </Tooltip>
      </Table.StripedTableCell>
      <Table.StripedTableCell className="left-aligned wide">
        <Tooltip title={sellerTooltip} placement="top">
          <div className="text-wrapper">{sellerName}</div>
        </Tooltip>
      </Table.StripedTableCell>
      {!props.forShipment && (
        <>
          <Table.StripedTableCell className="left-aligned s-medium">
            {incoterm}
          </Table.StripedTableCell>
          <Table.StripedTableCell className="left-aligned">
            <Tooltip title={loadingPortTooltip} placement="top">
              <div className="text-wrapper">{loadingPort}</div>
            </Tooltip>
          </Table.StripedTableCell>
          <Table.StripedTableCell className="medium left-aligned">
            {convertDatePickerToUniversalFormat(item.order_date) || '-'}
          </Table.StripedTableCell>
        </>
      )}
      <Table.StripedTableCell className="medium left-aligned">
        {convertDatePickerToUniversalFormat(item.ex_factory_date) || '-'}
      </Table.StripedTableCell>
      <Table.StripedTableCell className="medium left-aligned">
        {convertDatePickerToUniversalFormat(item.cargo_ready_date) || '-'}
      </Table.StripedTableCell>
      <Table.StripedTableCell className="left-aligned">
        {parseFloat(item.total_volume_cbm) ? `${item.total_volume_cbm}m3` : '-'}
      </Table.StripedTableCell>
      <Table.StripedTableCell className="left-aligned">
        {parseFloat(item.total_weight_kg) ? `${item.total_weight_kg}kg` : '-'}
      </Table.StripedTableCell>
      {!props.forShipment && (
        <>
          <Table.StripedTableCell className="medium left-aligned">
            {assignedShipments}
          </Table.StripedTableCell>
        </>
      )}
      <Table.StripedTableCell className="short left-aligned">
        {props.item.exceptional && (
          <Tooltip title="This PO has exceptional events" placement="right">
            <i className="icon attention exceptional" />
          </Tooltip>
        )}
      </Table.StripedTableCell>
      <Table.StripedTableCell className="short left-aligned">
        <div className="po-toggle">
          <i className={`icon chevron ${props.opened ? '' : 'collapsed'}`} />
        </div>
      </Table.StripedTableCell>
    </Table.StripedTableRow>
  )
}

export default PurchaseOrderRowHeader
