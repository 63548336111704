import { Box, Chip, Typography, TypographyProps } from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'
import ReportProblemIcon from '@mui/icons-material/ReportProblem'
import DoNotDisturbOnOutlinedIcon from '@mui/icons-material/DoNotDisturbOnOutlined'
import { useTranslation } from 'react-i18next'
import { styled } from '@mui/material/styles'
import { useMemo } from 'react'

export const StyledChip = styled(Chip)(({ theme }) => ({
  '&.MuiChip-root': {
    width: '24px',
    height: '24px',
  },
  '&.MuiChip-root .MuiChip-label': {
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
  },
}))

const statuses = (t, codes: string[] = []) => ({
  active: {
    label: t('common.tracking_status.active', 'Active'),
    icon: null,
    codes: [t('common.tracking_status.active', 'Active')],
  },
  pending: {
    label: t('common.tracking_status.pending', 'Pending'),
    icon: (
      <StyledChip
        size="small"
        icon={<HistoryOutlinedIcon />}
        title={t('common.tracking_status.pending', 'Pending')}
      />
    ),
    codes,
  },
  completed: {
    label: t('common.tracking_status.completed', 'Completed'),
    icon: null,
    codes: [t('common.tracking_status.completed', 'Completed')],
  },
  archived: {
    label: t('common.tracking_status.archived', 'Archived'),
    icon: null,
    codes: [t('common.tracking_status.archived', 'Archived')],
  },
  paused: {
    label: t('common.tracking_status.paused', 'Paused'),
    icon: (
      <StyledChip
        size="small"
        icon={<InfoOutlinedIcon />}
        title={t('common.tracking_status.paused', 'Paused')}
      />
    ),
    codes,
  },
  no_subscription: {
    label: t('common.tracking_status.no_subscription', 'No subscription'),
    icon: (
      <StyledChip
        size="small"
        color="default"
        icon={<DoNotDisturbOnOutlinedIcon />}
        title={t('common.tracking_status.no_subscription', 'No subscription')}
      />
    ),
    codes,
  },
  invalid: {
    label: t('common.tracking_status.invalid', 'Invalid'),
    icon: (
      <StyledChip
        size="small"
        color="error"
        icon={<ReportProblemIcon />}
        title={t('common.tracking_status.invalid', 'Invalid')}
      />
    ),
    codes,
  },
  warning: {
    label: t('common.tracking_status.warning', 'Warning'),
    icon: (
      <StyledChip
        size="small"
        color="warning"
        icon={<ErrorOutlineOutlinedIcon />}
        title={t('common.tracking_status.warning', 'Warning')}
      />
    ),
    codes,
  },
  error: {
    label: t('common.tracking_status.error', 'Error'),
    icon: (
      <StyledChip
        size="small"
        color="error"
        icon={<ReportProblemIcon />}
        title={t('common.tracking_status.error', 'Error')}
      />
    ),
    codes,
  },
})

const OverviewTableShipmentTrackingStatus: React.FC<{
  status: string
  variant?: TypographyProps['variant']
  codes?: string[]
  'data-testid'?: string
}> = ({ status, variant = 'body1', codes, ...otherProps }) => {
  const { t } = useTranslation()
  const translatedStatuses = useMemo(() => statuses(t, codes), [])
  const trackingStatus = translatedStatuses[status]

  if (!trackingStatus)
    return (
      <Typography noWrap variant={variant} {...otherProps}>
        -
      </Typography>
    )

  return (
    <Typography
      title={trackingStatus.label}
      noWrap
      variant={variant}
      {...otherProps}
    >
      {trackingStatus.icon}
      <Box ml={1} component="span">
        {trackingStatus.codes
          .map((code) => t(`common.tracking_status.codes.${code}`, code))
          .join(', ')}
      </Box>
    </Typography>
  )
}

export default OverviewTableShipmentTrackingStatus
