import { FunctionComponent } from 'react'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Typography from '@mui/material/Typography'
import { Box, Paper } from '@mui/material'
import { DashboardTab } from 'src/pages/Dashboard'
import DashboardUpdatedByYou from 'src/components/DashboardOverview/DashboardUpdatedByYou'
import DashboardUpdatedByOthers from 'src/components/DashboardOverview/DashboardUpdatedByOthers'
import DashboardArriving from 'src/components/DashboardOverview/DashboardArriving'
import DashboardDeparting from 'src/components/DashboardOverview/DashboardDeparting'
import DashboardRecentlyBooked from 'src/components/DashboardOverview/DashboardRecentlyBooked'

import { permissionTo } from '../../utils'

const dashboardPageTab = {
  root: 'dashboard-page__tab media-override mui-override',
  selected: 'dashboard-page__tab_selected mui-override',
}

const getIndentation = (): string => {
  return permissionTo('dashboard.shipments_overview.activity_tracking.all')
    ? 'normal'
    : 'hide'
}

interface DashboardShipmentsOverviewProps {
  tab: string
  setTab: (tab: DashboardTab) => void
}

const DashboardShipmentsOverview: FunctionComponent<DashboardShipmentsOverviewProps> = ({
  tab,
  setTab,
}) => (
  <Box
    sx={{ contentVisibility: 'auto' }}
    data-testid="shipments-overview-component"
  >
    <div className="dashboard-header">
      <Typography variant="h4" children="Shipments overview" />
    </div>
    <Paper variant="elevation">
      <div className="dashboard__header tabs">
        <Tabs
          value={tab}
          classes={{
            root: 'dashboard-page__tab-panel mui-override',
            flexContainer: 'dashboard-page__tab-panel-container mui-override',
            indicator: 'dashboard-page__tab-indicator mui-override',
            scroller: `dashboard-page__tab-panel-scroller scroller-${getIndentation()} mui-override`,
          }}
          onChange={(event: React.ChangeEvent<{}>, value: DashboardTab) => {
            setTab(value)
          }}
        >
          {permissionTo(
            'dashboard.shipments_overview.activity_tracking.all'
          ) && (
            <Tab
              label="Updates by you"
              value="updated_by_you"
              classes={dashboardPageTab}
            />
          )}
          {permissionTo(
            'dashboard.shipments_overview.activity_tracking.all'
          ) && (
            <Tab
              label="Updates by others"
              value="updated_by_others"
              classes={dashboardPageTab}
            />
          )}
          <Tab label="Arriving" value="arriving" classes={dashboardPageTab} />
          <Tab label="Departing" value="departing" classes={dashboardPageTab} />
          <Tab
            label="Recently booked"
            value="recently_booked"
            classes={dashboardPageTab}
          />
        </Tabs>
        <div className="tabsBlock">
          {tab === 'updated_by_you' && <DashboardUpdatedByYou />}
          {tab === 'updated_by_others' && <DashboardUpdatedByOthers />}
          {tab === 'arriving' && <DashboardArriving />}
          {tab === 'departing' && <DashboardDeparting />}
          {tab === 'recently_booked' && <DashboardRecentlyBooked />}
        </div>
      </div>
    </Paper>
  </Box>
)

export default DashboardShipmentsOverview
