import {
  Box,
  InputLabel,
  Checkbox,
  IconButton,
  FormHelperText,
} from '@mui/material'
import MuiMultipleInput from 'src/components/Common/Input/MuiMultipleInput'
import { useTranslation } from 'react-i18next'
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded'
import isEmail from 'validator/lib/isEmail'

const EmailAddressesComponent = (props: {
  name: string
  label: string
  options?: { title: string; id: string }[]
  values: string[]
  onChange: ({
    options,
    values,
    isInvalid,
  }: {
    options?: { title: string; id: string }[]
    values: string[]
    isInvalid?: boolean
  }) => void
  'data-testid'?: string
}) => {
  const { t } = useTranslation()
  const onChange = (values: string[] | number[] | { [key: string]: any }[]) => {
    const newValues: string[] = values.map((v) => v.id ?? v)
    if (props.options) {
      const uniqueOptions = [
        ...new Set([...props.options.map((o) => o.id), ...newValues]),
      ]
      const newOptions = uniqueOptions.map((o) => ({ title: o, id: o }))
      props.onChange({
        values: newValues,
        options: newOptions,
      })
    } else {
      props.onChange({
        values: newValues,
      })
    }
  }

  const onDelete = (event, option: { title: string; id: string }) => {
    if (!props.options) return
    event.stopPropagation()
    const newOptions = props.options.filter((email) => email.id !== option.id)
    const newValues = props.values.filter((email) => email !== option.id)

    props.onChange({ values: newValues, options: newOptions })
  }

  const isError = props.values.some((v) => !isEmail(v))

  return (
    <Box>
      <InputLabel htmlFor={props.name}>{props.label}</InputLabel>
      <MuiMultipleInput
        data-testid={props['data-testid']}
        name={props.name}
        label=""
        freeSolo
        disableCloseOnSelect
        placeholder={t(
          'shipment_share.email.to.placeholder',
          'Enter email address(es)'
        )}
        options={props.options}
        onChange={onChange}
        values={props.values}
        saveOnBlur
        customOption={(optionProps, option, selected) => (
          <li {...optionProps}>
            <Checkbox style={{ marginRight: 8 }} checked={selected} />
            <div style={{ width: '100%' }}>{option['title']}</div>
            <IconButton
              aria-label="delete"
              onClick={(e) => onDelete(e, option)}
            >
              <DeleteRoundedIcon />
            </IconButton>
          </li>
        )}
      />
      <FormHelperText error={isError}>
        {isError
          ? t(
              'shipment_share.validations.email_invalid',
              'One or more email addresses are invalid'
            )
          : t(
              'shipment_share.email.press_enter_after_each_email',
              'Press enter after each email address'
            )}
      </FormHelperText>
    </Box>
  )
}

export default EmailAddressesComponent
