import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { TruncatedTextWithTooltip } from 'src/stories/MUI/Text/TruncatedTextWithTooltip'

const TopNavigationUserMenuInfo = ({ name, email }) => {
  return (
    <Box px={2} pt={0.5}>
      <Stack direction="row" alignItems="center" mb={0.5}>
        <Typography component="h3" variant="button" sx={{ flexGrow: 1 }}>
          {name}
        </Typography>
      </Stack>
      <TruncatedTextWithTooltip
        text={email}
        tooltipPlacement="top"
        typographyColor="grey.600"
        typographyVariant="subtitle1Medium"
      />
    </Box>
  )
}

export default TopNavigationUserMenuInfo
