import React, { useMemo } from 'react'
import {
  Autocomplete,
  Button,
  Checkbox,
  FormControlLabel,
  InputLabel,
  TextField,
} from '@mui/material'
import isEmail from 'validator/lib/isEmail'
import TextArea from 'src/stories/MUI/TextArea'
import Grid from '@mui/material/Grid2'
import { Dialog, DialogContent } from 'src/stories/Dialogs'
import {
  Controller,
  FormProvider,
  useForm,
  SubmitHandler,
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useCreateTransportEmail } from 'src/services/Api/overview'
import EmailAddressesComponent from 'src/components/ShipmentShareModal/EmailAddressesComponent'
import { useDispatch, useSelector } from 'react-redux'
import { showNotification } from 'src/stores/actionCreators/notifications'
import { OverviewInlandTransportDocument } from 'src/services/Api/overview/types'

interface TransportOrderEmailDialogProps {
  id: number | null
  open: boolean
  onClose: () => void
  service: string
  documents: OverviewInlandTransportDocument[]
  containerNumber: string
  transporterEmail: string
}

interface FormValues {
  cc: string[]
  comment: string
  replyTo: string
  documents: { value: number; label: string }[]
  transporterEmail: string
  sendCopyToMe: boolean
}

const TransportOrderEmailDialog: React.FC<TransportOrderEmailDialogProps> = ({
  id,
  open,
  onClose,
  service,
  documents,
  containerNumber,
  transporterEmail,
}) => {
  const { userEmail } = useSelector((globalState: IGlobalState) => ({
    userEmail: globalState.user.email,
  }))
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const methods = useForm<FormValues>({
    defaultValues: {
      cc: [],
      comment: '',
      replyTo: '',
      documents: [],
      transporterEmail,
      sendCopyToMe: false,
    },
  })

  const {
    mutateAsync: createTransportEmail,
    isLoading,
  } = useCreateTransportEmail()

  const documentOptions = useMemo(() => {
    return documents.map((document) => ({
      value: document.id,
      label: `${document.name} - ${document.document_types
        .map((documentType) => documentType.name)
        .join(', ')}`,
    }))
  }, [documents])

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    const userEmailAddress: string[] = data.sendCopyToMe ? [userEmail] : []

    const payload = {
      id,
      comment: data.comment,
      reply_to: data.replyTo,
      document_ids: data.documents.map((document) => document.value),
      cc: [...data.cc, ...userEmailAddress],
    }

    createTransportEmail(payload).then(() => {
      dispatch(
        showNotification({
          message: t(
            'common.notifications.transport_order_sent',
            'Transport order was sent.'
          ),
          severity: 'success',
        })
      )
      onClose()
    })
  }

  const validateEmails = (emails: string[]) => {
    if (emails.some((email: string) => !isEmail(email))) {
      return t(
        'shipment_share.validations.email_invalid',
        'One or more email addresses are invalid'
      )
    }
    return true
  }

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      open={open}
      title={t(`transport_order_form.send_${service}_transport_order`, {
        interpolation: { escapeValue: false },
        defaultValue: `Send ${service} transport order for {{containerNumber}}`,
        containerNumber,
      })}
      onClose={onClose}
      PaperProps={{
        component: 'form',
        onSubmit: (event) => {
          event.preventDefault()
          methods.handleSubmit((formData) => {
            onSubmit(formData)
          })(event)
        },
      }}
      actions={
        <>
          <Button
            size="large"
            variant="text"
            onClick={onClose}
            disabled={isLoading}
          >
            {t('common.buttons.cancel', 'Cancel')}
          </Button>
          <Button
            size="large"
            type="submit"
            variant="contained"
            loading={isLoading}
          >
            {t('common.buttons.send_transport_order_email', 'Send order')}
          </Button>
        </>
      }
      data-testid="transport-order-email-dialog"
    >
      <DialogContent>
        <FormProvider {...methods}>
          <Grid container spacing={3}>
            <Grid size={12}>
              <InputLabel required>
                {t('common.forms.fields.send_to.label', 'Send to')}
              </InputLabel>
              <Controller
                render={({ field: { value, onChange } }) => (
                  <TextField
                    disabled
                    fullWidth
                    value={value}
                    onChange={(event) => {
                      onChange(event.target.value)
                    }}
                  />
                )}
                name="transporterEmail"
                control={methods.control}
              />
            </Grid>
            <Grid size={6}>
              <Controller
                render={({ field: { value, onChange } }) => (
                  <EmailAddressesComponent
                    name="to_email_addresses"
                    label={t('common.forms.fields.cc.label', 'Cc')}
                    values={value}
                    options={[]}
                    onChange={({ values }) => {
                      onChange(values)
                    }}
                  />
                )}
                name="cc"
                control={methods.control}
                rules={{
                  validate: validateEmails,
                }}
              />
              <Controller
                render={({ field: { value, onChange } }) => (
                  <FormControlLabel
                    label={t(
                      'common.forms.fields.send_a_copy_to_yourself.label',
                      'Send a copy to yourself'
                    )}
                    sx={{ mt: 1 }}
                    control={
                      <Checkbox
                        checked={value}
                        onChange={onChange}
                        sx={{ pl: 0 }}
                      />
                    }
                  />
                )}
                name="sendCopyToMe"
                control={methods.control}
              />
            </Grid>
            <Grid size={6}>
              <InputLabel>
                {t('common.forms.fields.reply_email.label', 'Reply email')}
              </InputLabel>
              <Controller
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <TextField
                    fullWidth
                    value={value}
                    placeholder={t(
                      'common.forms.fields.reply_email.placeholder',
                      'Add your preferred reply email'
                    )}
                    onChange={(event) => {
                      onChange(event.target.value)
                    }}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
                name="replyTo"
                control={methods.control}
                rules={{
                  validate: (value) => {
                    if (value && !isEmail(value)) {
                      return t(
                        'common.forms.validations.email',
                        'This email is invalid'
                      )
                    }
                    return true
                  },
                }}
              />
            </Grid>
            <Grid size={12}>
              <InputLabel>
                {t('common.forms.fields.message.label', 'Message')}
              </InputLabel>
              <Controller
                render={({ field: { value, onChange } }) => (
                  <TextArea
                    sx={{ width: '100%', maxWidth: '100%' }}
                    value={value}
                    placeholder={t(
                      'common.forms.fields.message.placeholder',
                      'Add a message'
                    )}
                    minRows={4}
                    onChange={(event) => {
                      onChange(event.target.value)
                    }}
                  />
                )}
                name="comment"
                control={methods.control}
              />
            </Grid>
            <Grid size={12}>
              <InputLabel>
                {t('common.forms.fields.add_documents.label', 'Add documents')}
              </InputLabel>
              <Controller
                render={({ field: { value, onChange } }) => (
                  <Autocomplete
                    multiple
                    id="documents-select"
                    options={documentOptions}
                    value={value}
                    onChange={(_event, newDocuments) => {
                      onChange(newDocuments)
                    }}
                    slotProps={{
                      chip: {
                        size: 'small',
                        color: 'primary',
                      },
                    }}
                    defaultValue={[]}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={t('common.select', 'Select')}
                      />
                    )}
                  />
                )}
                name="documents"
                control={methods.control}
              />
            </Grid>
          </Grid>
        </FormProvider>
      </DialogContent>
    </Dialog>
  )
}

export default TransportOrderEmailDialog
