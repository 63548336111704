import { createReducer, Handlers, Reducer } from 'redux-create-reducer'
import { AnyAction } from 'redux'
import { Cable } from 'actioncable'

import * as actions from '../actions/user'
import createActionCable from '../../actionCable'

declare global {
  type UserType = 'User' | 'AdminUser'
  type CommunicationType = 'email' | 'in_app'
  interface ICredentials {
    uid?: string
    accessToken?: string
    clientToken?: string
  }

  interface IUserNotification {
    id: number
    typeId: number
    name: string
    group: string
    enabled: boolean
    order: number
    communication: CommunicationType
    groupOrder: number
  }

  interface IUserMilestone {
    id: number
    name: string
    enabled: boolean
    order: number
  }
  interface IUserTenantOrganization {
    id: number
    name: string
  }

  interface IDictionary<TValue> {
    [id: string]: TValue
  }

  interface IDashboardSetting {
    code: string
    enabled: boolean
    id: number
    name: string
  }

  interface IDashboardWidgets {
    task_manager: boolean
    shipments_overview: boolean
    pickup_and_delivery: boolean
    map: boolean
  }

  interface IUser extends ICredentials {
    shyppleLanguage: null | string
    browserLanguage: null | string
    isSuperUser: boolean
    isStaff: boolean
    isActive: boolean
    isDummy: boolean
    canBeMentioned: boolean
    isOrdersImportActive: boolean
    tokenType?: string
    inttraCompanyId: string
    watchShipmentSetting: 'all_shipments' | 'none_shipments' | 'custom'
    watchShipmentRules: IWatchShipmentRule[]
    username: string
    email: string
    firstName?: string
    lastName?: string
    companyName?: string
    phone?: string
    postalCode?: string
    city?: string
    street?: string
    vatNumber?: string
    countryId?: number
    id?: number
    avatar?: string
    configured_po_upload: boolean
    notifications: IUserNotification[]
    milestones: IUserMilestone[]
    cable?: Cable | null
    permissions: string[]
    organizationId: number
    preferredShipmentRoleId: number | null
    preferredFreightForwarderId: number | null
    preferredAirFreightForwarderId: number | null
    preferredSeaFreightForwarderId: number | null
    organizationRole: string
    organizationRoleId: number | null
    userRole: string
    userRoleId: number | null
    organizationLogo: string
    customerState: string
    createdAt: string
    organizationName: string
    can_buy: boolean
    organization_visibility_trial: boolean
    time_zone: string | null
    out_of_office_till: string
    user_dashboard_settings: IDashboardSetting[]
    tenantOrganization: null | IUserTenantOrganization
    shipment_groups_last_synced_at: string | null
    passwordExpirationDate: string
    hardBounceEmail: boolean
    invoicePerContainer: boolean
    costsTabGrouping: 'service' | 'container'
  }

  interface IUserState extends IUser {
    loading: boolean
  }
}

export const initialUserState: IUserState = {
  shyppleLanguage: null,
  browserLanguage: null,
  accessToken: '',
  avatar: '',
  configured_po_upload: false,
  city: '',
  clientToken: '',
  companyName: '',
  email: '',
  firstName: '',
  isActive: false,
  organization_visibility_trial: false,
  isDummy: false,
  canBeMentioned: true,
  isOrdersImportActive: false,
  isStaff: false,
  isSuperUser: false,
  lastName: '',
  inttraCompanyId: '',
  phone: '',
  postalCode: '',
  street: '',
  tokenType: '',
  uid: '',
  username: '',
  vatNumber: '',
  customerState: '',
  notifications: [],
  milestones: [],
  watchShipmentSetting: 'all_shipments',
  watchShipmentRules: [],
  loading: false,
  cable: createActionCable(),
  permissions: [],
  organizationId: 0,
  organizationRole: '',
  organizationRoleId: null,
  userRole: '',
  userRoleId: null,
  organizationLogo: '',
  createdAt: '',
  organizationName: '',
  can_buy: true,
  time_zone: null,
  preferredShipmentRoleId: null,
  preferredFreightForwarderId: null,
  preferredAirFreightForwarderId: null,
  preferredSeaFreightForwarderId: null,
  user_dashboard_settings: [],
  tenantOrganization: null,
  out_of_office_till: '',
  shipment_groups_last_synced_at: null,
  passwordExpirationDate: '',
  hardBounceEmail: false,
  invoicePerContainer: false,
  costsTabGrouping: 'service',
}

const saveReceivedUserData: Reducer<IUserState, AnyAction> = (
  state,
  { payload }
) => ({
  ...state,
  ...payload,
  loading: false,
})

const saveReceivedUserDashboardSettingsData: Reducer<IUserState, AnyAction> = (
  state,
  { payload }
) => ({
  ...state,
  user_dashboard_settings: payload,
})

const resetState: Reducer<IUserState, AnyAction> = (state, action) =>
  initialUserState

const raiseLoadingFlag: Reducer<IUserState, AnyAction> = (state, action) => ({
  ...state,
  loading: true,
})

const doNothing: Reducer<IUserState, AnyAction> = (state, { payload }) => ({
  ...state,
})

const actionHandlers: Handlers<IUserState> = {
  [actions.USER_SIGN_IN_SUCCESS]: saveReceivedUserData,
  [actions.USER_LOAD_STATE_SUCCESS]: saveReceivedUserData,
  [actions.USER_GET_USER_DATA_SUCCESS]: saveReceivedUserData,
  [actions.USER_UPDATE_USER_SETTINGS_DATA_SUCCESS]: saveReceivedUserDashboardSettingsData,
  [actions.USER_DO_NOTHING]: doNothing,
  [actions.USER_SIGN_OUT]: resetState,
  [actions.USER_LOAD_STATE_FAILURE]: resetState,
  [actions.USER_LOAD_STATE]: raiseLoadingFlag,
}

export default createReducer(initialUserState, actionHandlers)
