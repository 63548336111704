import { pick } from 'lodash'
import { FormAddressData, FormData } from './props'

const getPayloadDateTime = (datetime, index: number): string | null => {
  if (!datetime[index]) {
    return null
  }
  return datetime[index]
}

export const defaultAddressValues: FormAddressData = {
  id: '',
  address: null,
  reference: '',
  type: '',
  datetime: [],
  text: '',
  usedForPriceCalculation: false,
  _destroy: false,
}

export const getFormAddressDataFromInlandTransportAddressData = (
  inlandTransportAddressData
): FormAddressData[] => {
  return inlandTransportAddressData.map((address) => {
    const dateTime =
      address.datetime_from && address.datetime_to
        ? [address.datetime_from, address.datetime_to]
        : []
    return {
      id: address.id,
      address: address.address_id
        ? {
            value: address.address_id,
            label: address.name ?? '',
          }
        : null,
      reference: address?.reference ?? '',
      type: address?.address_type ?? '',
      datetime: dateTime,
      text: `${address?.name ?? ''} ${address?.address ?? ''} ${
        address?.postal_code ?? ''
      } ${address?.city ?? ''}`,
      usedForPriceCalculation: address.pickup_delivery,
      _destroy: false,
    }
  })
}

export const getInlandTransportPayloadFromAddressFormData = (
  formData: FormData,
  inlandTransportId: string,
  originalInlandTransportAddresses
) => {
  const formDataAddressIDs = formData.addresses.map((item) => item.id)
  const deletedAddresses = originalInlandTransportAddresses
    .filter((item) => !formDataAddressIDs.includes(item.id))
    .map((item) => {
      return {
        ...pick(item, [
          'id',
          'reference',
          'address_id',
          'datetime_to',
          'address_type',
          'datetime_from',
          'pickup_delivery',
        ]),
        _destroy: true,
      }
    })
  return {
    transporter_organization_id: formData.transporter?.value ?? null,
    modality: formData.modality,
    transport_status: formData.status,
    inland_transport_addresses: [
      ...formData.addresses.map((item, index) => {
        return {
          id: item.id,
          position: index + 1,
          address_id: item?.address?.value ?? null,
          address_type: item.type,
          reference: item.reference,
          datetime_from: getPayloadDateTime(item.datetime, 0),
          datetime_to: getPayloadDateTime(item.datetime, 1),
          inland_transport_id: inlandTransportId,
          _destroy: false,
          pickup_delivery: item.usedForPriceCalculation,
        }
      }),
      ...deletedAddresses,
    ],
  }
}
