import { LocalizationProvider } from '@mui/x-date-pickers-pro/LocalizationProvider'
import { AdapterLuxon } from '@mui/x-date-pickers-pro/AdapterLuxon'
import { DateTimeRangePicker } from '@mui/x-date-pickers-pro/DateTimeRangePicker'
import { DateTime } from 'luxon'

const TransportOrderDialogFormAddressesDate = ({ onChange, value }) => {
  const luxonValue = value.map((date) => DateTime.fromISO(date))

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <DateTimeRangePicker
        value={luxonValue}
        onChange={(newValue) => {
          onChange(newValue.map((date) => date?.toISO()))
        }}
        format="dd-MM-yyyy HH:mm"
        ampm={false}
        localeText={{ start: '', end: '' }}
      />
    </LocalizationProvider>
  )
}

export default TransportOrderDialogFormAddressesDate
