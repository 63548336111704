import { useContext } from 'react'
import { useMutation } from '@tanstack/react-query'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'
import { AxiosError } from 'axios'
import { FormContext } from '..'
import { apiClient } from '../../../services/http-common'
import { showNotification } from '../../../stores/actionCreators/notifications'
import ShippingInstructionsView from './ShippingInstructionsView'

const ShippingInstructions = ({ title }) => {
  const { instruction, shipmentId } = useContext(FormContext)
  const history = useHistory()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const shippingInstructionsMutation = useMutation({
    mutationFn: () => {
      dispatch(
        showNotification({
          message: t(
            'shipping_instructions.notifications.creating_instruction',
            'Creating a shipping instruction...'
          ),
          severity: 'info',
        })
      )
      return apiClient.post<any, any>(
        `shipments/${shipmentId}/shipping_instructions`,
        JSON.stringify(instruction)
      )
    },
    onError: (error: AxiosError) => {
      dispatch(
        showNotification({
          message: error?.response?.data?.message || 'No message',
          severity: 'error',
        })
      )
    },
    onSuccess: () => {
      dispatch(
        showNotification({
          message: t(
            'shipping_instructions.notifications.instruction_created',
            'Shipping instruction is created'
          ),
          severity: 'success',
        })
      )
      history.push(`/shipments/${shipmentId}/documentation`)
    },
  })

  return (
    <ShippingInstructionsView
      title={title}
      isSubmitting={shippingInstructionsMutation.isLoading}
      onSubmit={shippingInstructionsMutation.mutateAsync}
    />
  )
}

export default ShippingInstructions
