import { FunctionComponent, useState, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Typography from '@mui/material/Typography'
import { find } from 'lodash'
import { Box } from '@mui/material'
import DashboardTaskManager from 'src/components/DashboardTaskManager'
import DashboardMessageCenter from 'src/components/DashboardMessageCenter'
import DashboardWidgetCustomization from 'src/components/DashboardWidgetCustomization'
import DashboardPickupAndDelivery from 'src/components/DashboardPickupAndDelivery'
import DashboardShipmentsOverview from 'src/components/DashboardShipmentsOverview'
import { permissionTo } from 'src/utils/permissionManager'
import './styles.scss'

export type DashboardTab =
  | 'updated_by_you'
  | 'updated_by_others'
  | 'arriving'
  | 'departing'
  | 'recently_booked'

const Dashboard: FunctionComponent<{}> = () => {
  const { t } = useTranslation()
  const { user } = useSelector((state: IGlobalState) => ({
    user: state.user,
  }))

  const [tab, setTab] = useState<string>(
    permissionTo('dashboard.shipments_overview.activity_tracking.all')
      ? 'updated_by_others'
      : 'arriving'
  )

  const getDashboardWidgetVisibility = (code: string) => {
    const dashboardSetting:
      | IDashboardSetting
      | undefined = find(user.user_dashboard_settings, { code })
    return dashboardSetting?.enabled ?? false
  }

  const dashboardWidgetsVisibilities: IDashboardWidgets = useMemo(() => {
    return {
      task_manager: getDashboardWidgetVisibility('task_manager'),
      shipments_overview: getDashboardWidgetVisibility('shipments_overview'),
      pickup_and_delivery: getDashboardWidgetVisibility('pickup_and_delivery'),
      map: getDashboardWidgetVisibility('map'),
    }
  }, [user])

  const isMapShown = dashboardWidgetsVisibilities.map

  return (
    <div className="dashboard">
      <Box
        mb={2}
        sx={{
          display: 'flex',
          alignItems: 'end',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="h3">
          {user?.firstName?.length
            ? t('dashboard.hi', 'Hi, {{userName}}!', {
                userName: user.firstName,
              })
            : 'Hi there!'}
        </Typography>
        <DashboardWidgetCustomization />
      </Box>
      <DashboardMessageCenter withMap={isMapShown} />
      {dashboardWidgetsVisibilities.pickup_and_delivery && (
        <DashboardPickupAndDelivery />
      )}
      {dashboardWidgetsVisibilities.task_manager && <DashboardTaskManager />}
      {dashboardWidgetsVisibilities.shipments_overview && (
        <DashboardShipmentsOverview
          tab={tab}
          setTab={(value: DashboardTab) => setTab(value)}
        />
      )}
    </div>
  )
}

export default Dashboard
