import {
  Box,
  Grid,
  Paper,
  Typography,
  Link as MuiLink,
  Button,
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useTranslation, Trans } from 'react-i18next'
import { useContext } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { formatDate } from 'src/utils/helpers/shipmentDate'
import { FormContext } from '..'
import { freightPayableOptions, movementTypeOptions } from '../helpers'
import ControlledSelect from './ControlledSelect'
import ControlledTextArea from './ControlledTextArea'
import Party from './Party'
import ReferencesBlock from './ReferencesBlock'
import ShipmentInfoBlock from './ShipmentInfoBlock'
import ContainersBlock from './ContainersBlock'

const ItemWrapper = ({ children }) => (
  <Box
    sx={{
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    }}
  >
    {children}
  </Box>
)

interface IShippingInstructionProps {
  isSubmitting: boolean
  onSubmit: () => void
  title: string
}

const ShippingInstructions = ({
  isSubmitting,
  onSubmit,
  title,
}: IShippingInstructionProps) => {
  const { t } = useTranslation()
  const { instruction, bookingId, shipmentId } = useContext(FormContext)
  const history = useHistory()

  const isInstructionCreated = instruction.id !== null

  const fullTitle = isInstructionCreated
    ? t(
        'shipping_instructions.titles.shipping_instruction',
        'Shipping instructions - {{bookingTitle}}',
        { bookingTitle: title }
      )
    : t(
        'shipping_instructions.titles.finalise_shipping_instruction',
        '2. Finalise shipping instructions - {{bookingTitle}}',
        { bookingTitle: title }
      )

  const message1 = isInstructionCreated
    ? t(
        'shipping_instructions.messages.read_only',
        'This document has been already been submitted and is read-only.'
      )
    : [
        t(
          'shipping_instructions.messages.review_details',
          'Review the content and add details where needed.'
        ),
        t(
          'shipping_instructions.messages.mandatory_fields',
          'The fields marked with asterisk (*) are mandatory for Shipping instructions.'
        ),
      ]

  const message2 = isInstructionCreated ? (
    <Trans
      i18nKey="shipping_instructions.messages.send_chat_message"
      components={{
        link: (
          <MuiLink
            variant="body1"
            component={Link}
            to={`/shipments/${shipmentId}/chats`}
          />
        ),
      }}
      defaults="Please <link>send a chat message</link> to your freight forwarder if you wish to submit another version."
    />
  ) : (
    t(
      'shipping_instructions.messages.submit_document',
      'Once you are finished, click submit and the document will be uploaded to the shipment.'
    )
  )

  return (
    <Paper className="shipping-instructions" sx={{ position: 'relative' }}>
      <Box
        sx={{
          position: 'sticky',
          top: '56px',
          backgroundColor: 'var(--common-white)',
          zIndex: 1,
        }}
      >
        <Box pl={3} pr={3} pt={3} display="flex" justifyContent="space-between">
          <Typography
            variant="h3"
            gutterBottom
            data-testid="shipping-instruction-title"
          >
            {fullTitle}
          </Typography>
          <Box>
            {!isInstructionCreated && (
              <Button
                sx={{ mr: 2 }}
                size="medium"
                variant="outlined"
                onClick={() =>
                  history.push(
                    `/shipments/${shipmentId}/bookings/${bookingId}/shipping-instructions/steps/1`
                  )
                }
              >
                {t('common.actions.back', 'Back')}
              </Button>
            )}
            <LoadingButton
              data-testid="shipping-instruction-submit-button"
              loading={isSubmitting}
              size="medium"
              variant="contained"
              disabled={isInstructionCreated}
              onClick={onSubmit}
            >
              {t('common.actions.submit', 'Submit')}
            </LoadingButton>
          </Box>
        </Box>
        <Box pl={3} pr={3} pb={3}>
          <Typography gutterBottom>{message1}</Typography>
          <Typography>{message2}</Typography>
        </Box>
      </Box>
      <Box
        sx={{
          position: 'sticky',
          width: '100%',
          height: '10px',
          top: '179px',
          boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.07)',
        }}
      />
      <Box
        sx={{
          backgroundColor: 'white',
          height: '10px',
          position: 'absolute',
          width: '100%',
        }}
      />
      <Grid container spacing={3} p={3}>
        <Grid item xs={6}>
          <ItemWrapper>
            <ReferencesBlock references={instruction.references} />
          </ItemWrapper>
        </Grid>
        <Grid item xs={6}>
          <ShipmentInfoBlock instruction={instruction} />
        </Grid>
        {instruction.parties
          .filter((party) =>
            ['notify', 'consignee', 'shipper'].includes(party.role_type)
          )
          .map((party) => (
            <Grid item xs={6} key={party.role_type}>
              <ItemWrapper>
                <Party party={party} />
              </ItemWrapper>
            </Grid>
          ))}
        <Grid item xs={12}>
          <ContainersBlock cargos={instruction.cargos} editable={false} />
        </Grid>
        <Grid item xs={12}>
          <ControlledTextArea
            name="notes"
            label={t('shipping_instructions.sub_headers.notes', 'Notes')}
            minRows={3}
            disabled={isInstructionCreated}
          />
        </Grid>
        <Grid item xs={6}>
          <ControlledSelect
            name="freight_payable"
            label={t(
              'shipping_instructions.sub_headers.freight_payable',
              'Freight payable'
            )}
            options={freightPayableOptions(t)}
            disabled={isInstructionCreated}
          />
        </Grid>
        <Grid item xs={6}>
          <ControlledTextArea
            name="freight_payable_at"
            label={t(
              'shipping_instructions.sub_headers.freight_payable_at',
              'Freight payable at'
            )}
            disabled={isInstructionCreated}
          />
        </Grid>
        <Grid item xs={6}>
          <ControlledSelect
            name="movement_type"
            label={t(
              'shipping_instructions.sub_headers.movement_type',
              'Movement Type'
            )}
            options={movementTypeOptions(t)}
            disabled={isInstructionCreated}
          />
        </Grid>
        <Grid item xs={6}>
          <ControlledTextArea
            name="issue_place"
            label={t(
              'shipping_instructions.sub_headers.place_of_issue',
              'Place of issue'
            )}
            disabled={isInstructionCreated}
          />
        </Grid>
        <Grid item xs={12}>
          <table style={{ tableLayout: 'fixed' }}>
            <thead>
              <tr>
                <th>
                  {t(
                    'shipping_instructions.sub_headers.date_of_issue',
                    'Date of issue'
                  )}
                </th>
                <th>
                  {t(
                    'shipping_instructions.sub_headers.signature',
                    'Signature'
                  )}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{formatDate(instruction.issue_date)}</td>
                <td>{instruction.signature_by}</td>
              </tr>
            </tbody>
          </table>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default ShippingInstructions
