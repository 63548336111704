import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Typography, Grid } from '@mui/material'
import { useGetVisibilityShipmentCarriers } from 'src/services/Api/shipments'

const SupportedCarriers: FunctionComponent = () => {
  const {
    data: visibilityShipmentCarriers = [],
  } = useGetVisibilityShipmentCarriers()

  const { t } = useTranslation()

  return (
    <Box
      width={700}
      height={400}
      sx={{ overflow: 'auto' }}
      data-testid="supported-carriers"
    >
      <Typography variant="h4">
        {t(
          'common.forms.fields.carrier.tooltip.supported_carriers.title',
          'Supported carriers'
        )}
      </Typography>
      <Typography>
        {t(
          'common.forms.fields.carrier.tooltip.supported_carriers.description',
          'All carriers for which we offer track and trace support'
        )}
      </Typography>
      <Grid container mt={2}>
        {visibilityShipmentCarriers.map((carrier) => (
          <Grid item xs={4} key={carrier.id}>
            <Typography variant="body1" children={carrier.name} />
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}

export default SupportedCarriers
