import { InputLabel } from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import ModalitySelect from 'src/components/Common/ModalitySelect'

const TransportOrderDialogFormModality = () => {
  const { control } = useFormContext()
  const { t } = useTranslation()

  return (
    <>
      <InputLabel required>
        {t('common.forms.fields.modality.label', 'Modality')}
      </InputLabel>
      <Controller
        control={control}
        name="modality"
        render={({ field: { onChange, value } }) => (
          <ModalitySelect
            inlandOnly
            showLabel={false}
            defaultValue={value}
            onChangeModality={onChange}
          />
        )}
      />
    </>
  )
}

export default TransportOrderDialogFormModality
