import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Button, Stack, Typography } from '@mui/material'
import { Add } from '@mui/icons-material'
import TemplatesList from './TemplatesList'

const TemplatesListPage = () => {
  const { t } = useTranslation()

  return (
    <>
      <Stack
        spacing={2}
        direction="row"
        mb={2}
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h3">
          {t('templates.main_heading', 'Templates')}
        </Typography>
        <Button
          component={Link}
          size="large"
          startIcon={<Add />}
          to="/templates/create"
          variant="contained"
          color="primary"
        >
          {t('templates.button_new_template', 'New template')}
        </Button>
      </Stack>
      <TemplatesList />
    </>
  )
}

export default TemplatesListPage
