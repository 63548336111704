import { DateTime } from 'luxon'
import { useState } from 'react'
import { fromISOwithZone } from 'src/utils/helpers'
import { getDisplayDate } from '../utils'
import OverviewTableShipmentColoredCell from './OverviewTableShipmentColoredCell'

const OverviewTableFirstDayDemurrageCell = ({ row, field }) => {
  const [date] = useState(
    fromISOwithZone(row.original[field]).setZone(DateTime.local().zoneName)
  )

  const cellColor = () => {
    if (!date.isValid) return 'default'

    const diffDays = date.diff(DateTime.now(), 'days').days

    if (diffDays <= 0) {
      return 'error'
    }

    if (diffDays < 3) {
      return 'warning'
    }

    return 'default'
  }

  return (
    <OverviewTableShipmentColoredCell
      color={cellColor()}
      text={getDisplayDate({ date: row.original[field], omitTime: true })}
      data-testid="actual-first-day-of-demurrage"
    />
  )
}
export default OverviewTableFirstDayDemurrageCell
