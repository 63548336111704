import { call, select, CallEffect } from 'redux-saga/effects'
import { Method, ResponseType } from 'axios'
import requester from 'src/utils/requester'
import { credentialsSelector } from './'

interface IRequestData {
  responseType?: ResponseType
  headers?: any
  method: Method
  url: string
  data?: any
  params?: any
  signal?: any
}

function* authorizedRequest(requestData: IRequestData): Iterator<any> {
  const creds: any = yield select(credentialsSelector)
  const scopedOrganization: IOrganization | null = JSON.parse(
    window.localStorage.getItem('scopedOrganization') || 'null'
  )

  const requestOptions = {
    ...requestData,
    headers: {
      ...requestData.headers,
      uid: creds.uid,
      client: creds.clientToken,
      'access-token': creds.accessToken,
      'Access-Control-Allow-Origin': '*',
      'Cache-Control': 'no-cache',
    },
  }

  if (scopedOrganization) {
    requestOptions.headers['scoped-organization-id'] = scopedOrganization.id
  }
  return yield call([requester, 'request'], requestOptions)
}

export default (requestData: IRequestData): CallEffect =>
  call(authorizedRequest, requestData)
