import { FunctionComponent, useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Route, Switch, Redirect } from 'react-router'
import { History, LocationState } from 'history'
import { useLocation } from 'react-router-dom'
import Typography from '@mui/material/Typography'
import Portal from '@mui/material/Portal'
import { Paper, Tabs } from '@mui/material'
import AccountInfoSideBar from 'src/components/AccountInfo/AccountInfoSideBar'
import { useTheme } from '@mui/material/styles'
import { CircularProgress } from 'src/stories'
import NotifySettingsForm from 'src/components/NotifySettingsForm'
import MyShipments from 'src/components/MyShipments'
import OutOfOfficeTab from 'src/components/OutOfOfficeTab'
import Tab from 'src/components/Tab'
import { PathNames } from 'src/components/Tasks/constants'
import { useTranslation } from 'react-i18next'
import { promisifyAction } from '../../utils'
import {
  organizationGetData,
  userGetUserData,
} from '../../stores/actionCreators'

import 'src/pages/OrganizationSettings/styles.scss'
import 'src/components/OrganizationSettings/OrganizationForm/styles.scss'
import './styles.scss'

interface IProps {
  history: History<LocationState>
  location: Location
  match?: IMatch | null
}

const Account: FunctionComponent<IProps> = (props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const theme = useTheme()

  const [loading, setLoading] = useState<boolean>(false)
  const [tabValue, setTabValue] = useState<string>(PathNames.MyShipments)
  const location = useLocation()
  const userGetUserDataAsync = promisifyAction(dispatch, userGetUserData)
  const organizationGetDataAsync = promisifyAction(
    dispatch,
    organizationGetData
  )

  const currentLocation = () =>
    props.location?.pathname?.replace(PathNames.Account, '') as PathNames

  useEffect(() => {
    const fetchDataAsync = async () => {
      setLoading(true)
      await Promise.all([userGetUserDataAsync(), organizationGetDataAsync()])
      setLoading(false)
    }
    fetchDataAsync()

    if (location.hash) {
      scrollTo(location.hash)
    }
  }, [])

  useEffect(() => {
    setTabValue(
      [
        PathNames.MyShipments,
        PathNames.Notifications,
        PathNames.OutOfOffice,
      ].includes(currentLocation())
        ? currentLocation()
        : PathNames.MyShipments
    )
  }, [props.location])

  const scrollTo = (id: string) => {
    const anchor = document.querySelector(id)
    anchor &&
      anchor.scrollIntoView({
        behavior: 'smooth',
      })
  }

  return (
    <article className="organization-settings-page" data-testid="account-page">
      <div className="dashboard-header--top heading__2 blue">
        <Typography
          variant="h3"
          children={t('account.title', 'Account settings')}
        />
      </div>
      <div className="flex">
        <div className="organization-settings-page__wrapper">
          <AccountInfoSideBar />
        </div>

        <div className="account-page">
          {loading && (
            <Portal>
              <div className="account-page__loader-container">
                <CircularProgress />
              </div>
            </Portal>
          )}
          <Paper variant="elevation">
            <Tabs
              value={tabValue}
              sx={{
                borderBottom: `1px solid ${theme.palette.action.disabledBackground}`,
              }}
            >
              <Tab
                label={t('account.tabs.shipments.title', 'Shipments')}
                basePath="/account"
                value="/my_shipments"
                onTabClick={(value: string) => {
                  setTabValue(value)
                }}
              />
              <Tab
                label={t('account.tabs.notifications.title', 'Notifications')}
                basePath="/account"
                value="/notifications"
                onTabClick={(value: string) => {
                  setTabValue(value)
                }}
              />
              <Tab
                label={t('account.tabs.out_of_office.title', 'Out of Office')}
                basePath="/account"
                value="/out_of_office"
                onTabClick={(value: string) => {
                  setTabValue(value)
                }}
              />
            </Tabs>
            <Switch>
              <Route path="/account/my_shipments" component={MyShipments} />
              <Route
                path="/account/notifications"
                component={NotifySettingsForm}
              />
              <Route path="/account/out_of_office" component={OutOfOfficeTab} />
              <Redirect to={'/account/my_shipments'} />
            </Switch>
          </Paper>
        </div>
      </div>
    </article>
  )
}
export default Account
