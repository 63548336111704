import { FunctionComponent, memo } from 'react'
import { Helmet } from 'react-helmet'
import Box from '@mui/material/Box'
import { Typography } from '@mui/material'
import ReportsDashboardTable from './ReportsDashboardTable'
import ReportsDashboardTabs from './ReportsDashboardTabs'
import ReportsDashboardFilters from './ReportsDashboardFilters'
import ReportsDashboardPagination from './ReportsDashboardPagination'
import EditShipmentCommentModal from './EditShipmentCommentModal'
import './index.scss'

const Dashboard: FunctionComponent = () => {
  return (
    <>
      <Helmet>
        <title>Reports Dashboard</title>
      </Helmet>
      <Box>
        <Typography variant="h3">Dashboard</Typography>
        <Box mt={2}>
          <ReportsDashboardFilters />
        </Box>
        <Box
          m="2px"
          bgcolor="white"
          boxShadow="0px 0px 2px 1px rgba(0, 0, 0, 0.0442425)"
        >
          <ReportsDashboardTabs />
          <ReportsDashboardTable />
          <ReportsDashboardPagination />
          <EditShipmentCommentModal />
        </Box>
      </Box>
    </>
  )
}

export default memo(Dashboard, () => true)
