import { includes } from 'lodash'
import { FunctionComponent, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import qs from 'query-string'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import Typography from '@mui/material/Typography'
import { showNotification } from 'src/stores/actionCreators/notifications'
import { assignPurchaseOrdersToShipment } from 'src/stores/actionCreators'
import { useDispatch } from 'react-redux'
import { Button } from '@mui/material'
import ColorBadge from 'src/components/Common/ColorBadge'
import { ModalityEnum } from 'src/config/constants'
import SABSearchItemTimeline from '../SABSearchItem/components/SABSearchItemTimeline'
import './styles.scss'
import { ISABQuoteBookedProps } from './interfaces'
import EditReferencesBlock from './EditReferencesBlock'

const SABQuoteBooked: FunctionComponent<ISABQuoteBookedProps> = (props) => {
  const dispatch = useDispatch()
  const allPriceUnknown: boolean = props.quote.estimated_prices
  const allPriceIncomplete: boolean = props.quote.incomplete_prices
  const bookingType: string = props.booking.type || ''
  const isQuoteRequested: boolean = bookingType === 'quote request'
  const organizationName: string = props.quote.owner_organization_name
  const showOrganization: boolean =
    props.quote.owner_organization_id !== props.currentOrganizationId
  const validTo: boolean = !!props.quote.price_details.valid_to
  const { quote, modality } = props
  const { cheapest, fastest } = quote
  const isModalityAir = modality === ModalityEnum.Air
  const { schedule } = quote

  useEffect(() => {
    const bookingType: string = props.booking.type || ''
    const isQuoteRequested: boolean = bookingType === 'quote request'
    if (document.getElementsByClassName('booked-page').length) {
      dispatch(
        showNotification({
          message: isQuoteRequested ? 'Quote requested.' : 'Booking requested.',
          severity: 'success',
        })
      )
    }

    performPOAssignment(
      qs.parse(document.location.search, {
        arrayFormat: 'bracket',
      })
    )

    window.scrollTo(0, 0)
  }, [])

  const performPOAssignment = async (urlParams): Promise<any> => {
    if (urlParams.purchase_order_line_ids) {
      await dispatch(
        assignPurchaseOrdersToShipment({
          po_line_ids: urlParams.purchase_order_line_ids,
          shipment_id: props.booking.id,
        })
      )

      dispatch(
        showNotification({
          message: `${(urlParams.purchase_orders as string[]).join(
            ', '
          )} assigned to shipment`,
          severity: 'success',
        })
      )
    }
  }

  const displayDimensions = (item: IContainerShort): string => {
    if (item.length_mm && item.width_mm && item.height_mm) {
      return `${item.length_mm / 10} x ${item.width_mm / 10} x ${
        item.height_mm / 10
      } cm`
    }
    return '-'
  }

  const { t } = useTranslation()

  return (
    <div className="search-book__quote" data-testid="search-book-quote-booked">
      <div className="search-book__quote__one" style={{ display: 'flex' }}>
        <Paper className="search-book__info">
          <div className="search-book__info--block booked-page">
            <div className="info__left-part">
              {modality && (
                <SABSearchItemTimeline
                  showAlert={true}
                  showCarrier={true}
                  schedule={schedule}
                  modality={modality}
                  pickupAddress={quote.pickup_address}
                  deliveryAddress={quote.delivery_address}
                  cargoClosingDate={quote.cargo_closing_date}
                />
              )}
              <div className="info-company">
                {validTo && !isModalityAir && (
                  <div className="button-container" data-testid="info-company">
                    {props.search.match(/is_cheapest=([^&]+)(&|$)/) && (
                      <div className="button-container__button button-container__button--orange">
                        {t('search_and_book.cheapest', 'Cheapest')}
                      </div>
                    )}
                    {props.search.match(/is_fastest=([^&]+)(&|$)/) && (
                      <div className="button-container__button">
                        {' '}
                        {t('search_and_book.fastest', 'Fastest')}
                      </div>
                    )}
                    {props.quote.schedule.direct_route && (
                      <>
                        <div
                          className="button-container__button button-container__button--direct"
                          data-testid="search-book-direct-route"
                        >
                          {t(
                            'search_and_book.direct_sailing',
                            'Direct sailing'
                          )}
                        </div>
                      </>
                    )}
                    {(allPriceUnknown || allPriceIncomplete) &&
                      !!props.quote.price_details.services.length && (
                        <div className="button-container__button button-container__button--disabled">
                          {t(
                            'search_and_book.price_estimation_available',
                            'Price estimation available'
                          )}
                        </div>
                      )}
                    {props.quote.schedule.predicted_schedule &&
                      !!props.quote.schedule.id &&
                      !props.quote.schedule.direct_route && (
                        <>
                          <div className="button-container__button button-container__button--disabled">
                            {t(
                              'search_and_book.by_predicted_schedule',
                              'By predicted schedule'
                            )}
                          </div>
                          <div className="button-container__text">
                            {t(
                              'search_and_book.sailing_dates_not_known_yet',
                              'The exact sailing is not known yet. These dates are an estimate. We will confirm the ETD &amp; ETA as soon as possible.'
                            )}
                          </div>
                        </>
                      )}
                  </div>
                )}
                <img className="info-company__logo" alt="" />
              </div>
            </div>
          </div>
          {includes(['air', 'lcl'], props.shipment_type) && (
            <Table className="info__lcl">
              <TableHead>
                <TableRow>
                  <TableCell className="center">
                    {t('shipment_costs.table_header.quantity', 'Quantity*')}
                  </TableCell>
                  <TableCell className="left">
                    {t('templates.cargo_details.package_type', 'Package type*')}
                  </TableCell>
                  <TableCell className="left">
                    {t(
                      'templates.cargo_details.description',
                      'Goods description'
                    )}
                  </TableCell>
                  <TableCell>
                    {t('templates.cargo_details.dimensions', 'Dimensions')}
                  </TableCell>
                  <TableCell>
                    {t('search_and_book.total_volume', 'Total volume*')}
                  </TableCell>
                  <TableCell>
                    {t('search_and_book.total_weight', 'Total weight*')}
                  </TableCell>
                  <TableCell>
                    {t('templates.cargo_details.stackable', 'Stackable')}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.containersArray.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell className="center">{item.quantity} x</TableCell>
                    <TableCell className="left">
                      {item.package_type || '-'}
                    </TableCell>
                    <TableCell className="left">
                      {item.goods_description || '-'}
                    </TableCell>
                    <TableCell>{displayDimensions(item)}</TableCell>
                    <TableCell>{item.volume_cbm} m3</TableCell>
                    <TableCell>{item.weight_kg} kg</TableCell>
                    <TableCell>{item.stackable ? 'Yes' : 'No'}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </Paper>
        <div style={{ marginLeft: '16px' }} />
        <EditReferencesBlock
          id={props.booking.id}
          title={props.booking.title}
        />
      </div>
      {cheapest && (
        <ColorBadge content="Cheapest" className="yellow-outlined" />
      )}
      {fastest && <ColorBadge content="fastest" className="purple-outlined" />}
      <Paper
        className="search-book__description"
        data-testid="search-book__shipment_info"
      >
        <div className="search-book__shipment_info">
          <div className="search-book__shipment_info--first">
            <div className="block-title" data-testid="block-title">
              <Typography variant="h4">
                {t('search_and_book.thank_you', 'Thank you for your')}{' '}
                {bookingType ===
                t('search_and_book.quote_request', 'quote request')
                  ? t('search_and_book.quotation', 'quotation')
                  : t('search_and_book.booking', 'booking')}{' '}
              </Typography>
            </div>
            <div className="block-list">
              <div className="block-list-item">
                <i className="icon check" />
                <div className="block-list-item--text">
                  <Typography
                    variant="body1"
                    children={t(
                      'search_and_book.created_page_shipment_details',
                      'We have created a page where you can find all the details about your shipment.'
                    )}
                  />
                </div>
              </div>
              {showOrganization && (
                <div className="block-list-item">
                  <i className="icon check" />
                  <div className="block-list-item--text">
                    <Typography variant="body1">
                      {t(
                        'search_and_book.shipment_visible_for',
                        'This shipment is also visible to the users of organization:'
                      )}{' '}
                      {organizationName}
                    </Typography>
                  </div>
                </div>
              )}
              <div className="block-list-item">
                <i className="icon check" />
                <div className="block-list-item--text">
                  <Typography
                    variant="body1"
                    children={t(
                      'search_and_book.conversation_started',
                      'A conversation has been started for this shipment between you and your Shypple team.'
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            className="search-book__shipment_info--last"
            data-testid="search-book__shipment_info--last"
          >
            <div className="block-title">
              <Typography
                variant="h4"
                children={t('search_and_book.what_is_next', 'What is next?')}
              />
            </div>
            <div className="block-list">
              <div className="block-list-item">
                <span className="block-list--bullet">&bull;</span>
                <div className="block-list-item--text">
                  <Typography
                    variant="body1"
                    children={t(
                      'search_and_book.provide_references',
                      'Please provide us with your reference.'
                    )}
                  />
                </div>
              </div>
              <div className="block-list-item">
                <span className="block-list--bullet">&bull;</span>
                <div className="block-list-item--text">
                  <Typography
                    variant="body1"
                    children={t(
                      'search_and_book.visit_shipments_page',
                      'Visit your shipments page and provide us with the shipping instructions, packing list and commercial invoice.'
                    )}
                  />
                </div>
              </div>
              <div className="block-list-item">
                <span className="block-list--bullet">&bull;</span>
                <div className="block-list-item--text">
                  <Typography variant="body1">
                    {t(
                      'search_and_book.created_page_shipment_details',
                      'We have created a page where you can find all the details about your shipment.'
                    )}{' '}
                    {isQuoteRequested
                      ? t(
                          'search_and_book.once_prices_available',
                          'once we have prices for all your requested services.'
                        )
                      : t(
                          'search_and_book.space_availability_confirmed',
                          'when space and availability have been confirmed by the carrier.'
                        )}
                  </Typography>
                </div>
              </div>
            </div>
            <div className="block-list-button">
              <Button
                variant="outlined"
                data-testid="go-to-shipment"
                component={Link}
                to={`/shipments/${props.booking.id}/bookings`}
              >
                {t(
                  'search_and_book.go_to_your_shipment',
                  'Go to your shipment'
                )}
              </Button>
            </div>
          </div>
        </div>
      </Paper>
    </div>
  )
}

export default SABQuoteBooked
