import { FunctionComponent, useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import { Button } from '@mui/material'
import InlineNotification from 'src/components/Common/InlineNotification'
import ShipmentBookingTermsAndConditions from 'src/components/Common/ShipmentBookingTermsAndConditions'

import { getSellerHasAllDocuments } from 'src/components/Common/ShipmentBookingTermsAndConditions/utils'
import ConfirmDialog from '../../ConfirmDialog/index'

import { convertDateToUniversalFormat } from '../../../utils/helpers'

import './styles.scss'

interface IProps {
  quote: ISearchQuote
  currentUserId: number
  collaboratorsRolesSet: boolean
  collaboratorsWarningAction: () => {}
  bookingSubmit: () => {}
}

const SABTermsConditions: FunctionComponent<IProps> = (props) => {
  const sellerHasAllDocuments = getSellerHasAllDocuments(
    props.quote.seller_organization
  )
  const [termsChecked, setTermsChecked] = useState<boolean>(
    !sellerHasAllDocuments
  )
  const [dialogOpened, setDialogOpened] = useState<boolean>(false)

  const allPriceUnknown: boolean = props.quote.estimated_prices
  const allPriceIncomplete: boolean = props.quote.incomplete_prices
  const createdBySupplier: boolean = props.quote.user_id !== props.currentUserId
  const validTo = props.quote.price_details.valid_to
  const { t } = useTranslation()
  const oceanFreightService:
    | IPriceServices
    | undefined = props.quote.price_details.services.find(
    (x) => x.service_name === 'freight'
  )
  const displayLatestEtd: boolean = !!(
    oceanFreightService && !oceanFreightService.price_on_request
  )

  let buttonText: string = ''
  if (createdBySupplier) {
    buttonText = t('search_and_book.place_booking', 'Place booking')
  } else if (
    !allPriceUnknown &&
    !allPriceIncomplete &&
    props.quote.price_details.services.length
  ) {
    buttonText = t('search_and_book.request_booking', 'Request booking')
  } else {
    buttonText = t('search_and_book.request_quote', 'Request quote')
  }

  const requestText = (t) =>
    createdBySupplier
      ? t(
          'search_and_book.initiate_booking',
          'Initiate booking for your customer.'
        )
      : t(
          'search_and_book.request_quote_complete',
          'Request quote and receive the complete quotation.'
        )

  const submit = useCallback(() => {
    if (termsChecked) {
      props.bookingSubmit()
    } else {
      setDialogOpened(true)
    }
  }, [props.bookingSubmit, termsChecked])

  const sellerOrganizationName: string = props.quote.seller_organization
    ? props.quote.seller_organization.name
    : 'Shypple'

  return (
    <Paper className="quote__footer" data-testid="search-book-terms-conditions">
      <div className="quote__footer__notification">
        <InlineNotification
          color="warning"
          show={!props.collaboratorsRolesSet}
          actionName={t(
            'search_and_book.collaborators.add_manage_collaborators',
            'Add/manage collaborators'
          )}
          action={props.collaboratorsWarningAction}
          message="You can't request the booking yet. Please indicate the role of all the shipment collaborators first."
        />
      </div>
      <div className="quote__footer__desc">
        <div className="footer__empty" />

        <div className="footer__accept">
          <div className="footer__accept--container">
            {sellerHasAllDocuments && (
              <Typography variant="body1">
                <ShipmentBookingTermsAndConditions
                  forwarderName={sellerOrganizationName}
                  seller={props.quote.seller_organization}
                />
              </Typography>
            )}
          </div>
        </div>

        <div className="footer__results-booking">
          <div className="footer__booking">
            {sellerHasAllDocuments && (
              <FormControlLabel
                className="outline"
                control={
                  <Checkbox
                    checked={termsChecked}
                    onChange={setTermsChecked.bind(null, (prev) => !prev)}
                    name="accept-terms"
                    data-testid="terms"
                    color="primary"
                  />
                }
                label={
                  <Typography variant="body1Strong" className="pr-20">
                    {t(
                      'templates.booking_request.accept_terms',
                      'Accept terms'
                    )}
                  </Typography>
                }
              />
            )}
            <Button
              size="medium"
              color="primary"
              variant="contained"
              data-testid="request-quote-price"
              onClick={submit}
              disabled={!props.collaboratorsRolesSet || !termsChecked}
            >
              {buttonText}
            </Button>
          </div>
          <div className="footer__total-results">
            <div className="total-results__warning">
              <Typography
                variant="subtitle1"
                children={
                  props.quote.schedule.id
                    ? t(
                        'common.notifications.subject_to_space_and_availability',
                        'Schedule is subject to space and availability'
                      )
                    : t(
                        'search_and_book.schedule_is_to_be_advised',
                        'Schedule is to advised'
                      )
                }
              />
            </div>
            <div className="total-results__warning latest-etd">
              {validTo && !allPriceUnknown && displayLatestEtd ? (
                <>
                  <Typography variant="subtitle1">
                    Latest ETD for this rate:{' '}
                    <b>
                      {convertDateToUniversalFormat(
                        props.quote.price_details.valid_to
                      )}
                    </b>
                  </Typography>
                </>
              ) : (
                <Typography variant="subtitle1" children={requestText(t)} />
              )}
            </div>
          </div>
        </div>
      </div>
      <ConfirmDialog
        message="Please accept our terms and conditions before you continue."
        isOpen={dialogOpened}
        confirm={setDialogOpened.bind(null, false)}
        onClose={setDialogOpened.bind(null, false)}
        isConfirmOnly={true}
        confirmButtonText="OK"
      />
    </Paper>
  )
}

export default SABTermsConditions
