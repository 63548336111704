import { FunctionComponent } from 'react'
import { EmptyState } from 'src/stories'
import { RequestQuoteOutlined } from '@mui/icons-material'
import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'

interface IRatesNoResults {
  message: string
}

const RatesNoResults: FunctionComponent<IRatesNoResults> = ({ message }) => {
  const { t } = useTranslation()
  return (
    <Box overflow="hidden">
      <EmptyState
        badgeColor="primary"
        badgeIcon={RequestQuoteOutlined}
        title={t('rates.empty_state.title', 'No lanes, no rates')}
        description={
          message
            ? message
            : t(
                'rates.empty_state.description',
                'There are no rates to show. To set up tradelanes and make booking easier, contact our customer success team via the support button in the bottom right corner of the app.'
              )
        }
      />
    </Box>
  )
}

export default RatesNoResults
