import { Button, Box, Link, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { FunctionComponent, useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { showNotification } from 'src/stores/actionCreators/notifications'
import {
  getOrganizationApiToken,
  postOrganizationApiToken,
  deleteOrganizationApiToken,
} from '../../../stores/actionCreators'

import { promisifyAction } from '../../../utils'

import './styles.scss'

const ApiToken: FunctionComponent<{}> = (props) => {
  const dispatch = useDispatch()
  const [tokenReceived, setTokenReceived] = useState<boolean>(false)

  const { apiToken } = useSelector((state: IGlobalState) => ({
    apiToken: state.organizationSettings.api_token,
  }))

  const getOrganizationApiTokenAsync = promisifyAction(
    dispatch,
    getOrganizationApiToken
  )

  const postOrganizationApiTokenAsync = promisifyAction(
    dispatch,
    postOrganizationApiToken
  )
  const deleteOrganizationApiTokenAsync = promisifyAction(
    dispatch,
    deleteOrganizationApiToken
  )

  const copy = useCallback(() => {
    if (apiToken) {
      navigator.clipboard.writeText(apiToken)
      dispatch(
        showNotification({
          message: 'API token is copied to your clipboard.',
          severity: 'success',
        })
      )
    }
  }, [apiToken])

  const { t } = useTranslation()

  const receiveApiToken = useCallback(async () => {
    await getOrganizationApiTokenAsync()
    setTokenReceived(true)
  }, [])

  return (
    <div className="api-token-page">
      <Typography variant="h5">
        {t('organization_settings.api_token.header', 'Server API token')}
      </Typography>
      <Typography variant="body1" mb={2} mt={1}>
        {t(
          'organization_settings.api_token.description',
          'Interested in our public API? Please contact us at'
        )}{' '}
        <Link
          variant="body1"
          component="button"
          onClick={() => {
            window.location.href = 'mailto:CS@shypple.com'
          }}
        >
          CS@shypple.com
        </Link>{' '}
      </Typography>

      {tokenReceived ? (
        <>
          <div className="api-token-page__label">
            {t('organization_settings.api_token.button.api_token', 'API token')}
          </div>
          <div
            className={`api-token-page__token ${apiToken ? 'active' : ''}`}
            onClick={copy}
          >
            {apiToken ||
              'No API token available because there is no service account'}
          </div>
          <div className="api-token-page__actions">
            {apiToken && (
              <>
                <Button
                  variant="outlined"
                  onClick={deleteOrganizationApiTokenAsync}
                >
                  {t(
                    'organization_settings.api_token.button.disable_token',
                    'Disable token'
                  )}
                </Button>
                <Box mr={1} display="inline-block" />
              </>
            )}
            <Button onClick={postOrganizationApiTokenAsync} variant="contained">
              {apiToken ? 'Generate new token' : 'Request API service account'}
            </Button>
          </div>
        </>
      ) : (
        <Button onClick={receiveApiToken} variant="contained">
          {t(
            'organization_settings.api_token.button.receive_token',
            'Receive API token'
          )}
        </Button>
      )}
    </div>
  )
}

export default ApiToken
