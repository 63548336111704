import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import SettingsIcon from '@mui/icons-material/Settings'
import { useTranslation } from 'react-i18next'
import {
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  FormControlLabel,
  Checkbox,
  FormGroup,
  Button,
  Box,
  Typography,
} from '@mui/material'
import { sortBy } from 'lodash'
import Drawer from 'src/stories/Drawer'
import LoadingButton from '@mui/lab/LoadingButton'
import { showNotification } from 'src/stores/actionCreators/notifications'
import {
  shipmentsWatchedByToggle,
  userUpdateUserSettingsData,
} from '../../stores/actionCreators'
import { promisifyAction } from '../../utils'
import WatchShipmentsToggler from '../Common/WatchShipmentsToggler'

// Order of elements in array is required, should be same as order of widgets on page
const settingsToDisplay: string[] = [
  'map',
  'pickup_and_delivery',
  'task_manager',
  'shipments_overview',
]

interface Options {
  widgets: IDashboardSetting[]
  table_density: IDashboardSetting
}

const DashboardWidgetCustomization: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { settings, onlyWatchedShipments } = useSelector(
    (state: IGlobalState) => ({
      settings: state.user.user_dashboard_settings,
      onlyWatchedShipments: state.shipmentsWatchedBy.onlyWatchedShipments,
    })
  )

  const [options, setOptions] = useState<Options>({
    widgets: sortBy(
      settings.filter((x) => settingsToDisplay.includes(x.code)),
      (item) => settingsToDisplay.indexOf(item.code)
    ),
    table_density: settings.find(
      (setting) => setting.code === 'compact_table_density'
    ) as IDashboardSetting,
  })

  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClickClose = () => {
    setOpen(false)
  }

  const handleWatchedByToggleClick = (newValue) => {
    localStorage.setItem('dashboardMyShipments', JSON.stringify(newValue))
    dispatch(shipmentsWatchedByToggle(newValue))
  }

  const updateUserSettingsAsync = promisifyAction(
    dispatch,
    userUpdateUserSettingsData
  )

  const handleOnChange = (name: string, value: number | string | boolean) => {
    if (name === 'table_density') {
      setOptions((prev: Options) => ({
        ...prev,
        [name]: {
          ...prev.table_density,
          enabled: value === 'true',
        },
      }))
    } else {
      setOptions((prev: Options) => ({
        ...prev,
        [name]: prev.widgets.map((widget) => ({
          ...widget,
          id: widget.id,
          enabled: value === widget.id ? !widget.enabled : widget.enabled,
        })),
      }))
    }
  }

  const handleSubmit = async () => {
    setLoading(true)
    const data = {
      user_dashboard_settings_attributes: [
        ...options.widgets.map((option) => ({
          id: option.id,
          enabled: option.enabled,
        })),
        {
          id: options.table_density.id,
          enabled: options.table_density.enabled,
        },
      ],
    }
    await updateUserSettingsAsync(data)
    setLoading(false)
    dispatch(
      showNotification({
        message: t(
          'dashboard.change_layout.layout_updated',
          'Dashboard layout updated.'
        ),
        severity: 'success',
      })
    )
  }

  return (
    <>
      <Box sx={{ float: 'right', display: 'flex', alignItems: 'center' }}>
        <WatchShipmentsToggler
          value={onlyWatchedShipments}
          action={handleWatchedByToggleClick}
        />
        <Button
          variant="outlined"
          onClick={handleClickOpen}
          sx={{ ml: 2, verticalAlign: 'text-bottom' }}
          startIcon={<SettingsIcon />}
          data-testid="dashboard-customization-widget"
          size="large"
        >
          {t('dashboard.change_layout.title', 'Change layout')}
        </Button>
      </Box>
      <Drawer
        anchor="right"
        onClose={handleClickClose}
        open={open}
        ModalProps={{ disableScrollLock: true }}
        data-testid="change-dashboard-layout-drawer"
        heading={t(
          'dashboard.change_layout.heading',
          'Change dashboard layout'
        )}
      >
        <Box sx={{ pt: 0, pb: 0.5 }}>
          <FormGroup>
            <FormLabel id="widgets" sx={{ mt: 2, mb: 1 }}>
              <Typography variant="h5">
                {t('dashboard.change_layout.widgets', 'Widgets')}
              </Typography>
            </FormLabel>

            {options.widgets.map((option) => (
              <FormControlLabel
                data-testid={option.code}
                key={option.id}
                sx={{ py: 0.5 }}
                value={option.id}
                label={option.name}
                control={<Checkbox />}
                disabled={loading}
                checked={option.enabled}
                onChange={() => handleOnChange('widgets', option.id)}
              />
            ))}
          </FormGroup>

          <FormControl>
            <FormLabel id="table-density" sx={{ mt: 2, mb: 1 }}>
              <Typography variant="h5">
                {t('dashboard.change_layout.table_density', 'Table density')}
              </Typography>
            </FormLabel>
            <RadioGroup
              aria-labelledby="table-density"
              value={options.table_density?.enabled.toString()}
              name="table_density"
              onChange={(event, value) =>
                handleOnChange(event.target.name, value)
              }
            >
              <FormControlLabel
                data-testid="compact"
                value="true"
                disabled={loading}
                control={<Radio />}
                label={t(
                  'dashboard.change_layout.table_density.compact',
                  'Compact'
                )}
              />
              <FormControlLabel
                data-testid="comfortable"
                value="false"
                disabled={loading}
                control={<Radio />}
                label={t(
                  'dashboard.change_layout.table_density.comfortable',
                  'Comfortable'
                )}
              />
            </RadioGroup>
          </FormControl>

          <Box mt={4} display="flex" justifyContent="end">
            <LoadingButton
              variant="contained"
              loading={loading}
              onClick={handleSubmit}
            >
              {t('common.buttons.save', 'Save')}
            </LoadingButton>
          </Box>
        </Box>
      </Drawer>
    </>
  )
}

export default DashboardWidgetCustomization
