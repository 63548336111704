import Autocomplete, {
  AutocompleteRenderInputParams,
} from '@mui/material/Autocomplete'
import { useMemo } from 'react'
import PopperComponent from 'src/stories/Lab/Autocomplete/AutocompletePopperComponent'
import { getRenderInput } from 'src/stories/Lab/Autocomplete/Autocomplete.utils'
import { AutocompleteOptionProps } from 'src/stories/Lab/Autocomplete/Autocomplete.props'
import { StyledBaseAutocompletePopper } from '../../styled'
import AutocompleteBaseSelectAll from './AutocompleteBaseSelectAll'

const AutocompleteBasePopper = ({
  id,
  open,
  value,
  anchorEl,
  multiple,
  popperMaxWidth,
  onSelectChange,
  loading,
  onClose,
  onInputChange,
  noOptionsText,
  options,
  optionKey,
  asynchronous,
  selectAll,
  inputPlaceholder,
  onSelectAll = () => {},
  ...autocompleteProps
}) => {
  const renderInput = (props: AutocompleteRenderInputParams) => {
    const Input = getRenderInput({
      ...props,
      inputProps: {
        ...props.inputProps,
        placeholder: inputPlaceholder,
      },
    })
    return (
      <>
        <div>{Input}</div>
        {!asynchronous && onSelectAll && (
          <AutocompleteBaseSelectAll
            selectAll={selectAll}
            onSelectAll={onSelectAll}
          />
        )}
      </>
    )
  }
  const optionsHaveGroups = useMemo(
    () => options.some((option: AutocompleteOptionProps) => option.group),
    [options]
  )

  return (
    <StyledBaseAutocompletePopper
      id={id}
      open={open}
      anchorEl={anchorEl}
      placement="bottom-start"
      data-testid="autocomplete-popper"
      sx={{ maxWidth: popperMaxWidth }}
    >
      <Autocomplete
        open
        fullWidth
        multiple={multiple}
        autoComplete={true}
        onChange={onSelectChange}
        groupBy={
          optionsHaveGroups ? (option) => option?.group ?? null : undefined
        }
        value={value}
        loading={loading}
        disableCloseOnSelect
        onClose={onClose}
        disablePortal
        disableListWrap={true}
        renderTags={() => null}
        onInputChange={onInputChange}
        noOptionsText={noOptionsText}
        slots={{
          popper: PopperComponent,
        }}
        slotProps={{
          paper: {
            elevation: 0,
            square: true,
            sx: { pb: 1, pt: optionsHaveGroups ? 0 : 1 },
          },
        }}
        options={options}
        getOptionLabel={(option) => option[optionKey]}
        renderInput={renderInput}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        {...autocompleteProps}
      />
    </StyledBaseAutocompletePopper>
  )
}

export default AutocompleteBasePopper
