import { FunctionComponent, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { sortBy, groupBy, entries, map } from 'lodash'
import Checkbox from '@mui/material/Checkbox'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'

import {
  userUpdateNotification,
  userSubmitUserData,
} from '../../stores/actionCreators'

const groupSorting = ([, notifications]) => notifications[0].groupOrder || 0

const Notifications: FunctionComponent<{}> = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [notifications, setNotifications] = useState<IUserNotification[]>([])

  const { storeNotifications, canBeMentioned } = useSelector(
    (state: IGlobalState) => ({
      storeNotifications: state.user.notifications || [],
      canBeMentioned: state.user.canBeMentioned,
    })
  )

  useEffect(() => {
    setNotifications(storeNotifications)
  }, [storeNotifications])

  const toggleNotification = async (
    event: React.ChangeEvent,
    value: boolean
  ) => {
    const newNotifications = notifications.map(
      (notification: IUserNotification) => {
        if (notification.id === parseInt(event.target.id)) {
          notification.enabled = value
        }
        return notification
      }
    )
    setNotifications(newNotifications)
    await dispatch(
      userUpdateNotification({ id: event.target.id, enabled: value })
    )
  }

  const renderField = (notification: IUserNotification) => {
    return (
      <div
        className="notify-settings-form__setting-wrapper"
        key={notification.id}
      >
        <label className="notify-settings-form__checkbox-group">
          <Checkbox
            id={notification.id.toString()}
            checked={notification.enabled}
            onChange={toggleNotification}
          />
          <Typography variant="body1" children={notification.name} />
        </label>
      </div>
    )
  }

  const renderEmailGroups = () => {
    const emailNotifications = notifications.filter(
      (notification: IUserNotification) =>
        notification.communication === 'email'
    )
    const sortedNotifications = sortBy(emailNotifications, 'typeId')
    const groupedNotifications = groupBy(sortedNotifications, 'group')
    const sortedEntries = sortBy(entries(groupedNotifications), groupSorting)
    return map(sortedEntries, ([group, notifications]: any[]): any =>
      renderSingleGroup(group, notifications)
    )
  }

  const renderInAppNotifications = () => {
    const inAppNotifications = notifications.filter(
      (notification: IUserNotification) =>
        notification.communication === 'in_app'
    )
    return renderSingleGroup(null, inAppNotifications)
  }

  const renderSingleGroup = (
    group: string | null,
    notifications: IUserNotification[]
  ) => (
    <div key={group} className="notify-settings-form__group-block">
      <Typography variant="h6" children={group} />
      <div className="notify-settings-form__group-content">
        {sortBy(notifications, 'order').map(renderField)}
      </div>
    </div>
  )

  const onFollowNewShipmentsChange = (key: string, value: boolean) => {
    dispatch(
      userSubmitUserData({
        [key]: value,
      })
    )
  }

  return (
    <div className="notify-settings-form--block">
      <div className="notify-settings-form--title">
        <Typography
          variant="h4"
          children={t(
            'account.tabs.notifications.notification_settings.title',
            'Notification settings'
          )}
        />
        <Typography
          variant="body1"
          children={t(
            'account.tabs.notifications.notification_settings.description',
            'Please select the activities that you want to be notified about'
          )}
          marginBottom={1}
        />
        <label className="notify-settings-form__checkbox-group">
          <Checkbox
            checked={canBeMentioned}
            onChange={() => {
              onFollowNewShipmentsChange('can_be_mentioned', !canBeMentioned)
            }}
          />
          <Typography
            variant="body1"
            children={t(
              'account.tabs.notifications.notification_settings.can_be_mentioned',
              'Can be mentioned'
            )}
          />
        </label>
      </div>
      <Typography
        variant="h5"
        children={t(
          'account.tabs.notifications.in_app_notifications.title',
          'In-app notifications'
        )}
      />
      <Typography
        variant="body1"
        children={t(
          'account.tabs.notifications.in_app_notifications.description',
          "Notifications will appear in your 'Notifications' top-right drawer"
        )}
        marginBottom={1}
      />
      {renderInAppNotifications()}
      <Typography
        variant="h5"
        children={t(
          'account.tabs.notifications.email_notifications.title',
          'Email notifications'
        )}
      />
      <Typography
        variant="body1"
        children={t(
          'account.tabs.notifications.email_notifications.description',
          'These settings will only apply to email notifications'
        )}
        marginBottom={2}
      />

      {renderEmailGroups()}
    </div>
  )
}

export default Notifications
