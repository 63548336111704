import { FunctionComponent, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { push } from 'connected-react-router'
import { useTranslation } from 'react-i18next'
import { upperCase, groupBy, orderBy, keys } from 'lodash'
import { Box, Typography, Tooltip } from '@mui/material'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import AltRouteIcon from '@mui/icons-material/AltRoute'
import TopNavBar from 'src/components/TopNavigation'
import Map from 'src/components/Map'
import SelectableChip from 'src/components/SelectableChip'
import SailingInformation from 'src/components/SailingInformation'
import ShipmentTimelinePhases from 'src/components/ShipmentTimeline/ShipmentTimelinePhases'
import CarbonEmissionsBlock from 'src/components/CarbonEmissionsBlock'
import { Skeleton } from 'src/stories/Skeleton'
import { shipmentLinkGetData } from 'src/stores/actionCreators'
import { shipmentChipColor } from '../PickupsAndDeliveries/constants'
import { promisifyAction } from '../../utils'

interface IShipmentsLinkProps {
  match: IMatch | null
}

const ShipmentLink: FunctionComponent<IShipmentsLinkProps> = (props) => {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const dispatch = useDispatch()

  const { shipment, markers, timeline, legs } = useSelector(
    (state: IGlobalState) => ({
      shipment: state.shipmentLink.public_shipment as any,
      markers: state.shipmentLink.markers,
      timeline: state.shipmentLink.timeline,
      legs: state.shipmentLink.legs,
    })
  )

  const shipmentLinkGetDataAsync = promisifyAction(
    dispatch,
    shipmentLinkGetData
  )

  const isLCL: boolean = useMemo(() => shipment.load_type === 'lcl', [
    shipment.load_type,
  ])

  useEffect(() => {
    setIsLoading(true)
    const fetchDataAsync = async () => {
      try {
        await shipmentLinkGetDataAsync(props.match!.params.token)
        setIsLoading(false)
      } catch (error) {
        dispatch(push({ pathname: '/dashboard' }))
      }
    }
    fetchDataAsync()
  }, [])

  const shipmentIconClass: string = useMemo((): string => {
    switch (shipment.shipment_type) {
      case 'air':
        return 'airport'
      case 'rail':
        return 'train'
      case 'fcl':
        return 'sea'
      case 'lcl':
        return 'sea'
      default:
        return ''
    }
  }, [shipment.type])

  const loadingBlock = useMemo(() => {
    return (
      <Box sx={{ background: 'white', p: 2, mb: 2, borderRadius: '8px' }}>
        <Skeleton animation="wave" sx={{ width: '70%', height: '40px' }} />
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Skeleton animation="wave" sx={{ width: '20%', height: '60px' }} />
          <Skeleton animation="wave" sx={{ width: '20%', height: '60px' }} />
          <Skeleton animation="wave" sx={{ width: '20%', height: '60px' }} />
          <Skeleton animation="wave" sx={{ width: '20%', height: '60px' }} />
        </Box>
      </Box>
    )
  }, [isLoading])

  const groupedContainers = shipment.containers
    ? groupBy(shipment.containers, 'container_type.name')
    : []

  const containerTypeNames: string[] = orderBy(keys(groupedContainers)) || []

  const renderContainerTypes = (typeNames: string[]): string =>
    typeNames
      .map((name: string) => `${groupedContainers[name].length} x ${name}`)
      .join(', ')

  const sailingInformation = (): React.ReactElement => {
    return <SailingInformation shipmentLegs={legs} />
  }

  return (
    <Box
      sx={{
        height: '100vh',
        marginTop: '56px',
      }}
    >
      <TopNavBar />
      <Box sx={{ padding: '16px 16px 0px 16px', maxWidth: '32vw' }}>
        <Typography
          variant="h5"
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
          children={`${shipment.title} | ${shipment.shared_reference || ''}`}
        />
      </Box>
      <Box
        sx={{ padding: 2, display: 'flex', height: 'calc(100% - 56px - 40px)' }}
      >
        <Box
          className="map-block"
          sx={{ display: 'flex', flexDirection: 'column', flex: 2 }}
        >
          {isLoading && loadingBlock}
          {!isLoading && (
            <Box
              sx={{
                background: 'white',
                p: 2,
                mb: 2,
                borderRadius: '8px',
              }}
            >
              <Box
                sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}
              >
                <Tooltip title={shipment.loading_port || ''} placement="bottom">
                  <Typography variant="h3">{shipment.loading_port}</Typography>
                </Tooltip>
                <i className={`icon ${shipmentIconClass} mr-10 ml-10`} />
                <Tooltip
                  title={shipment.discharge_port || ''}
                  placement="bottom"
                >
                  <Typography variant="h3">
                    {shipment.discharge_port}
                  </Typography>
                </Tooltip>
                <Box ml={2}>
                  <SelectableChip
                    disabled={true}
                    value={{
                      name: shipment.humanized_status,
                      color: shipmentChipColor[shipment.shipment_phase],
                    }}
                    variant="filled"
                  />
                </Box>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Tooltip title={sailingInformation()} placement="bottom-start">
                  <Box className="sailing-info">
                    {legs && legs.length === 1 && (
                      <>
                        <ArrowForwardIcon className="arrow" />
                        <Typography>
                          {t(
                            'shipment_details.direct_shipment',
                            'Direct shipment'
                          )}
                        </Typography>
                      </>
                    )}
                    {legs && legs.length > 1 && (
                      <>
                        <AltRouteIcon className="arrow" />
                        <Typography>{`${legs.length - 1} transshipment port${
                          legs.length - 1 > 1 ? 's' : ''
                        }`}</Typography>
                      </>
                    )}
                  </Box>
                </Tooltip>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  mt: 2,
                  justifyContent: 'space-between',
                  width: '90%',
                  flexWrap: 'wrap',
                }}
              >
                <Box sx={{ minWidth: '150px', mb: 1 }}>
                  <Typography
                    variant="body1Strong"
                    children={t('common.load_type', 'Load type')}
                  />
                  <Typography
                    variant="body1"
                    children={upperCase(shipment.load_type)}
                    data-testid="load-type"
                  />
                </Box>
                {!isLCL && (
                  <Box sx={{ minWidth: '150px', mb: 1 }}>
                    <Typography
                      variant="body1Strong"
                      children={t(
                        'shipment_share.tooltips.shared_content_info.containers',
                        'Containers'
                      )}
                    />
                    <Typography
                      variant="body1"
                      children={renderContainerTypes(containerTypeNames)}
                      data-testid="container-type"
                    />
                  </Box>
                )}
                <Box sx={{ minWidth: '150px', mb: 1 }}>
                  <Typography
                    variant="body1Strong"
                    children={t(
                      'shipment_share.tooltips.shared_content_info.carrier',
                      'Carrier'
                    )}
                  />
                  <Typography
                    variant="body1"
                    children={shipment.carrier_name || '-'}
                    data-testid="carrier-name"
                  />
                </Box>
                <Box sx={{ minWidth: '150px', mb: 1 }}>
                  <Typography
                    variant="body1Strong"
                    children={t(
                      'shipment_share.tooltips.shared_content_info.vessel',
                      'Vessel'
                    )}
                  />
                  <Typography
                    variant="body1"
                    children={shipment.vessel || '-'}
                    data-testid="vessel"
                  />
                </Box>
                <Box sx={{ minWidth: '150px', mb: 1 }}>
                  <Typography variant="body1Strong">
                    CO<sub className="co2-emissions-label">2</sub>
                  </Typography>
                  {shipment.co2_emissions ? (
                    <CarbonEmissionsBlock
                      shipment={shipment}
                      isPublicShipment={true}
                    />
                  ) : (
                    <Typography variant="body1" children="-" />
                  )}
                </Box>
              </Box>
            </Box>
          )}
          <Box className="public-shipments__map" sx={{ flex: 1 }}>
            <Map markers={markers} styles={{ borderRadius: 8 }} />
          </Box>
        </Box>
        <Box className="timeline-phases-block" sx={{ flex: 1, ml: 1 }}>
          <ShipmentTimelinePhases
            timeline={timeline}
            public_shipment={shipment}
            public_shipment_legs={legs}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default ShipmentLink
