import { useRef, useState } from 'react'
import { styled } from '@mui/material/styles'
import { Chip, CircularProgress, Menu, MenuItem, Tooltip } from '@mui/material'
import { useUpdateTask } from 'src/services/Api/overview'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { customFields, tableCellMutationKey } from '../../constants'

export const StyledChip = styled(Chip)(({ theme }) => ({
  '&.MuiChip-root .MuiChip-label': {
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
  },
  '& .MuiChip-deleteIcon': {
    transition: theme.transitions.create(['transform'], {
      duration: theme.transitions.duration.complex,
    }),
  },
}))

const EditableTask = ({
  task,
  Icon,
  color,
  label,
  taskStatuses,
  currentTaskStatusId,
}) => {
  const ref = useRef(null)

  const {
    mutateAsync: updateTaskStatus,
    isLoading: isUpdating,
  } = useUpdateTask({
    retry: 0,
  })

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = () => {
    setAnchorEl(ref?.current)
  }

  const handleClose = ({ taskId, newTaskStatusId }) => {
    if (currentTaskStatusId === newTaskStatusId) {
      setAnchorEl(null)
      return
    }
    updateTaskStatus({
      mutationKey: [tableCellMutationKey],
      params: {
        custom_fields: customFields,
        tasks: [
          {
            task_id: taskId,
            task_status_id: newTaskStatusId,
          },
        ],
      },
    })
    setAnchorEl(null)
  }

  return (
    <div>
      <Tooltip title={label} placement="top">
        <StyledChip
          ref={ref}
          icon={Icon}
          size="small"
          color={color}
          aria-haspopup="true"
          disabled={isUpdating}
          onClick={handleClick}
          onDelete={handleClick}
          label=""
          id="task-status-selection-trigger"
          deleteIcon={
            isUpdating ? (
              <CircularProgress color={color} size={12} />
            ) : (
              <KeyboardArrowDownIcon
                sx={{ transform: open ? 'rotate(180deg)' : 'rotate(0deg)' }}
              />
            )
          }
          aria-expanded={open ? 'true' : undefined}
          aria-controls={open ? 'task-status-selection-menu' : undefined}
        />
      </Tooltip>

      {open && (
        <Menu
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          id="task-status-selection-menu"
          MenuListProps={{
            'aria-labelledby': 'task-status-selection-trigger',
          }}
        >
          {taskStatuses.map((status) => (
            <MenuItem
              key={status.id}
              onClick={() => {
                handleClose({
                  taskId: task.task_id,
                  newTaskStatusId: status.id,
                })
              }}
              selected={currentTaskStatusId === status.id}
            >
              {status.name}
            </MenuItem>
          ))}
        </Menu>
      )}
    </div>
  )
}

export default EditableTask
