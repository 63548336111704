import { useTranslation } from 'react-i18next'
import { Box, Button } from '@mui/material'
import { useState, useEffect, useRef } from 'react'
import {
  draggable,
  dropTargetForElements,
} from '@atlaskit/pragmatic-drag-and-drop/element/adapter'
import { useFieldArray, useFormContext } from 'react-hook-form'

import { defaultAddressValues } from './utils'
import TransportOrderDialogFormAddressesAccordion from './TransportOrderDialogFormAddressesAccordion'

const TransportOrderDialogFormAddresses = ({
  service,
  shipmentOwnerOrganization,
}) => {
  const { t } = useTranslation()
  const { control } = useFormContext()

  const { fields, append, move, remove } = useFieldArray({
    control,
    name: 'addresses',
  })

  const [expanded, setExpanded] = useState<string | false>(false)
  const registeredElements = useRef(new Set())

  const handleRemove = (index: number) => {
    remove(index)
    setExpanded(false)
  }

  const handleDrop = ({ source, element, index }) => {
    element.style.backgroundColor = ''
    const sourceIndex = fields.findIndex(
      (field) =>
        field.id === source.element.id.replace('accordion-summary-', '')
    )
    const targetIndex = index
    if (sourceIndex !== targetIndex) {
      move(sourceIndex, targetIndex)
    }
  }

  const initDragAndDrop = () => {
    const cleanupFunctions: (() => void)[] = []

    fields.forEach((item, index) => {
      const element = document.getElementById(`accordion-${item.id}`)
      const dragHandle = document.getElementById(`accordion-summary-${item.id}`)

      if (element && dragHandle && !registeredElements.current.has(element)) {
        // Register draggable on the accordion summary element
        const draggableCleanup = draggable({
          element: dragHandle,
          onDragStart: () => {
            element.style.opacity = '0.5'
          },
          onDrop: () => {
            element.style.opacity = '1'
          },
        })

        // Register drop target on the accordion element
        const dropTargetCleanup = dropTargetForElements({
          element,
          onDragEnter: () => {
            element.style.backgroundColor = '#f0f0f0'
          },
          onDragLeave: () => {
            element.style.backgroundColor = ''
          },
          onDrop: ({ source }) => {
            handleDrop({ source, element, index })
          },
        })

        // Track registered elements
        registeredElements.current.add(element)

        // Store cleanup functions
        cleanupFunctions.push(draggableCleanup, dropTargetCleanup)
      }
    })

    // Cleanup on unmount or fields update
    return () => {
      cleanupFunctions.forEach((cleanup) => cleanup())
      registeredElements.current.clear()
    }
  }

  useEffect(() => {
    initDragAndDrop()
  }, [fields, move])

  return (
    <Box my={2}>
      <Box>
        {fields.map((item, index) => (
          <TransportOrderDialogFormAddressesAccordion
            key={item.id}
            expanded={expanded}
            setExpanded={setExpanded}
            onRemoveClicked={() => handleRemove(index)}
            {...{ item, index, service, shipmentOwnerOrganization }}
          />
        ))}
      </Box>
      <Box mt={2}>
        <Button
          variant="outlined"
          onClick={() => {
            append(defaultAddressValues)
            setExpanded(`panel${fields.length}`)
          }}
        >
          {t('common.buttons.add_address', 'Add address')}
        </Button>
      </Box>
    </Box>
  )
}

export default TransportOrderDialogFormAddresses
