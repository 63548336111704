import { Link } from 'react-router-dom'
import { Alert } from 'src/stories/Alert'
import { useTranslation } from 'react-i18next'
import { useForm, FormProvider } from 'react-hook-form'
import { Typography, Link as MuiLink, Button, Box } from '@mui/material'
import { Dialog, DialogContent } from 'src/stories/Dialogs'
import { useUpdateInlandTransport } from 'src/services/Api/overview'
import { customFields, tableCellMutationKey } from '../../constants'
import RowActionMenuItemsTransportOrderDialogForm from './TransportOrderDialogForm'
import { getInlandTransportPayloadFromAddressFormData } from './utils'

const RowActionMenuItemsTransportOrderDialog = ({
  open,
  onClose,
  service,
  containerNumber,
  inlandTransport,
  onInlandTransportUpdate,
  onSendEmailButtonClick,
}) => {
  const transportEmailActionItems = inlandTransport?.transporter_email_action_items ?? [
    '',
  ]

  const {
    mutateAsync: updateInlandTransport,
    isLoading: isUpdating,
  } = useUpdateInlandTransport({
    retry: 0,
  })

  const methods = useForm({
    defaultValues: {
      status: '',
      modality: '',
      addresses: [],
      transporter: null,
    },
  })

  const isFormEdited = methods.formState.isDirty

  const onSubmit = (data) => {
    const payload = getInlandTransportPayloadFromAddressFormData(
      data,
      inlandTransport?.id,
      inlandTransport?.inland_transport_addresses ?? []
    )

    updateInlandTransport({
      mutationKey: [tableCellMutationKey],
      params: {
        custom_fields: customFields,
        inland_transports: [
          {
            service,
            container_id: inlandTransport?.container.id ?? null,
            ...payload,
          },
        ],
      },
    }).then(onInlandTransportUpdate)
  }

  const { t } = useTranslation()

  return (
    <>
      <Dialog
        maxWidth="md"
        fullWidth
        open={open}
        title={t(`transport_order_form.create_${service}_transport_order`, {
          interpolation: { escapeValue: false },
          defaultValue: `Create ${service} transport order for {{containerNumber}}`,
          containerNumber,
        })}
        onClose={onClose}
        PaperProps={{
          component: 'form',
          onSubmit: methods.handleSubmit(onSubmit),
        }}
        actions={
          <>
            <Button
              variant="text"
              onClick={onClose}
              size="large"
              disabled={isUpdating}
            >
              {t('common.buttons.cancel', 'Cancel')}
            </Button>
            <Button
              size="large"
              type="submit"
              variant="outlined"
              loading={isUpdating}
              disabled={!isFormEdited}
            >
              {t('common.buttons.save_changes', 'Save changes')}
            </Button>
            <Button
              size="large"
              variant="contained"
              onClick={onSendEmailButtonClick}
              disabled={
                isUpdating ||
                !inlandTransport ||
                transportEmailActionItems.length > 0
              }
            >
              {t(
                `transport_order_form.buttons.create_${service}_order`,
                `Create ${service} order`
              )}
            </Button>
          </>
        }
      >
        <DialogContent>
          {transportEmailActionItems.map((message: string, index: number) => (
            <Box mt={1} mb={2} key={index}>
              <Alert color="warning" message={message} />
            </Box>
          ))}
          <Typography
            mb={2}
            children={t(
              'transport_order_form.helper_texts.mandatory_information',
              'Complete the mandatory information marked with * to send the transport order.'
            )}
          />
          <MuiLink
            component={Link}
            children={t('transport_order_form.buttons.view_shipment', {
              defaultValue: 'View shipment {{shipmentReference}}',
              shipmentReference: inlandTransport?.shipment?.shared_reference,
            })}
            to={`/shipments/${inlandTransport?.shipment?.id}`}
            target="_blank"
          />
          <Typography
            variant="h6"
            sx={{ mt: 3, mb: 2 }}
            children={t(
              'transport_order_form.headers.inland_transport_information',
              'Inland transport information'
            )}
          />
          <FormProvider {...methods}>
            <RowActionMenuItemsTransportOrderDialogForm
              service={service}
              inlandTransport={inlandTransport}
            />
          </FormProvider>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default RowActionMenuItemsTransportOrderDialog
