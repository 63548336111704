import { capitalize } from 'lodash'
import Grid from '@mui/material/Grid2'
import { useTranslation } from 'react-i18next'
import { Box, InputLabel, TextField, Chip, Button } from '@mui/material'
import { SyntheticEvent } from 'react'
import Typography from '@mui/material/Typography'

import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import { Controller, useFormContext } from 'react-hook-form'
import { Accordion, AccordionDetails, AccordionSummary } from './styles'
import TransportOrderDialogFormAddressesAddressType from './TransportOrderDialogFormAddressesAddressType'
import TransportOrderDialogFormAddressesAddressSelect from './TransportOrderDialogFormAddressesAddressSelect'
import TransportOrderDialogFormAddressesDate from './TransportOrderDialogFormAddressesDate'

const TransportOrderDialogFormAddressesAccordion = ({
  service,
  item,
  index,
  expanded,
  setExpanded,
  onRemoveClicked,
  shipmentOwnerOrganization,
}) => {
  const { t } = useTranslation()
  const { control } = useFormContext()

  const handleChange = (panel: string) => (
    _event: SyntheticEvent,
    newExpanded: boolean
  ) => {
    setExpanded(newExpanded ? panel : false)
  }

  return (
    <Accordion
      id={`accordion-${item.id}`}
      expanded={expanded === `panel${index}`}
      onChange={handleChange(`panel${index}`)}
    >
      <Controller
        render={({ field: { value } }) => (
          <AccordionSummary
            aria-controls={`panel${index}bh-content`}
            id={`accordion-summary-${item.id}`}
          >
            <Box display="flex" alignItems="center">
              {/* Drag Handle */}
              <Box
                id={`drag-handle-${item.id}`}
                sx={{ cursor: 'grab', mr: 1 }}
                onClick={(e) => e.stopPropagation()}
              >
                <DragIndicatorIcon />
              </Box>
              <Box>
                <Typography component="div" variant="body1Strong">
                  {capitalize(value.type)}
                  {value.usedForPriceCalculation && (
                    <Chip
                      size="small"
                      color="secondary"
                      sx={{ ml: 1 }}
                      label={t(
                        'transports.drawer.used_for calculation',
                        'Used for price calculation'
                      )}
                    />
                  )}
                </Typography>
                <Typography component="div" mt={0.5}>
                  {value.text?.length > 0 ? value.text : '-'}
                </Typography>
              </Box>
            </Box>
          </AccordionSummary>
        )}
        name={`addresses.${index}`}
        control={control}
      />
      <AccordionDetails>
        <Grid container spacing={3}>
          <Grid size="grow">
            <InputLabel required>
              {t(
                'common.forms.fields.address.address_type.label',
                'Address type'
              )}
            </InputLabel>
            <Controller
              render={({ field: { value, onChange } }) => (
                <TransportOrderDialogFormAddressesAddressType
                  value={value}
                  onChange={onChange}
                />
              )}
              name={`addresses.${index}.type`}
              control={control}
            />
          </Grid>
          <Grid size="grow">
            <InputLabel>
              {t(
                `common.forms.fields.address.${service}_reference.label`,
                `${capitalize(service)} reference`
              )}
            </InputLabel>
            <Controller
              render={({ field: { value, onChange } }) => (
                <TextField
                  value={value}
                  fullWidth
                  onChange={(event) => {
                    onChange(event.target.value)
                  }}
                />
              )}
              name={`addresses.${index}.reference`}
              control={control}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} mt={2}>
          <Grid size="grow">
            <InputLabel required>
              {t('common.forms.fields.address.address_line.label', 'Address')}
            </InputLabel>
            <Controller
              render={({ field: { onChange, value } }) => (
                <TransportOrderDialogFormAddressesAddressSelect
                  onChange={onChange}
                  value={value}
                  shipmentOwnerOrganization={shipmentOwnerOrganization}
                />
              )}
              name={`addresses.${index}.address`}
              control={control}
            />
          </Grid>
          <Grid size="grow">
            <InputLabel>
              {t(
                `common.forms.fields.address.${service}_time.label`,
                `${capitalize(service)} time`
              )}
            </InputLabel>
            <Controller
              render={({ field: { onChange, value } }) => (
                <TransportOrderDialogFormAddressesDate
                  onChange={onChange}
                  value={value}
                />
              )}
              name={`addresses.${index}.datetime`}
              control={control}
            />
          </Grid>
        </Grid>
        <Controller
          render={({ field: { value } }) => {
            if (value) {
              return <span />
            }
            return (
              <Box mt={2} display="flex" justifyContent="flex-end">
                <Button variant="outlined" onClick={onRemoveClicked}>
                  {t('common.buttons.delete', 'Delete')}
                </Button>
              </Box>
            )
          }}
          name={`addresses.${index}.usedForPriceCalculation`}
          control={control}
        />
      </AccordionDetails>
    </Accordion>
  )
}

export default TransportOrderDialogFormAddressesAccordion
