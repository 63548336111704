import { useEffect, useState, Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Skeleton,
  TextField,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  CircularProgress,
  Typography,
  Divider,
} from '@mui/material'

import { Dialog, DialogContent } from 'src/stories/Dialogs'

import { getDisplayDateInLocalTimezone } from 'src/components/Overview/utils'
import { useGetShipmentCommentsAsync } from 'src/services/Api/shipments'
import { useUpdateShipment } from 'src/services/Api/overview'
import { customFields, tableCellMutationKey } from '../../constants'

const CommentsModalListItem = ({
  comment,
  index,
  size,
}: {
  comment: IComment | null
  size: number
  index: number
}) => {
  const secondaryText: string = comment
    ? [
        getDisplayDateInLocalTimezone(comment.created_at, 'dd MMM yyyy HH:mm'),
        comment.author.full_name,
        comment.author.organization.name,
        comment.author.phone,
      ]
        .filter((item) => item !== null && item !== undefined && item !== '')
        .join(' | ')
    : ''

  return (
    <Fragment>
      {size > 0 && index === 0 && <Divider />}
      <ListItem alignItems="flex-start" sx={{ pl: 0 }}>
        <ListItemAvatar>
          {comment && (
            <Avatar
              src={comment.author.avatar_thumb}
              sx={{ backgroundColor: 'grey.200' }}
            />
          )}
          {!comment && (
            <Skeleton
              animation="wave"
              variant="circular"
              width={40}
              height={40}
              data-testid="comment-skeleton-avatar"
            />
          )}
        </ListItemAvatar>
        <ListItemText
          primary={
            comment ? comment.body : <Skeleton animation="wave" width="80%" />
          }
          secondary={
            comment ? (
              <Typography
                component="span"
                variant="body2"
                sx={{ color: 'grey.600', display: 'inline', mt: 1 }}
              >
                {secondaryText}
              </Typography>
            ) : (
              <Skeleton animation="wave" />
            )
          }
        />
      </ListItem>
      <Divider />
    </Fragment>
  )
}

const CommentsModal = ({
  open,
  onClose,
  shipmentId,
  shipmentTitle,
}: {
  open: boolean
  onClose: () => void
  shipmentId: number
  shipmentTitle: string
}) => {
  const { t } = useTranslation()
  const [newComment, setNewComment] = useState('')
  const [comments, setComments] = useState<IComment[]>([])

  const {
    fetchAsync: fetchComments,
    isLoading: commentsLoading,
    isFetching: commentsFetching,
  } = useGetShipmentCommentsAsync()
  const {
    mutateAsync: updateShipment,
    isLoading: isUpdating,
  } = useUpdateShipment()

  const handleSendComment = async () => {
    if (!newComment.trim()) return
    await updateShipment({
      mutationKey: [tableCellMutationKey],
      params: {
        custom_fields: customFields,
        shipments: [
          {
            id: shipmentId,
            comment: newComment,
          },
        ],
      },
    })
    getComments()
  }

  const getComments = async () => {
    const data = await fetchComments({ shipmentId })

    if (data) {
      setComments(data)
    }

    setNewComment('')
  }

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewComment(e.target.value)
  }

  const onInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSendComment()
    }
  }

  useEffect(() => {
    getComments()
  }, [])

  const isLoading = commentsLoading || commentsFetching

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
      title={t('shipment_comments_dialog.title', {
        defaultValue: 'Comments on {{shipmentNumber}}',
        shipmentNumber: shipmentTitle,
      })}
    >
      <DialogContent>
        <TextField
          fullWidth
          placeholder={t(
            'common.forms.fields.comment.placeholder',
            'Add a new comment'
          )}
          value={newComment}
          onChange={onInputChange}
          onKeyDown={onInputKeyDown}
          variant="outlined"
          rows={2}
          slotProps={{
            input: {
              sx: { py: 1, px: 1.5 },
              endAdornment: (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSendComment}
                  disabled={isUpdating || !newComment.trim()}
                >
                  {isUpdating ? (
                    <CircularProgress size={24} />
                  ) : (
                    t('common.buttons.send', 'Send')
                  )}
                </Button>
              ),
            },
          }}
        />
        <List sx={{ mt: 2 }}>
          {isLoading && (
            <CommentsModalListItem comment={null} index={0} size={1} />
          )}
          {comments?.map((comment: IComment, index: number) => (
            <CommentsModalListItem
              key={comment.id}
              comment={comment}
              index={index}
              size={comments.length}
            />
          ))}
        </List>
      </DialogContent>
    </Dialog>
  )
}

export default CommentsModal
