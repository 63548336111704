import { getDisplayDate } from '../utils'
import OverviewTableText from './OverviewTableText'
import OverviewTableShipmentColoredCell from './OverviewTableShipmentColoredCell'

const OverviewTableShipmentClosingDateTime = ({ date, status }) => {
  if (!date) return <OverviewTableText text="-" />

  const color = status === 'confirmed' ? 'success' : 'default'

  return (
    <OverviewTableShipmentColoredCell
      color={color}
      text={getDisplayDate({ date })}
    />
  )
}

export default OverviewTableShipmentClosingDateTime
