import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

const ReferencesBlock = ({ references }) => {
  const { t } = useTranslation()
  return (
    <>
      <Typography variant="h6" gutterBottom>
        {t('shipping_instructions.headers.references', 'References')}
      </Typography>
      <Box
        sx={{
          border: '1px solid var(--grey-200)',
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        {references.map((reference) => (
          <Box key={reference.reference_type}>
            {t(
              `shipping_instructions.reference_types.${reference.reference_type}`,
              reference.reference_type
            )}{' '}
            {reference.reference}
          </Box>
        ))}
      </Box>
    </>
  )
}

export default ReferencesBlock
