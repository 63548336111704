import { useState, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Link, Menu, Paper } from '@mui/material'
import MenuItem from '@mui/material/MenuItem'
import { toNumber, find } from 'lodash'
import { LoadingButton } from '@mui/lab'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { makeStyles } from '@mui/styles'
import { Table } from 'src/stories'
import { convertDatePickerToUniversalFormat } from 'src/utils/helpers'
import { ReportProblemOutlined } from '@mui/icons-material'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import Tooltip from '@mui/material/Tooltip'
import EditShipmentWindow from 'src/components/EditShipmentWindow'
import {
  shipmentOverviewGetData,
  toggleHsModalOpen,
} from 'src/stores/actionCreators'
import { CargoWindow } from 'src/components/PickupAndDelivery/CargoWindow'
import HsCodeAddModal from 'src/components/PickupAndDelivery/HsCodeAddModal'
import ContainerEditWindow from 'src/components/PickupAndDelivery/ContainerEditWindow'
import { permissionTo } from 'src/utils'
import { INITIAL_CARGO_VALUE } from './constants'

const TablePaper = (props) => <Paper {...props} square elevation={0} />

interface ICarrierReviewProps {
  shipment: IOverviewShipment
  getCarrierBookingData: (shipmentId: number) => void
  setAferSaveGoods: (value: boolean) => void
  afterSaveGoods: boolean
  containersDetailedData: IShipmentContainer[]
}

const useStyles = makeStyles(() => ({
  alertWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
  },
  menu: {
    '& .MuiPaper-root': {
      minWidth: 130,
    },
  },
}))

const CarrierReview: React.FC<ICarrierReviewProps> = (props) => {
  const classes = useStyles()

  const { booking, bookings } = useSelector((state: IGlobalState) => ({
    booking: state.bookings.carrierBooking,
    bookings: state.bookings.bookings,
  }))
  const dispatch = useDispatch()
  const [editShipmentOpen, setEditShipmentOpen] = useState<boolean>(false)

  const [openCargoWindow, setOpenCargoWindow] = useState<boolean>(false)
  const [currentContainerId, setCurrentContainerId] = useState<number | null>(
    null
  )
  const [openContainerWindow, setOpenContainerWindow] = useState<boolean>(false)
  const [currentCargo, setCurrentCargo] = useState<ICargo[]>([
    INITIAL_CARGO_VALUE,
  ])

  const [editCarrierGood, setEditCarrierGood] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [hcodes, setHscodes] = useState<IContainerHsCode[]>([])
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const onCloseCargoWindow = () => {
    props.setAferSaveGoods(true)
    setCurrentCargo([INITIAL_CARGO_VALUE])
    setOpenCargoWindow(false)
    setCurrentContainerId(null)
    setEditCarrierGood(false)
    setLoading(false)
  }

  useEffect(() => {
    if (props.containersDetailedData.length === 0) {
      setLoading(true)
    }
    setLoading(false)
  }, [props.containersDetailedData])

  const onOpenCargoWindow = (containerId: number) => {
    setOpenCargoWindow(true)
    setCurrentContainerId(containerId)
  }

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
    const id = toNumber(event.currentTarget.id)
    setCurrentContainerId(id)
  }

  const getContainerData = () => {
    const currentContainer: IShipmentContainer | {} =
      find(props.containersDetailedData, { id: currentContainerId }) || {}
    return [currentContainer as IShipmentContainer]
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const renderLoadingButton = (args) => (
    <>
      <LoadingButton
        sx={{
          left: '70%',
        }}
        color="primary"
        id={`${args.row.id}`}
        onClick={handleClick}
        loading={args.row.id === currentContainerId && loading}
        loadingPosition="start"
        startIcon={<KeyboardArrowDownIcon />}
      >
        Edit
      </LoadingButton>
      <Menu
        id="carrier-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem id={args.row.id} onClick={onUpdateContainer}>
          <Typography variant="body1" children="Container" />
        </MenuItem>
        {!(args.row.goods_description === '') && (
          <MenuItem id={args.row.id} onClick={(e) => onUpdateCargo(e)}>
            <Typography variant="body1" children="Goods" />
          </MenuItem>
        )}
      </Menu>
    </>
  )

  const onOpenHScodeWindow = useCallback((containerId: number | null, type) => {
    for (const item of props.containersDetailedData) {
      if (item.id === containerId) {
        setHscodes(item.hs_codes)
      }
    }
    dispatch(
      toggleHsModalOpen({
        open: true,
        containerId: containerId,
        containerType: type.code,
      })
    )
  }, [])

  const closeContainerWindow = useCallback(() => {
    setOpenContainerWindow(false)
  }, [])

  const onCloseContainerWindow = useCallback((): void => {
    props.setAferSaveGoods(true)
  }, [])

  const onClose = useCallback(() => {
    dispatch(
      toggleHsModalOpen({
        open: false,
        containerId: null,
        containerType: null,
      })
    )
    props.setAferSaveGoods(true)
  }, [])

  const renderAlert = (name: string): JSX.Element => {
    return (
      <Box className={classes.alertWrapper}>
        <CheckCircleRoundedIcon
          sx={{
            fontSize: '16px',
            paddingRight: '7px',
          }}
        />
        <Box ml={1}>{name}</Box>
      </Box>
    )
  }

  const onUpdateCargo = useCallback(
    (event: React.MouseEvent<HTMLLIElement>) => {
      setEditCarrierGood(true)
      const id = toNumber(event.currentTarget.id)
      setCurrentContainerId(id)

      for (const item of props.containersDetailedData) {
        if (item.id === id) {
          if (item.cargo.length === 0) {
            setLoading(true)
          } else {
            setCurrentCargo(item.cargo)
          }
        }
      }
      setOpenCargoWindow(true)
    },
    [props.containersDetailedData]
  )

  const onUpdateContainer = useCallback(() => {
    setOpenContainerWindow(true)
  }, [])

  const columns = {
    type: {
      name: 'Type',
      component: (args) => {
        const containerId = args.row.id

        const notifications =
          booking?.action_items?.length &&
          booking?.action_items.filter((i) => i.model_name === 'Container')
        if (notifications && notifications.length > 0) {
          const notification = notifications.find((n) => n.id === containerId)
          return notification ? (
            <Box className={classes.alertWrapper}>
              <Tooltip title={notification.description}>
                <ReportProblemOutlined color="warning" />
              </Tooltip>
              <Box ml={1}>{args.row.container_type.name}</Box>
            </Box>
          ) : (
            renderAlert(args.row.container_type.name)
          )
        } else return renderAlert(args.row.container_type.name)
      },
    },
    hs_code: {
      name: 'HS Code',
      component: (props) =>
        props.row.goods_code ? (
          props.row.goods_code
        ) : (
          <>
            <Link
              id={props.row.goods_code}
              component="button"
              variant="body1"
              onClick={() =>
                onOpenHScodeWindow(props.row.id, props.row.container_type)
              }
            >
              Add
            </Link>
          </>
        ),
    },
    volume: {
      name: 'Volume',
      component: (props) =>
        props.row.total_volume_cbm ? (
          <>
            {props.row.total_volume_cbm + ' m'}
            <sup>3</sup>
          </>
        ) : (
          ''
        ),
    },
    weight: {
      name: 'Weight',
      component: (props) =>
        props.row.total_weight_kg ? `${props.row.total_weight_kg} kg` : '',
    },
    cargo: {
      name: 'Сargo',
      component: (props): React.ReactNode =>
        props.row.goods_description ? (
          props.row.goods_description
        ) : (
          <>
            <Link
              component="button"
              variant="body1"
              onClick={() => onOpenCargoWindow(props.row.id)}
            >
              Add
            </Link>
          </>
        ),
    },
    Edit: {
      name: '',
      component: (props): React.ReactNode => renderLoadingButton(props),
    },
  }

  const onSaveAndFetch = (): void => {
    dispatch(shipmentOverviewGetData(`${props.shipment.id}`))
  }

  const onCloseEditShipment = (): void => {
    dispatch(shipmentOverviewGetData(`${props.shipment.id}`))
    props.getCarrierBookingData(props.shipment.id)
    setEditShipmentOpen(false)
  }

  const shipmentLegsColumns = {
    pol: {
      name: 'POL',
      component: (args) => {
        const notifications =
          booking?.action_items?.length &&
          booking?.action_items.filter((i) => i.model_name === 'Shipment')
        if (notifications && notifications.length > 0) {
          const notification = notifications.map((x) => x.description)
          return (
            <Box className={classes.alertWrapper}>
              <Tooltip title={notification.join()}>
                <ReportProblemOutlined color="warning" />
              </Tooltip>
              <Box ml={1}>{args.row.loading_port.name}</Box>
            </Box>
          )
        } else return renderAlert(args.row.loading_port.name)
      },
    },
    etd: {
      name: 'ETD',
      component: (props) =>
        convertDatePickerToUniversalFormat(props.row.estimated_departure),
    },
    pod: {
      name: 'POD',
      component: (props) => <b>{props.row.discharge_port.name}</b>,
    },
    eta: {
      name: 'ETA',
      component: (props) =>
        convertDatePickerToUniversalFormat(props.row.estimated_arrival),
    },
    carrier: {
      name: 'Carrier',
      component: () => props.shipment.carrier_name,
    },
    edit: {
      name: '',
      component: () => (
        <Button
          variant="text"
          sx={{
            left: '70%',
          }}
          onClick={() => setEditShipmentOpen(true)}
          disabled={!permissionTo('shipments.manage')}
        >
          Edit shipment
        </Button>
      ),
    },
  }

  return (
    <div>
      <Box mb={2} mt={1}>
        <Typography variant="body2">
          Please review you shipment details that will be sent to the carrier
          with your booking.
        </Typography>
      </Box>
      <Box mt={4} mb={2}>
        <Typography variant="h3" gutterBottom>
          Shipment legs
        </Typography>
      </Box>
      <Table
        component={TablePaper}
        loading={!props.shipment.shipment_legs}
        rows={props.shipment.shipment_legs || []}
        columns={shipmentLegsColumns}
      />
      <Box mt={4} mb={2}>
        <Typography variant="h3" gutterBottom>
          Containers
        </Typography>
      </Box>
      <Table
        component={TablePaper}
        loading={!props.containersDetailedData.length}
        rows={props.shipment.containers || []}
        columns={columns}
      />
      <EditShipmentWindow
        shipment={props.shipment}
        open={editShipmentOpen}
        onClose={onCloseEditShipment}
        fetchData={onSaveAndFetch}
      />
      <Box mb={2} />
      <CargoWindow
        open={openCargoWindow}
        close={onCloseCargoWindow}
        cargoItems={currentCargo}
        bookings={bookings || []}
        containerId={currentContainerId}
        editCarrierGood={editCarrierGood}
      />
      <HsCodeAddModal currentHsCodes={hcodes} onClose={onClose} />
      {openContainerWindow && (
        <ContainerEditWindow
          shipmentId={props.shipment.id}
          container={getContainerData()[0]}
          open={openContainerWindow}
          close={closeContainerWindow}
          fetchData={onCloseContainerWindow}
          sharedReference={props.shipment.shared_reference}
        />
      )}
    </div>
  )
}

export default CarrierReview
