import Box from '@mui/material/Box'
import { PillProps } from './Pill.props'

const Pill: React.FC<PillProps> = ({
  children,
  width = 48,
  height = 48,
  color = 'primary',
}) => {
  return (
    <Box
      sx={{
        height: height,
        display: 'flex',
        width: width,
        alignItems: 'center',
        color: `${color}.dark`,
        justifyContent: 'center',
        borderRadius: height / 2,
        backgroundColor: `${color}.light`,
      }}
    >
      {children}
    </Box>
  )
}

export default Pill
