import { FunctionComponent, useState } from 'react'
import {
  Box,
  Button,
  FormControlLabel,
  Checkbox,
  Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { getSellerHasAllDocuments } from 'src/components/Common/ShipmentBookingTermsAndConditions/utils'
import ShipmentBookingTermsAndConditions from 'src/components/Common/ShipmentBookingTermsAndConditions'

interface IProps {
  onSave: () => void
  preferredForwarder: any
  seller: any
  loading?: boolean
}

const ShipmentTemplateAcceptTerm: FunctionComponent<IProps> = ({
  onSave,
  preferredForwarder,
  seller,
  loading = false,
}) => {
  const sellerHasAllDocuments = getSellerHasAllDocuments(seller)

  const [termsChecked, setTermsChecked] = useState<boolean>(
    !sellerHasAllDocuments
  )

  const { t } = useTranslation()

  return (
    <Box data-testid="rebook-template" sx={{ position: 'relative' }}>
      {sellerHasAllDocuments && (
        <Box data-testid="rebook-text">
          <ShipmentBookingTermsAndConditions
            forwarderName={seller?.name ?? preferredForwarder?.name}
            seller={seller}
          />
        </Box>
      )}
      <Box sx={{ position: 'absolute', right: '0', bottom: -61 }}>
        {sellerHasAllDocuments && (
          <FormControlLabel
            className="outline"
            control={
              <Checkbox
                checked={termsChecked}
                className={termsChecked ? 'checked' : ''}
                onChange={setTermsChecked.bind(null, (prev) => !prev)}
                name="accept-terms"
                data-testid="accept-rebook"
                color="primary"
              />
            }
            label={
              <Typography variant="body1Strong" className="pr-20">
                {t('templates.booking_request.accept_terms', 'Accept terms')}
              </Typography>
            }
          />
        )}
        <Button
          size="large"
          color="primary"
          variant="contained"
          className="ml-16"
          onClick={onSave}
          disabled={loading || !termsChecked}
          data-testid="rebook-action"
        >
          {loading
            ? t('templates.booking_request.button_next_loading', 'Loading...')
            : t('templates.booking_request.button_next', 'Next')}
        </Button>
      </Box>
    </Box>
  )
}

export default ShipmentTemplateAcceptTerm
