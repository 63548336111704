import { FunctionComponent, useMemo, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Typography, Link } from '@mui/material'
import { push } from 'connected-react-router'
import { Dispatch } from 'redux'
import { permissionTo } from '../../../utils'

interface IProps {
  phase: IShipmentPhase
  shipment: IOverviewShipment
}

const TimelineDelivery: FunctionComponent<IProps> = ({ phase, shipment }) => {
  const { t } = useTranslation()
  const dispatch: Dispatch = useDispatch()
  const toContainersTab = useCallback(() => {
    dispatch(push(`/shipments/${shipment.id}/containers`))
  }, [shipment])

  const deliveryAddress: React.ReactNode = useMemo(() => {
    if (!shipment.delivery_requested || !shipment.delivery_address_name) {
      return (
        <Typography
          variant="body1"
          children={t(
            'shipment.timeline.service_not_requested',
            'Not requested'
          )}
        />
      )
    }

    if (~shipment.delivery_address_name.indexOf('not set')) {
      return (
        <Link variant="body1" component="button" onClick={toContainersTab}>
          {t('common.buttons.add_address', 'Add address')}
        </Link>
      )
    }

    return (
      <Link variant="body1" component="button" onClick={toContainersTab}>
        {shipment.delivery_address_name}
      </Link>
    )
  }, [shipment])

  return (
    <>
      <div className="shipment-timeline--phases-item-title">
        <Typography
          variant="h6"
          children={t('shipment.timeline.headings.delivery', 'Delivery')}
        />
      </div>
      {permissionTo('shipments.containers_|_cargo.delivery.view') ? (
        <div className="shipment-timeline--phases-item-link">
          {deliveryAddress}
        </div>
      ) : (
        ''
      )}
    </>
  )
}

export default TimelineDelivery
