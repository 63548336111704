import { SyntheticEvent } from 'react'
import { Autocomplete, CircularProgress } from '@mui/material'
import { useTranslation } from 'react-i18next'
import BaseAutocompleteWrapper from 'src/components/Common/AutocompleteWrappers'
import { useUpdateInlandTransport } from 'src/services/Api/overview'
import {
  RenderedAutocompleteProps,
  FilterOptionValue,
} from 'src/components/Common/AutocompleteWrappers/types'
import OverviewTableText from '../OverviewTableText'
import { customFields, tableCellMutationKey } from '../../constants'
import { SelectInput } from './styled'

interface SelectProps {
  isEditable: boolean
  value: string | null
  label: string
  containerId: number
  additionalParams?: Record<string, any>
  formatter: (data: any) => FilterOptionValue[]
  fetchFunction: () => {
    fetchAsync: (params: any) => Promise<unknown>
    isFetched: boolean
    isFetching: boolean
  }
  fetchParams: Record<string, any>
  field?: string
}

const AsyncSelect = ({
  isEditable,
  value,
  label,
  containerId,
  additionalParams = {},
  formatter,
  fetchFunction,
  fetchParams,
  field = 'transporter_organization_id',
}: SelectProps) => {
  const { t } = useTranslation()

  const defaultValue = value ? { value, label } : null

  const defaultOptions = defaultValue ? [defaultValue] : []

  const {
    mutateAsync: updateInlandTransport,
    isLoading: isUpdating,
  } = useUpdateInlandTransport({
    retry: 0,
  })

  const updateCell = (
    _event: SyntheticEvent,
    selected: FilterOptionValue | null
  ) => {
    if (selected?.value === value) {
      return
    }
    updateInlandTransport({
      mutationKey: [tableCellMutationKey],
      params: {
        custom_fields: customFields,
        inland_transports: [
          {
            container_id: containerId,
            [field]: selected?.value || null,
            ...additionalParams,
          },
        ],
      },
    })
  }

  if (isEditable) {
    return (
      <BaseAutocompleteWrapper
        formatter={formatter}
        fetchFunction={fetchFunction}
        defaultValue={defaultOptions}
        params={fetchParams}
        WrappedAutocompleteComponent={(props: RenderedAutocompleteProps) => {
          return (
            <Autocomplete
              fullWidth
              disabled={isUpdating}
              options={props.options}
              data-testid="select-editable-cell"
              className="filled-cell editable"
              componentsProps={{
                paper: {
                  elevation: 8,
                },
              }}
              getOptionLabel={(option) => option.label}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              sx={{ width: 300 }}
              filterOptions={(x) => x}
              renderInput={(params) => (
                <SelectInput
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {props.loading || isUpdating ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                  inputProps={{
                    ...params.inputProps,
                    'data-testid': 'select-input',
                  }}
                  placeholder={isUpdating ? '' : t('common.buttons.add', 'Add')}
                  sx={{ border: 'none' }}
                />
              )}
              onInputChange={(
                _event: SyntheticEvent<Element, Event>,
                search: string
              ) => {
                props.onInputChange(search)
              }}
              value={defaultValue}
              onChange={updateCell}
            />
          )
        }}
      />
    )
  }

  return <OverviewTableText text={label} data-testid="select-cell" />
}

export default AsyncSelect
