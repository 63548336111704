import { FunctionComponent, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Checkbox from '@mui/material/Checkbox'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import { promisifyAction } from '../../utils'

import {
  userUpdateMilestone,
  getYourOpenTasks,
} from '../../stores/actionCreators'

const Milestones: FunctionComponent = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const userUpdateMilestoneAsync = promisifyAction(
    dispatch,
    userUpdateMilestone
  )

  const [milestones, setMilestones] = useState<IUserMilestone[]>([])

  const { storeMilestones } = useSelector((state: IGlobalState) => ({
    storeMilestones: state.user.milestones || [],
  }))

  useEffect(() => {
    setMilestones(storeMilestones)
  }, [storeMilestones])

  const toggleMilestone = async (event: React.ChangeEvent, value: boolean) => {
    const newMilestones = milestones.map((milestone: IUserMilestone) => {
      if (milestone.id === parseInt(event.target.id)) {
        milestone.enabled = value
      }
      return milestone
    })
    setMilestones(newMilestones)
    await userUpdateMilestoneAsync({ id: event.target.id, enabled: value })
    dispatch(getYourOpenTasks())
  }

  const renderField = (milestone: IUserMilestone) => {
    return (
      <div className="notify-settings-form__setting-wrapper" key={milestone.id}>
        <label className="notify-settings-form__checkbox-group">
          <Checkbox
            id={milestone.id.toString()}
            checked={milestone.enabled}
            onChange={toggleMilestone}
          />
          <Typography variant="body1" children={milestone.name} />
        </label>
      </div>
    )
  }

  return (
    <div className="notify-settings-form--block">
      <div className="notify-settings-form--title">
        <Typography
          variant="h4"
          children={t('account.tabs.notifications.tasks.title', 'Tasks')}
        />
        <Typography
          variant="body1"
          children={t(
            'account.tabs.notifications.tasks.description',
            'Please select the tasks that you want to see in your task manager and be notified about'
          )}
        />
      </div>

      {milestones.map(renderField)}
    </div>
  )
}

export default Milestones
