import {
  FunctionComponent,
  useCallback,
  useState,
  useMemo,
  useEffect,
} from 'react'
import { AnyAction, Dispatch } from 'redux'
import { Box, Button, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import {
  getCarriers,
  shipmentOverviewGetData,
  updateShipmentData,
} from 'src/stores/actionCreators'
import { promisifyAction } from 'src/utils'
import { AutoCompleteSelect } from 'src/stories/MUI/Select/AutoCompleteSelect'

const CarrierBlock: FunctionComponent = () => {
  const dispatch: Dispatch<AnyAction> = useDispatch()

  const { shipmentOverview } = useSelector((state: IGlobalState) => ({
    shipmentOverview: state.shipmentOverview,
  }))

  const currentCarrier: ICarrier = {
    id: shipmentOverview.carrier_id,
    name: shipmentOverview.carrier_name,
    scac: shipmentOverview.carrier_scac,
  }
  const [carrier, setCarrier] = useState<ICarrier | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [isModalOpen, setModalOpen] = useState<boolean>(false)

  const updateShipmentDataAsync = promisifyAction(dispatch, updateShipmentData)
  const shipmentOverviewGetDataAsync = promisifyAction(
    dispatch,
    shipmentOverviewGetData
  )
  const getCarriersAsync = promisifyAction(dispatch, getCarriers)

  const isCarrierUpdated = useMemo(() => {
    return `${currentCarrier?.name} - ${currentCarrier?.scac}` !== carrier?.name
  }, [currentCarrier?.name, carrier?.name])

  useEffect(() => {
    if (currentCarrier?.name) {
      setCarrier({
        id: currentCarrier.id,
        name: `${currentCarrier.name} - ${currentCarrier.scac}`,
        scac: currentCarrier.scac,
      })
    }
  }, [currentCarrier?.name])

  const onChange = (value) => {
    setCarrier(value)
  }

  const saveChanges = async (): Promise<void> => {
    setLoading(true)

    await updateShipmentDataAsync(shipmentOverview.id, {
      carrier_id: carrier?.id,
    })
    shipmentOverviewGetDataAsync(shipmentOverview.id)

    setLoading(false)

    if (isModalOpen) {
      setModalOpen(false)
    }
  }

  const fetchCarriers = useCallback((searchInput: string) => {
    const fetchCarriersAsync = async (input: string): Promise<any[]> => {
      if (!!input) {
        const carriersList = await getCarriersAsync({
          search: input.toLowerCase(),
          carrier_type:
            shipmentOverview.modality === 'air' ? 'airline' : 'carrier',
        })
        return carriersList.map((carrier: ICarrier) => ({
          ...carrier,
          name: `${carrier.name} - ${carrier.scac}`,
        }))
      } else {
        return []
      }
    }
    return fetchCarriersAsync(searchInput)
  }, [])

  const isButtonDisabled: boolean = useMemo(() => {
    return !carrier || loading || !isCarrierUpdated
  }, [carrier])

  return (
    <Box mt={3} data-testid="shipment-overview-drawer--carrier">
      <Box mb={1}>
        <Typography variant="h3">Carrier</Typography>
      </Box>
      <Box sx={{ display: 'flex', width: '450px' }}>
        <Box sx={{ minWidth: '400px' }}>
          <AutoCompleteSelect
            id="carrier"
            getData={fetchCarriers}
            onChange={onChange}
            value={carrier}
            placeholder="Choose carrier"
            noOptionsText="Type to search"
          />
        </Box>
        <Button
          sx={{ marginLeft: 2 }}
          disabled={isButtonDisabled}
          variant="outlined"
          onClick={saveChanges}
        >
          Save
        </Button>
      </Box>
    </Box>
  )
}

export default CarrierBlock
