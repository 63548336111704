import { useState, Fragment, useRef, useEffect, SyntheticEvent } from 'react'
import isEqual from 'lodash/isEqual'
import { useFormContext, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { MenuItemWithCheckbox } from 'src/stories/Lab/Menu/components/MenuItemWithCheckbox'
import { FilterOptionValue } from 'src/components/Common/AutocompleteWrappers/types'
import { AutocompleteBaseProps } from '../types'
import RecentFiltersManager from '../RecentSearches'
import DropdownTrigger from './DropdownTrigger'
import AutocompleteBasePopper from './AutocompleteBasePopper'

const AutocompleteBase = ({
  asynchronous = false,
  token,
  options,
  loading,
  onDelete,
  onInputChange,
  noOptionsText,
  optionKey = 'label',
  popperMaxWidth = 300,
  onSelectChange = () => {},
}: AutocompleteBaseProps) => {
  const { t } = useTranslation()
  const recentSearches = new RecentFiltersManager()
  const { control, setValue: setFilterValue, getValues } = useFormContext()

  const defaultValue = getValues(token.value) ?? []
  const transformedDefaultValue = defaultValue.map((item) => {
    if (typeof item === 'string') {
      return options?.find((option) => option.value === item) ?? item
    }
    return item
  })
  const anchorRef = useRef(null)
  const [selectAll, setSelectAll] = useState(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [localValue, setLocalValue] = useState<FilterOptionValue[]>(
    transformedDefaultValue
  )

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const setRecentSearch = (newValue: FilterOptionValue[]) => {
    recentSearches.addRecentFilter({
      key: token.value,
      data: newValue,
      label: token.label,
      text: newValue.map((item) => item.label).join(', '),
    })
  }

  const handleClose = () => {
    if (anchorEl) {
      anchorEl.focus()
    }
    setAnchorEl(null)
    if (localValue.length > 0) {
      setRecentSearch(localValue)
    }
  }

  const open = Boolean(anchorEl)
  const id = open ? 'autocomplete-button-label' : undefined

  const getRenderOption = (props, option, { selected }) => {
    const { className, ...rest } = props
    return (
      <MenuItemWithCheckbox {...rest} selected={selected} text={option.label} />
    )
  }

  const handleOnInputChange = (
    _event: SyntheticEvent<Element, Event>,
    search: string
  ) => {
    if (onInputChange) {
      onInputChange(search)
    }
  }

  const handleDelete = () => {
    setFilterValue(token.value, [])
    onDelete(token.value)
  }

  const onSelectAll = () => {
    setSelectAll((prev) => {
      if (!prev) {
        setLocalValue(options)
        setFilterValue(token.value, options)
      } else {
        setLocalValue([])
        setFilterValue(token.value, [])
      }
      return !prev
    })
  }

  useEffect(() => {
    if (anchorRef?.current && defaultValue.length === 0) {
      setAnchorEl(anchorRef.current)
    }
  }, [anchorRef])

  useEffect(() => {
    if (!isEqual(defaultValue, transformedDefaultValue)) {
      setFilterValue(token.value, transformedDefaultValue)
    }
  }, [])

  return (
    <Fragment>
      <Controller
        control={control}
        name={token.value}
        render={({ field: { value, onChange } }) => (
          <>
            <DropdownTrigger
              open={open}
              anchorRef={anchorRef}
              partOne={token?.shortLabel ?? token.label}
              onClick={handleClick}
              onDelete={handleDelete}
              partTwo={
                !asynchronous && options.length === value.length
                  ? 'All selected'
                  : value.map((item) => item.label).join(', ')
              }
            />
            <AutocompleteBasePopper
              id={id}
              open={open}
              value={value ?? null}
              multiple={true}
              anchorEl={anchorEl}
              onClose={handleClose}
              popperMaxWidth={popperMaxWidth}
              onSelectChange={(_event, item: FilterOptionValue[]) => {
                onChange(item)
                setLocalValue(item)
                onSelectChange(item)
              }}
              loading={loading}
              options={options}
              selectAll={selectAll}
              optionKey={optionKey}
              onSelectAll={onSelectAll}
              asynchronous={asynchronous}
              noOptionsText={noOptionsText}
              renderOption={getRenderOption}
              onInputChange={handleOnInputChange}
              inputPlaceholder={t(
                'common.select.type_to_find_value',
                'Type to find value'
              )}
            />
          </>
        )}
      />
    </Fragment>
  )
}

export default AutocompleteBase
