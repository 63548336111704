import { EmptyState } from 'src/stories'
import { CurrencyExchange } from '@mui/icons-material'
import { ShipmentQuotationsContainerProps } from 'src/pages/ShipmentLayout/ShipmentCosts/ShipmentCosts.props'
import { useTranslation } from 'react-i18next'

export const ShipmentCostsAirQuote: React.FC<ShipmentQuotationsContainerProps> = ({
  shipment,
}) => {
  const { t } = useTranslation()
  return (
    <EmptyState
      badgeColor="primary"
      badgeIcon={CurrencyExchange}
      title={t(
        'shipment_costs.air.empty_state.title',
        'We are crunching the numbers'
      )}
      button={{
        text: t('shipment_costs.air.empty_state.button', 'Chat with us'),
        link: `/shipments/${shipment.id}/chats`,
      }}
      description={t(
        'shipment_costs.air.empty_state.description',
        'We are gathering prices to complete your quote request. You will be notified when our offer is complete. If you have any questions, please contact us via chat.'
      )}
    />
  )
}
