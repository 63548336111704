import { SelectChangeEvent } from '@mui/material'
import { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { ModalityEnum } from 'src/config/constants'
import { SingleSelect } from 'src/stories/Lab/Select/SingleSelect'
import { ModalitySelectProps } from './ModalitySelect.props'

const ModalitySelect: React.FC<ModalitySelectProps> = ({
  disabled,
  inlandOnly,
  defaultValue,
  onChangeModality,
  disablePortal = false,
  showLabel = true,
}) => {
  const { t } = useTranslation()
  const isInitialMount = useRef(true)

  const modalityOptions = [
    {
      id: ModalityEnum.Sea,
      label: t('common.modality.sea', 'Sea'),
      inland: false,
    },
    {
      id: ModalityEnum.Air,
      label: t('common.modality.air', 'Air'),
      inland: false,
    },
    {
      id: ModalityEnum.Road,
      label: t('common.modality.road', 'Road'),
      inland: true,
    },
    {
      id: ModalityEnum.Rail,
      label: t('common.modality.rail', 'Rail'),
      inland: true,
    },
    {
      id: ModalityEnum.Barge,
      label: t('common.modality.barge', 'Barge'),
      inland: true,
    },
  ]
  const [value, setValue] = useState<ModalitySelectProps['defaultValue']>(
    defaultValue
  )

  const options = inlandOnly
    ? modalityOptions.filter((option) => option.inland)
    : modalityOptions

  const onChange = (event: SelectChangeEvent<unknown>) => {
    const updatedValue = event.target.value as ModalityEnum
    setValue(updatedValue)
    if (onChangeModality) {
      onChangeModality(updatedValue)
    }
  }

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false
    } else if (defaultValue !== value) {
      setValue(defaultValue)
    }
  }, [defaultValue])

  return (
    <SingleSelect
      value={value}
      label={showLabel ? t('common.modality', 'Modality') : ''}
      options={options}
      onChange={onChange}
      disabled={disabled}
      placeholder={t('common.select', 'Select')}
      MenuProps={{ disablePortal }}
      data-testid="modality-select"
      inputProps={{ 'data-testid': 'modality-select-input' }}
    />
  )
}

export default ModalitySelect
