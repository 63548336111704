import { InputLabel } from '@mui/material'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { SingleSelect } from 'src/stories/Lab/Select/SingleSelect'

const TransportOrderDialogFormStatus = () => {
  const { control } = useFormContext()
  const { t } = useTranslation()

  const statusOptions = [
    { id: 'estimated', label: 'Estimated' },
    { id: 'requested', label: 'Requested' },
    { id: 'proposed', label: 'Proposed' },
    { id: 'confirmed', label: 'Confirmed' },
    { id: 'completed', label: 'Completed' },
  ]

  return (
    <>
      <InputLabel required>
        {t('common.forms.fields.inland_transport_status.label', 'Status')}
      </InputLabel>
      <Controller
        control={control}
        name="status"
        render={({ field: { onChange, value } }) => (
          <SingleSelect
            value={value}
            onChange={onChange}
            options={statusOptions}
            placeholder={t('common.select', 'Select')}
          />
        )}
      />
    </>
  )
}

export default TransportOrderDialogFormStatus
