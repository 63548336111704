import { MenuItem } from '@mui/material'
import { useTranslation } from 'react-i18next'

const RowActionMenuItemsTransportOrder = ({ service, onClick, isLoading }) => {
  const { t } = useTranslation()

  return (
    <MenuItem
      disabled={isLoading}
      children={t(
        `transport_order_form.buttons.create_${service}_order`,
        `Create ${service} order`
      )}
      onClick={onClick}
      data-testid={`row-action-menu-item-${service}-transport-order`}
    />
  )
}

export default RowActionMenuItemsTransportOrder
