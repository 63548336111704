import { useQueries } from '@tanstack/react-query'
import { useState, createContext } from 'react'
import { Switch, Route, useHistory, Redirect } from 'react-router'
import { apiClient } from 'src/services/http-common'
import { createQueryString } from 'src/services/utils'
import { bookingTitle } from './helpers'
import ShippingInstructionsContainer from './components/ShippingInstructionsContainer'
import ShippingInstructionsShipmentView from './components/ShippingInstructionsShipmentView'
import { IShippingInstruction } from './types'

import './index.scss'

export const FormContext = createContext<any>(null)

const ShippingInstructions = ({
  match: {
    params: { shipment_id, booking_id },
  },
}) => {
  const history = useHistory()
  const [booking, setBooking] = useState<IBooking | null>(null)
  const [instruction, setInstruction] = useState<IShippingInstruction | null>(
    null
  )

  const [bookings, instructions] = useQueries({
    queries: [
      {
        queryKey: ['bookings', booking_id],
        queryFn: () =>
          apiClient.get<any, any>(`shipments/${shipment_id}/bookings`),
        onSuccess: ({ bookings }: { bookings: IBooking[] }) => {
          const booking = bookings.find(
            (booking: IBooking) => booking.id === +booking_id
          )
          if (!booking) return history.push('/404')
          setBooking(booking)
        },
      },
      {
        queryKey: ['instruction', booking_id],
        queryFn: () =>
          apiClient.get<any, any>(
            `shipments/${shipment_id}/shipping_instructions/new` +
              createQueryString({ booking_id })
          ),
        onSuccess: (data: IShippingInstruction) => {
          setInstruction(data)
        },
      },
    ],
  })

  if (bookings.isLoading || booking === null || instructions.isLoading)
    return null

  const title = bookingTitle(booking)

  const isInstructionCreated = instruction?.id !== null

  return (
    <FormContext.Provider
      value={{
        instruction,
        setInstruction,
        refetchInstruction: instructions.refetch,
        bookingId: booking_id,
        shipmentId: shipment_id,
      }}
    >
      <Switch>
        {!isInstructionCreated && (
          <Route
            path="/shipments/:shipment_id/bookings/:booking_id/shipping-instructions/steps/1"
            component={() => <ShippingInstructionsShipmentView title={title} />}
          />
        )}
        <Route
          path="/shipments/:shipment_id/bookings/:booking_id/shipping-instructions/steps/2"
          component={() => <ShippingInstructionsContainer title={title} />}
        />
        <Redirect
          from="/shipments/:shipment_id/bookings/:booking_id/shipping-instructions/steps/1"
          to="/shipments/:shipment_id/bookings/:booking_id/shipping-instructions/steps/2"
        />
      </Switch>
    </FormContext.Provider>
  )
}

export default ShippingInstructions
