import { FunctionComponent, useMemo, useState, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { includes, find, values, compact } from 'lodash'
import useGoBack from 'src/hooks/useGoBack'
import { useHistory, useLocation } from 'react-router'
import { useTranslation } from 'react-i18next'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import VisibilityIcon from '@mui/icons-material/Visibility'
import FlagIcon from '@mui/icons-material/Flag'
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined'
import { Box, Button, Grid } from '@mui/material'
import { showNotification } from 'src/stores/actionCreators/notifications'
import ShipmentTemplate from 'src/components/ShipmentTemplate'
import { BreadcrumbItem, Breadcrumbs } from 'src/stories/Breadcrumbs'
import { CreateRounded } from '@mui/icons-material'
import { AvatarGroup } from 'src/stories/AvatarGroup'
import ShipmentReferencesModal from 'src/components/ShipmentReferencesModal'
import ShipmentShareModal from 'src/components/ShipmentShareModal'
import ShipmentCollaboratorsPopper from 'src/components/ShipmentCollaboratorsPopper'
import { getInternalReference } from '../ShipmentReferencesModal'
import { permissionTo, promisifyAction } from '../../utils'

import {
  updateConnections,
  shipmentOverviewGetData,
} from '../../stores/actionCreators'
import { renderTooltipReference, getPartiesAvatarsArray } from '../helpers'
import './styles.scss'

const classesTooltip = {
  tooltip: 'shipment__header__tooltip',
  popper: 'shipment__header__popper',
}

const ShipmentHeader: FunctionComponent<{}> = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()

  const [openEditModal, setOpenEditModal] = useState<boolean>(false)
  const [openShareModal, setOpenShareModal] = useState<boolean>(false)
  const [buttonLoading, setButtonLoading] = useState<boolean>(false)

  const updateConnectionsAsync = promisifyAction(dispatch, updateConnections)
  const shipmentOverviewGetDataAsync = promisifyAction(
    dispatch,
    shipmentOverviewGetData
  )

  const showSuccess = (message: string): void => {
    dispatch(showNotification({ message, severity: 'success' }))
  }
  const showError = (message: string): void => {
    dispatch(showNotification({ message, severity: 'error' }))
  }

  const closeModal = useCallback(() => {
    setOpenEditModal(false)
  }, [])

  const { shipment, currentUser, displayShipmentShareModal } = useSelector(
    (state: IGlobalState) => ({
      shipment: state.shipmentOverview,
      currentUser: state.user,
      displayShipmentShareModal: state.flags.displayShipmentShareModal,
    })
  )

  const isCarrierBooking = !!(
    location?.pathname === `/shipments/${shipment.id}/carrier-booking`
  )

  const goBack = useGoBack()
  const isQuote: boolean = useMemo(
    (): boolean => includes(['QUO', 'PQUO'], shipment.status),
    [shipment]
  )

  const organizationReference: string = useMemo(
    () => getInternalReference(shipment, currentUser),
    [shipment]
  )

  const flagBlock = useCallback((): React.ReactNode => {
    const currentParty: IShipmentParty | undefined = find(
      shipment.collaborators,
      (party) => party.organization_id === currentUser.organizationId
    )
    if (!currentParty) {
      return null
    }

    return currentParty.flagged_at ? (
      <FlagIcon color="primary" />
    ) : (
      <FlagOutlinedIcon color="primary" />
    )
  }, [shipment.collaborators, currentUser.organizationId])

  const manageReferenceBlock = (): React.ReactNode => {
    const isAllowed = permissionTo('shipments.overview.reference.manage')

    const openModal = () => {
      if (!isAllowed) return
      setOpenEditModal(true)
    }

    return (
      <Breadcrumbs
        links={links}
        current={
          location?.pathname === `/shipments/${shipment.id}/carrier-booking` ? (
            'Carrier booking'
          ) : (
            <Box
              onClick={openModal}
              display="flex"
              alignItems="center"
              data-testid="shipment-header-edit"
              sx={{ cursor: isAllowed ? 'pointer' : 'inherit' }}
            >
              <Tooltip
                title={renderTooltipReference(
                  shipment.shared_reference,
                  organizationReference,
                  shipment.title
                )}
                classes={classesTooltip}
                placement="bottom"
              >
                <Typography variant="body1Strong">{reference}</Typography>
              </Tooltip>
              {isAllowed && (
                <CreateRounded
                  data-testid="pencil-icon"
                  fontSize="small"
                  sx={{ ml: 1 }}
                />
              )}
            </Box>
          )
        }
      ></Breadcrumbs>
    )
  }

  const reference = useMemo(() => {
    const references = [
      shipment.title,
      organizationReference,
      shipment.shared_reference,
    ]

    return shipment.shared_reference || organizationReference ? (
      <Box className="references" data-testid="shipment-header-references">
        {compact(references).join(' | ')}
      </Box>
    ) : (
      shipment.title
    )
  }, [shipment.title, organizationReference, shipment.shared_reference])

  const shipmentCollaborators = useMemo(() => {
    if (shipment.visibility_only) {
      return shipment.collaborators.filter((collaborator) => {
        return (
          collaborator.roles.length > 1 ||
          collaborator.roles[0].role !== 'Visibility provider'
        )
      })
    }

    return shipment.collaborators
  }, [shipment.collaborators])

  const links = useMemo(() => {
    const items: BreadcrumbItem[] = [
      {
        label: isQuote
          ? t('common.entities.quote_other', 'Quotes')
          : t('common.entities.shipment_other', 'Shipments'),
        onClick: goBack,
      },
    ]
    if (isCarrierBooking) {
      return [
        ...items,
        {
          label: reference,
          onClick: () => history.push(`/shipments/${shipment.id}`),
        },
      ]
    }

    return items
  }, [isCarrierBooking, isQuote])

  if (!shipment.id) return null

  const showPublicLink: boolean =
    permissionTo('shipments.public_link.all') &&
    !!shipment &&
    !!shipment.token_enabled &&
    !!shipment.token

  const onSaveAndFetch = async (selectedConnections) => {
    const dataObj = selectedConnections.map((party) => {
      return {
        id: party?.id,
        organization_id: party.organization_id,
        role_ids: (party.roles || []).map((role) => role.id) || [],
      }
    })
    try {
      setButtonLoading(true)
      await updateConnectionsAsync(shipment ? shipment.id : '', {
        shipment_organizations: dataObj,
      })
      showSuccess('You have added one or more new collaborators.')
      await shipmentOverviewGetDataAsync(shipment ? shipment.id : '')
      setOpenShareModal(false)
    } catch (error) {
      const unknownError: any = error
      const errorsArr: string[] = values(unknownError.response.data.errors).map(
        (item) => {
          return item || '-'
        }
      )
      showError(
        values(unknownError.response.data.errors).length > 1
          ? compact(errorsArr).join(', ')
          : errorsArr[0]
      )
      setButtonLoading(false)
    }
    setButtonLoading(false)
  }

  return (
    <header className="shipment__header">
      <Grid container spacing={2} data-testid="shipment-header-content">
        <Grid item xs={12} md={8} display="flex" alignItems="center">
          {manageReferenceBlock()}
        </Grid>
        <Grid item xs={12} md={4}>
          <div
            className="shipment__header--share-content"
            data-testid="shipment-header-share-content"
          >
            <ShipmentTemplate
              shipmentId={shipment.id}
              shipmentContainerType={shipment.container_type}
            />
            <ShipmentCollaboratorsPopper
              shipmentId={shipment.id}
              parties={shipment.collaborators}
              groupByValue="organization_id"
              watchersCount={shipment.watchers_count}
              watched={shipment.watched}
              withFlag={true}
            >
              {shipment.watched ? (
                <VisibilityIcon data-testid="visibility-icon" color="primary" />
              ) : (
                ''
              )}
              {permissionTo('shipments.shipment_collaborators.view') && (
                <>
                  {flagBlock()}
                  <Box mr={2}>
                    <AvatarGroup
                      avatars={getPartiesAvatarsArray(
                        shipmentCollaborators,
                        'organization_id'
                      )}
                      max={4}
                      className="mr-15"
                      onClick={setOpenShareModal.bind(null, true)}
                    />
                  </Box>
                </>
              )}
            </ShipmentCollaboratorsPopper>
            {permissionTo('shipments.shipment_collaborators.view') && (
              <Button
                data-testid="share-button"
                variant="contained"
                onClick={setOpenShareModal.bind(null, true)}
              >
                {t('common.buttons.share', 'Share')}
              </Button>
            )}
          </div>
        </Grid>
      </Grid>
      {openEditModal && (
        <ShipmentReferencesModal
          shipmentTitle={shipment.title}
          shipmentId={shipment.id}
          open
          onClose={closeModal}
        />
      )}
      {(openShareModal || displayShipmentShareModal) && (
        <ShipmentShareModal
          shipmentId={shipment.id}
          collaborators={shipment.collaborators || []}
          showPublicLink={showPublicLink}
          open
          onClose={setOpenShareModal.bind(null, false)}
          onSaveAndFetch={onSaveAndFetch}
          buttonLoading={buttonLoading}
          token={shipment.token || ''}
          allowManageable={false}
          headerText={t('shipment_share.title', 'Share shipment')}
        />
      )}
    </header>
  )
}

export default ShipmentHeader
