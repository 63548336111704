import { FunctionComponent, useState, useMemo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Route, Switch, Redirect } from 'react-router'
import { History, LocationState } from 'history'
import Typography from '@mui/material/Typography'
import Tabs from '@mui/material/Tabs'
import { Paper } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import SLA from 'src/components/OrganizationSettings/SLA'
import OrganizationForm from 'src/components/OrganizationSettings/OrganizationForm'
import ApiToken from 'src/components/OrganizationSettings/ApiToken'
import Users from 'src/components/OrganizationSettings/Users'
import Tab from 'src/components/Tab'
import { permissionTo } from '../../utils/'
import './styles.scss'

interface IProps {
  history: History<LocationState>
  location: Location
  match?: IMatch | null
}

const OrganizationSettings: FunctionComponent<IProps> = (props) => {
  const [tabValue, setTabValue] = useState<string>('')
  const theme = useTheme()
  const { t } = useTranslation()

  const slaPermission = useMemo(() => {
    return permissionTo('service_level_agreements.view')
  }, [])

  useEffect(() => {
    const currentLocation: string = props.location.pathname.replace(
      '/organization_settings',
      ''
    )
    setTabValue(
      ['/service_level_agreements', '/users', '/api_token'].includes(
        currentLocation
      )
        ? currentLocation
        : slaPermission
        ? '/service_level_agreements'
        : '/users'
    )
  }, [])

  return (
    <article className="organization-settings-page">
      <div className="dashboard-header--top">
        <Typography
          variant="h3"
          children={t('organization_settings.header', 'Organization setting')}
        />
      </div>
      <div className="flex">
        <div className="organization-settings-page__wrapper">
          <Paper variant="elevation">
            <OrganizationForm />
          </Paper>
        </div>
        <Paper
          classes={{
            root: 'organization-settings-page__content filters mui-override',
          }}
        >
          <>
            <Tabs
              value={tabValue}
              sx={{
                borderBottom: `1px solid ${theme.palette.action.disabledBackground}`,
              }}
            >
              {slaPermission && (
                <Tab
                  label={t('organization_settings.sla', 'SLA')}
                  basePath="/organization_settings"
                  value="/service_level_agreements"
                  onTabClick={(value: string) => {
                    setTabValue(value)
                  }}
                />
              )}
              <Tab
                label={t('organization_settings.users', 'Users')}
                basePath="/organization_settings"
                value="/users"
                onTabClick={(value: string) => {
                  setTabValue(value)
                }}
              />
              <Tab
                label={t('organization_settings.api_token', 'API token')}
                basePath="/organization_settings"
                value="/api_token"
                onTabClick={(value: string) => {
                  setTabValue(value)
                }}
              />
            </Tabs>
            <Switch>
              {slaPermission && (
                <Route
                  path="/organization_settings/service_level_agreements"
                  component={SLA}
                />
              )}
              <Route path="/organization_settings/users" component={Users} />
              <Route
                path="/organization_settings/api_token"
                component={ApiToken}
              />
              <Redirect
                to={`${props.match ? props.match.url : ''}${
                  slaPermission ? '/service_level_agreements' : '/users'
                }`}
              />
            </Switch>
          </>
        </Paper>
      </div>
    </article>
  )
}

export default OrganizationSettings
