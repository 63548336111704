export const getSellerHasAllDocuments = (seller: IOrganizationWithTerms) => {
  if (!seller?.public_documents) return false

  const mandatoryDocuments = ['terms_and_conditions', 'forwarding_conditions']

  return mandatoryDocuments.every((docType) => {
    return seller.public_documents?.some(
      (item) => item.document_type.code === docType
    )
  })
}
