import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { IParty } from '../types'

const Party = ({ party }: { party: IParty }) => {
  const { t } = useTranslation()
  return (
    <>
      <Typography variant="h6" gutterBottom>
        {t(`common.${party.role_type}`, party.role_type)}
      </Typography>
      <Box
        sx={{
          border: '1px solid var(--grey-200)',
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <Typography sx={{ whiteSpace: 'pre-line' }}>
          {party.address_details}
        </Typography>
      </Box>
    </>
  )
}

export default Party
