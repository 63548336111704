export const groupingKey = 'group_by'

export const customFields = {
  root: [
    'id',
    'type',
    'desc',
    'number',
    'status',
    'seal_number',
    'discharged_at',
    'pickup_status',
    'delivery_status',
    'pickup_time',
    'delivery_time',
    'inspection_datetime',
    'gate_out',
    'portbase_enabled',
    'pod_customs_documents_present',
    'pod_customs_inspection_type',
    'pod_customs_inspection_status',
    'actual_first_day_of_demurrage',
    'pickup_address',
    'delivery_address',
    'pickup_transporter',
    'delivery_transporter',
    'pickup_notes',
    'delivery_notes',
    'shared_reference',
    'tasks',
    'delivery_inland_transport_id',
    'pickup_inland_transport_id',
  ],
  shipment: [
    'tracking_status',
    'id',
    'title',
    'status',
    'vessel',
    'terminal',
    'loading_terminal',
    'modality',
    'load_type',
    'shippers',
    'consignees',
    'loading_port',
    'discharge_port',
    'shared_reference',
    'internal_reference',
    'estimated_arrival',
    'estimated_departure',
    'estimated_discharge',
    'shipment_organizations',
    'carrier_estimated_arrival',
    'terminal_estimated_arrival',
    'house_bl_numbers',
    'master_bl_number',
    'closing_datetime',
    'closing_datetime_status',
    'owner_organization',
    'carrier',
    'created_at',
    'comment',
  ],
}

export const groups = [
  {
    label: 'vessels_page.groups.shipments',
    value: 'shipment',
  },
  {
    label: 'vessels_page.groups.vessels',
    value: 'vessel',
  },
]

export const entityGroupMap = {
  shipment: 'container.shipment.title',
  vessel: 'container.shipment.vessel.name',
}

export const tableStorageKey = 'overviewTableState'

export const viewsLocalStorageKey = `${tableStorageKey}_views`

export const savedViewLocalStorageKey = `${tableStorageKey}_viewId`

export const subItemsPerPage = 20

export const tableSortingKeys = {
  trackingStatus: 'container.shipment.tracking_status.status',
  containerNumber: 'container.number',
  shipmentVoyageNumber: 'container.shipment.vessel.voyage_no',
  containerStatus: 'container.status.name',
  containerPodCustomsDocumentsPresent:
    'container.pod_customs_documents_present',
  shipmentStatus: 'container.shipment.status.name',
  shipmentEstimatedDeparture: 'container.shipment.estimated_departure',
  shipmentEstimatedArrival: 'container.shipment.estimated_arrival',
  shipmentEstimatedDischarge: 'container.shipment.estimated_discharge',
  shipmentCarrier: 'container.shipment.carrier.name',
  shipmentLoadingPort: 'container.shipment.loading_port.name',
  shipmentDischargePort: 'container.shipment.discharge_port.name',
  shipmentTerminal: 'container.shipment.terminal.name',
  shipmentCreatedAt: 'container.shipment.created_at',
  shipmentLoadingTerminal: 'container.shipment.loading_terminal.name',
  containerPickupStatus: 'container.pickup_status',
  containerPickupTime: 'container.pickup_time',
  containerDeliveryStatus: 'container.delivery_status',
  containerDeliveryTime: 'container.delivery_time',
  containerSealNumber: 'container.seal_number',
  shipmentTitle: 'container.shipment.title',
  shipmentVessel: 'container.shipment.vessel.name',
  containerDischargeAt: 'container.discharged_at',
  shipmentTerminalEstimatedArrival:
    'container.shipment.terminal_estimated_arrival',
  shipmentComment: 'container.shipment.comment',
  containerGateOut: 'container.gate_out',
  containerInspectionDatetime: 'container.inspection_datetime',
  containerPodCustomsInspectionStatus:
    'container.pod_customs_inspection_status',
  containerPodCustomsInspectionType: 'container.pod_customs_inspection_type',
  containerActualFirstDayOfDemurrage: 'container.actual_first_day_of_demurrage',
  shipmentCarrierEstimatedArrival:
    'container.shipment.carrier_estimated_arrival',
  shipmentModality: 'container.shipment.modality',
  shipmentLoadType: 'container.shipment.load_type',
  containerType: 'container.type.name',
  shipmentConsignees: 'container.shipment.consignees',
  shipmentShippers: 'container.shipment.shippers',
  shipmentSharedReference: 'container.shipment.shared_reference',
  shipmentInternalReference: 'container.shipment.internal_reference',
  shipmentOwnerOrganizationName: 'container.shipment.owner_organization.name',
  containerDeliveryAddress: 'container.delivery_address.name',
  containerPickupAddress: 'container.pickup_address.name',
  houseBlNumbers: 'container.shipment.house_bl_numbers',
  masterBlNumber: 'container.shipment.master_bl_number',
  pickupTransporter: 'container.pickup_transporter.name',
  deliveryTransporter: 'container.delivery_transporter.name',
  shipmentClosingDateTime: 'container.shipment.closing_datetime',
  containerPickupNotes: 'container.pickup_notes',
  containerDeliveryNotes: 'container.delivery_notes',
  containerSharedReference: 'container.shared_reference',
}

export const defaultColumnOrder = [
  tableSortingKeys.shipmentTitle,
  tableSortingKeys.containerNumber,
  tableSortingKeys.containerStatus,
  tableSortingKeys.shipmentShippers,
  tableSortingKeys.shipmentLoadingPort,
  tableSortingKeys.shipmentDischargePort,
  tableSortingKeys.shipmentVessel,
  tableSortingKeys.shipmentEstimatedArrival,
  tableSortingKeys.shipmentTerminalEstimatedArrival,
  tableSortingKeys.shipmentEstimatedDischarge,
  tableSortingKeys.containerDeliveryStatus,
  tableSortingKeys.containerDeliveryAddress,
  tableSortingKeys.deliveryTransporter,
  tableSortingKeys.shipmentOwnerOrganizationName,
  tableSortingKeys.shipmentStatus,
  tableSortingKeys.shipmentClosingDateTime,
  tableSortingKeys.shipmentTerminal,
  tableSortingKeys.shipmentCreatedAt,
  tableSortingKeys.shipmentLoadingTerminal,
  tableSortingKeys.shipmentConsignees,
  tableSortingKeys.shipmentSharedReference,
  tableSortingKeys.shipmentInternalReference,
  tableSortingKeys.shipmentEstimatedDeparture,
  tableSortingKeys.shipmentCarrierEstimatedArrival,
  tableSortingKeys.shipmentCarrier,
  tableSortingKeys.shipmentVoyageNumber,
  tableSortingKeys.shipmentModality,
  tableSortingKeys.shipmentLoadType,
  tableSortingKeys.houseBlNumbers,
  tableSortingKeys.masterBlNumber,
  tableSortingKeys.containerType,
  tableSortingKeys.containerPodCustomsDocumentsPresent,
  tableSortingKeys.containerSealNumber,
  tableSortingKeys.containerDischargeAt,
  tableSortingKeys.containerGateOut,
  tableSortingKeys.containerInspectionDatetime,
  tableSortingKeys.containerPodCustomsInspectionStatus,
  tableSortingKeys.containerPodCustomsInspectionType,
  tableSortingKeys.containerActualFirstDayOfDemurrage,
  tableSortingKeys.containerPickupStatus,
  tableSortingKeys.containerPickupTime,
  tableSortingKeys.containerPickupAddress,
  tableSortingKeys.containerDeliveryTime,
  tableSortingKeys.pickupTransporter,
  tableSortingKeys.containerDeliveryNotes,
  tableSortingKeys.containerPickupNotes,
  tableSortingKeys.containerSharedReference,
  tableSortingKeys.shipmentComment,
]

export const defaultColumnVisibility = {
  [tableSortingKeys.shipmentTitle]: true,
  [tableSortingKeys.containerNumber]: true,
  [tableSortingKeys.containerStatus]: true,
  [tableSortingKeys.shipmentShippers]: true,
  [tableSortingKeys.shipmentLoadingPort]: true,
  [tableSortingKeys.shipmentDischargePort]: true,
  [tableSortingKeys.shipmentVessel]: true,
  [tableSortingKeys.shipmentEstimatedArrival]: true,
  [tableSortingKeys.shipmentTerminalEstimatedArrival]: true,
  [tableSortingKeys.shipmentEstimatedDischarge]: true,
  [tableSortingKeys.containerDeliveryStatus]: true,
  [tableSortingKeys.containerDeliveryAddress]: true,
  [tableSortingKeys.deliveryTransporter]: true,
  [tableSortingKeys.shipmentVoyageNumber]: false,
  [tableSortingKeys.containerType]: false,
  [tableSortingKeys.containerPodCustomsDocumentsPresent]: false,
  [tableSortingKeys.shipmentStatus]: false,
  [tableSortingKeys.shipmentEstimatedDeparture]: false,
  [tableSortingKeys.shipmentTerminal]: false,
  [tableSortingKeys.shipmentCreatedAt]: false,
  [tableSortingKeys.shipmentLoadingTerminal]: false,
  [tableSortingKeys.containerPickupStatus]: false,
  [tableSortingKeys.containerPickupTime]: false,
  [tableSortingKeys.containerDeliveryTime]: false,
  [tableSortingKeys.containerSealNumber]: false,
  [tableSortingKeys.containerDischargeAt]: false,
  [tableSortingKeys.containerGateOut]: false,
  [tableSortingKeys.containerInspectionDatetime]: false,
  [tableSortingKeys.containerPodCustomsInspectionStatus]: false,
  [tableSortingKeys.containerPodCustomsInspectionType]: false,
  [tableSortingKeys.containerActualFirstDayOfDemurrage]: false,
  [tableSortingKeys.shipmentCarrierEstimatedArrival]: false,
  [tableSortingKeys.shipmentModality]: false,
  [tableSortingKeys.shipmentLoadType]: false,
  [tableSortingKeys.shipmentConsignees]: false,
  [tableSortingKeys.shipmentCarrier]: false,
  [tableSortingKeys.shipmentSharedReference]: false,
  [tableSortingKeys.shipmentInternalReference]: false,
  [tableSortingKeys.houseBlNumbers]: false,
  [tableSortingKeys.masterBlNumber]: false,
  [tableSortingKeys.pickupTransporter]: false,
  [tableSortingKeys.containerPickupAddress]: false,
  [tableSortingKeys.shipmentClosingDateTime]: false,
  [tableSortingKeys.shipmentOwnerOrganizationName]: false,
  [tableSortingKeys.containerPickupNotes]: false,
  [tableSortingKeys.containerDeliveryNotes]: false,
  [tableSortingKeys.containerSharedReference]: false,
  [tableSortingKeys.shipmentComment]: false,
}

export const tableGroups = {
  vessel: {
    value: 'vessel',
    label: 'Vessel',
    i18nKey: 'vessels_page.show_hide_group.vessel',
  },
  container: {
    value: 'container',
    label: 'Container',
    i18nKey: 'vessels_page.show_hide_group.container',
  },
  shipment: {
    value: 'shipment',
    label: 'Shipment',
    i18nKey: 'vessels_page.show_hide_group.shipment',
  },
  pickup: {
    value: 'pickup',
    label: 'Pickup',
    i18nKey: 'vessels_page.show_hide_group.pickup',
  },
  delivery: {
    value: 'delivery',
    label: 'Delivery',
    i18nKey: 'vessels_page.show_hide_group.delivery',
  },
  tasks: {
    value: 'tasks',
    label: 'Tasks',
    i18nKey: 'vessels_page.show_hide_group.tasks',
  },
}

export const allowedColumnsRootMap = {
  root: 'container',
  status: 'container.status',
  shipment: 'container.shipment',
  cluster: 'container.cluster',
  delivery_transporter: 'container.delivery_transporter',
  pickup_transporter: 'container.pickup_transporter',
  type: 'container.type',
  pickup_address: 'container.pickup_address',
  delivery_address: 'container.delivery_address',
  tasks: 'tasks',
}

export const tableCellMutationKey = 'edit_inland_transport'

export const bulkEditableColumns = [
  tableSortingKeys.pickupTransporter,
  tableSortingKeys.deliveryTransporter,
  tableSortingKeys.containerPickupAddress,
  tableSortingKeys.containerDeliveryAddress,
  tableSortingKeys.containerPickupTime,
  tableSortingKeys.containerDeliveryTime,
]
