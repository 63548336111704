import { FunctionComponent } from 'react'
import capitalize from 'lodash/capitalize'
import { Box, Button, Link } from '@mui/material'
import Typography from '@mui/material/Typography'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import { history } from 'src/shyppleStore'
import { RatesListTypeEnum } from 'src/@types/endpoints/prices'
import { useTranslation } from 'react-i18next'
import { getIsFreightPresent, getActionButtonText } from '../helpers'
import RatesLineDetailsChargesTable from './RatesLineDetailsChargesTable'
import RatesLineDetailsAirAdditionalInformation from './RatesLineDetailsAirAdditionalInformation'
import { TableCustom, TableCustomCell } from './RatesLineDetails.styles'
import RatesLineDetailsNotification from './RatesLineDetailsNotification'

const PricesPerContainerType: FunctionComponent<{ rate: IRate }> = ({
  rate,
}) => {
  const { t } = useTranslation()
  return (
    <>
      <Typography
        variant="h5"
        children={t(
          'rates.table_content.headings.prices_per_container_type',
          'Prices per container type'
        )}
      />
      <TableCustom size="small">
        <TableBody>
          {rate.prices.map((price, idx) => {
            if (price.service_item.service_code === 'freight') {
              return (
                <TableRow key={idx}>
                  <TableCustomCell>{price.service_item.name}</TableCustomCell>
                  <TableCustomCell>{price.value}</TableCustomCell>
                  <TableCustomCell>
                    {price.currency} / {capitalize(price.calculation_method)}
                  </TableCustomCell>
                </TableRow>
              )
            }
            return null
          })}
        </TableBody>
      </TableCustom>
    </>
  )
}

const LocalCharges: FunctionComponent<{ rate: IRate }> = ({ rate }) => {
  const { t } = useTranslation()
  return (
    <>
      {!!rate.origin.local.length && (
        <>
          <Typography
            variant="h5"
            children={t(
              'rates.table_content.headings.local_charges_origin',
              'Local charges at origin'
            )}
          />
          <RatesLineDetailsChargesTable prices={rate.origin.local} />
        </>
      )}

      {!!rate.destination.local.length && (
        <>
          <Typography
            variant="h5"
            children={t(
              'rates.table_content.headings.local_charges_destination',
              'Local charges at destination'
            )}
          />
          <RatesLineDetailsChargesTable prices={rate.destination.local} />
        </>
      )}
    </>
  )
}

interface IProps {
  rate: IRate
  bookUrl: string
  status: 'on_request' | 'future' | 'historic' | 'valid'
}

const RatesLineDetails: FunctionComponent<IProps> = ({
  rate,
  bookUrl,
  status,
}) => {
  const { t } = useTranslation()
  const isFreightPresent: boolean = getIsFreightPresent(rate.prices)

  const handleClickOnActionButton = () => {
    history.push(bookUrl)
  }

  return (
    <Grid className="rates-details" container spacing={0} component={Paper}>
      {!isFreightPresent && (
        <Grid item xs={12}>
          <Box pt={3} px={3}>
            <RatesLineDetailsNotification
              loadingPort={rate.loading_port.name}
              dischargePort={rate.discharge_port.name}
              prices={rate.prices}
              bookUrl={bookUrl}
            />
          </Box>
        </Grid>
      )}
      <Grid item sm={12} md={12} lg={4}>
        <Box m={3}>
          <RatesLineDetailsAirAdditionalInformation
            rate={rate}
            status={status}
          />
          <Button
            sx={{ whiteSpace: 'nowrap' }}
            className="rates-details___button"
            onClick={handleClickOnActionButton}
            variant="contained"
            component={Link}
          >
            {getActionButtonText(isFreightPresent, t)}
          </Button>
        </Box>
      </Grid>
      {rate.list_type === RatesListTypeEnum.fcl && isFreightPresent && (
        <Grid item sm={12} md={6} lg={4}>
          <Box m={3}>
            <PricesPerContainerType rate={rate} />
          </Box>
        </Grid>
      )}
      <Grid item sm={12} md={6} lg={4}>
        <Box m={3}>
          <LocalCharges rate={rate} />
        </Box>
      </Grid>
    </Grid>
  )
}

export default RatesLineDetails
