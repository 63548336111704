import { FunctionComponent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  omitBy,
  isEmpty,
  uniq,
  toNumber,
  round,
  includes,
  each,
  has,
} from 'lodash'
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import { Button, Typography } from '@mui/material'
import { Dialog, DialogContent, Table } from 'src/stories'
import {
  initialCargoItem,
  convertToProceed,
  convertToNewFormat,
} from 'src/stores/reducers/shipments'
import { promisifyAction } from 'src/utils'
import {
  createCargoLine,
  updateCargoLine,
  deleteCargoLine,
} from 'src/stores/actionCreators'
import {
  cargoErrorsDependencies,
  ModalityEnum,
  OrganizationRoleEnum,
} from 'src/config/constants'
import { CargoWindowStyles } from './styles'
import { cargoItemValidation } from './validation'
import { createCargoColumn } from './createCargoColumn'
import { CargoWindowWarningMessageModal } from './CargoWindowWarningMessageModal'

type Cargo = ICargo & {
  new?: boolean
  edited?: boolean
}
interface IProps {
  open: boolean
  cargoItems: Cargo[]
  close: () => void
  containerId: number | null
  editCarrierGood?: boolean
  fetchContainerData?: () => void
  shouldShowWarningMessage?: boolean
  isCargoDisabled?: boolean
  shipmentModality?: ModalityEnum
  onSave?: () => void
  bookings?: IBooking[]
}

export const convertCarrierBookingCargo = (cargos, bookings) => {
  if (!cargos || !cargos.length) return [initialCargoItem(bookings || [])]

  return cargos.map((item) => {
    const response = convertToNewFormat(item)
    return response
  })
}

interface IResolveCargoErrorsParams {
  cargoErrors: {
    [key: string]: {
      [key: string]: string
    }
  }
  id: string
  name: string
}

export const resolveErrors = ({
  cargoErrors,
  id,
  name,
}: IResolveCargoErrorsParams) => {
  const errors = cargoErrors[id]

  if (!isEmpty(errors)) {
    delete errors[name]
    each(cargoErrorsDependencies[name], (errorKey) => {
      delete errors[errorKey]
    })
  }
  return cargoErrors
}

const dimensionsNames: string[] = ['length', 'width', 'height']

const volumeNames: string[] = ['quantity', 'packageType']
const getUpdatedValueForDimension = (value, name) => {
  const key = `default_${name}_mm`
  if (has(value, key)) {
    return value[key] / 10
  }
  return 0
}
const getUpdateTotalVolume = (cargo) => {
  const { width, height, length, quantity } = cargo
  if (!width || !height || !length || !quantity) {
    return 0
  }
  return round(
    (toNumber(width) * toNumber(height) * toNumber(length) * quantity) /
      1000000,
    2
  )
}
export const updateCargoArray = (
  { id, name, value },
  cargoItems,
  shouldShowWarningMessage?: boolean
) => {
  const response = {
    updatedCargoArray: [] as IInitialCargoItem[],
    showWarningMessage: false,
  }
  response['updatedCargoArray'] = cargoItems.map((cargo) => {
    if (`${cargo.id}` !== `${id}`) {
      return cargo
    }
    cargo['edited'] = true
    cargo[name] = value
    if (name === 'packageType') {
      each(dimensionsNames, (dimensionsName: string) => {
        cargo[dimensionsName] = getUpdatedValueForDimension(
          value,
          dimensionsName
        )
      })
    }
    if (includes([...volumeNames, ...dimensionsNames], name)) {
      cargo.total_volume_cbm = getUpdateTotalVolume(cargo)
    }
    if (name === 'total_volume_cbm') {
      each(dimensionsNames, (dimensionsName: string) => {
        cargo[dimensionsName] = 0
      })
    }
    return cargo
  })

  /**
   * Show warning message when weight/measurement is changed by AIR Customer
   */
  const showWarningMessageCriteria = [
    ...dimensionsNames,
    ...volumeNames,
    'total_volume_cbm',
    'total_weight_kg',
  ]
  if (shouldShowWarningMessage && includes(showWarningMessageCriteria, name)) {
    response['showWarningMessage'] = true
  }

  return response
}
export const CargoWindow: FunctionComponent<IProps> = (props) => {
  const { t } = useTranslation()
  const [cargoItems, setCargoItems] = useState<
    IInitialCargoItem[] & {
      isCargoDisabled?: boolean
    }
  >([])
  const [cargoErrors, setCargoErrors] = useState<{
    [key: string]: {
      [key: string]: string
    }
  }>({})
  const [showWarningMessage, setShowWarningMessage] = useState(false)
  const [showMessageModal, setShowMessageModal] = useState(false)
  const [cargoItemsToDelete, setCargoItemsToDelete] = useState<string[]>([])

  const dispatch = useDispatch()
  const createCargoLineAsync = promisifyAction(dispatch, createCargoLine)
  const updateCargoLineAsync = promisifyAction(dispatch, updateCargoLine)
  const deleteCargoLineAsync = promisifyAction(dispatch, deleteCargoLine)

  const { shipmentModality, organizationRole } = useSelector(
    (state: IGlobalState) => ({
      shipmentModality: state.shipmentOverview.modality,
      organizationRole: state.user.organizationRole,
    })
  )

  const isCustomer = organizationRole === OrganizationRoleEnum.Customer

  useEffect(() => {
    setCargoItems(
      convertCarrierBookingCargo(props.cargoItems, props.bookings || [])
    )
  }, [props.open])

  const saveCargo = async (item: IInitialCargoItem): Promise<any> => {
    setShowWarningMessage(false)
    try {
      const cargo = convertToProceed(item)
      if (item.edited && !item.new) {
        await updateCargoLineAsync(cargo.id, cargo)
      }

      if (item.new) {
        await createCargoLineAsync({
          ...cargo,
          container_id: props.containerId,
        })
      }

      props.fetchContainerData?.()
      return item
    } catch (err) {}
  }

  const saveCargoItems = async () => {
    let errors = {}

    for (const id of cargoItemsToDelete) {
      if (id) {
        await deleteCargoLineAsync(id)
        props.fetchContainerData?.()
      }
    }

    for (const item of cargoItems) {
      const error = cargoItemValidation(item, shipmentModality)
      if (!isEmpty(error)) {
        errors = { ...errors, [item.id]: error }
      } else if (requestCondition()) {
        await saveCargo(item)
      }
    }

    if (!isEmpty(errors)) {
      setCargoErrors(errors)
      return
    }

    props.onSave && props.onSave()
    onClose()
  }

  const onSaveCargoItems = async () => {
    if (showWarningMessage || isCustomer) {
      setShowMessageModal(true)
    } else {
      saveCargoItems()
    }
  }

  const onClose = () => {
    setCargoErrors({})
    setShowWarningMessage(false)
    setCargoItemsToDelete([])
    props.close()
  }

  const requestCondition = (): boolean => {
    const validations = cargoItems.map((cargoItem) => {
      return isEmpty(
        omitBy(cargoItemValidation(cargoItem, shipmentModality), (i) => !i)
      )
    })
    return uniq(validations).length === 1 ? uniq(validations)[0] : false
  }

  const addNewCargo = (): void => {
    setCargoItems((prev) => [...prev, initialCargoItem(props.bookings || [])])
  }

  const handleChangeCargo = ({ id, name, value }): void => {
    setCargoErrors(resolveErrors({ cargoErrors, id, name }))

    const { updatedCargoArray, showWarningMessage } = updateCargoArray(
      {
        id,
        name,
        value,
      },
      cargoItems,
      props.shouldShowWarningMessage
    )
    setShowWarningMessage(showWarningMessage)
    setCargoItems(updatedCargoArray)
  }

  const onClear = async (cargo) => {
    if (!cargo.new) {
      setCargoItemsToDelete([...cargoItemsToDelete, cargo.id])
    }
    if (cargoItems.length === 1) {
      setCargoItems([initialCargoItem(props.bookings || [])])
    } else {
      setCargoItems(
        cargoItems.filter((item: any) => String(item.id) !== String(cargo.id))
      )
    }
  }

  const filteredError = (props, name) => {
    const error = cargoErrors[props.row.id]
    return error
      ? {
          message: error[name],
          id: props.row.id,
        }
      : undefined
  }

  const columns = createCargoColumn(
    t,
    handleChangeCargo,
    filteredError,
    onClear,
    cargoItems,
    props.bookings || [],
    props.shipmentModality
  )

  return (
    <>
      <Dialog
        maxWidth="xl"
        title={
          props.cargoItems?.length
            ? t('shipment_containers.cargo_window.edit_goods', 'Edit goods')
            : t('shipment_containers.cargo_window.add_goods', 'Add goods')
        }
        onClose={onClose}
        open={props.open}
        actions={
          <>
            <Button
              variant="text"
              color="primary"
              size="large"
              onClick={onClose}
            >
              {t('common.buttons.cancel', 'Cancel')}
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={onSaveCargoItems}
            >
              {t('common.buttons.save', 'Save')}
            </Button>
          </>
        }
      >
        <DialogContent className={CargoWindowStyles().root}>
          <div>
            <Table loading={false} columns={columns} rows={cargoItems} />
            <div className="goods-select--add-line">
              <Button
                variant="text"
                color="primary"
                size="medium"
                onClick={addNewCargo}
                disabled={props.isCargoDisabled}
                startIcon={<AddRoundedIcon />}
              >
                {t(
                  'shipment_containers.cargo_window.add_more_goods',
                  'Add more goods'
                )}
              </Button>
            </div>
            {!isEmpty(cargoErrors) && (
              <Typography variant="body1" color="error" pl={2} pb={1}>
                {t(
                  'shipment_containers.cargo_window.please_complete_required_fields',
                  'Please complete all required fields to continue.'
                )}
              </Typography>
            )}
          </div>
        </DialogContent>
      </Dialog>

      {showMessageModal && (
        <CargoWindowWarningMessageModal
          onSave={() => {
            saveCargoItems()
            setShowMessageModal(false)
          }}
          onClose={() => setShowMessageModal(false)}
          open={showMessageModal}
        />
      )}
    </>
  )
}
