import { Chip } from '@mui/material'
import { chipColor } from 'src/config/constants'
import icons from 'src/pages/PickupsAndDeliveries/PickupsAndDeliveriesTable/TaskStatusSelect/mappings/icons'
import { getAllowedColumns } from './EditableCells/utils'
import EditableTask from './EditableCells/Task'

const OverviewTableTasksTask = ({ taskStatus, task, taskType }) => {
  const allowedColumns = getAllowedColumns() || {}
  const isEditable = allowedColumns?.['tasks.task_status_id']?.includes('edit')
  const Icon = icons?.[taskStatus.icon] ?? undefined
  const color = chipColor[taskStatus.color] ?? 'default'
  const label = taskStatus.name

  if (isEditable) {
    return (
      <EditableTask
        task={task}
        Icon={Icon}
        color={color}
        label={label}
        taskStatuses={taskType.statuses}
        currentTaskStatusId={taskStatus.id}
      />
    )
  }

  return <Chip label={taskStatus.name} color={color} icon={Icon} />
}

export default OverviewTableTasksTask
