import { Box, InputLabel } from '@mui/material'
import { useContext } from 'react'
import TextArea from 'src/stories/MUI/TextArea'
import { setProperty } from '../helpers'
import { FormContext } from '../index'

const ControlledTextArea = ({ name, label, minRows = 1, disabled = false }) => {
  const { instruction, setInstruction } = useContext(FormContext)

  const handleChange = (event) => {
    setInstruction(setProperty(instruction, name, event.target.value))
  }

  return (
    <Box>
      <InputLabel disabled={disabled} children={label} htmlFor={name} />
      <TextArea
        disabled={disabled}
        sx={{ minWidth: '100%' }}
        id={name}
        value={instruction[name] || ''}
        onChange={handleChange}
        minRows={minRows}
      />
    </Box>
  )
}

export default ControlledTextArea
