import { SyntheticEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { Controller, useFormContext } from 'react-hook-form'
import { Autocomplete, InputLabel, TextField } from '@mui/material'
import { useGetConnectionsAsync } from 'src/services/Api/common'
import BaseAutocompleteWrapper from 'src/components/Common/AutocompleteWrappers'
import { RenderedAutocompleteProps } from 'src/components/Common/AutocompleteWrappers/types'
import { getFormattedTransporterData } from '../EditableCells/utils'

const TransportOrderDialogFormTransporter = () => {
  const { t } = useTranslation()
  const { control } = useFormContext()

  return (
    <>
      <InputLabel required>
        {t('common.forms.fields.transporter.label', 'Transporter')}
      </InputLabel>
      <Controller
        control={control}
        name="transporter"
        render={({ field: { onChange, ...fieldProps } }) => (
          <BaseAutocompleteWrapper
            formatter={getFormattedTransporterData}
            fetchFunction={useGetConnectionsAsync}
            defaultValue={fieldProps.value ? [fieldProps.value] : []}
            params={{ role: 'transporter', per_page: '100' }}
            WrappedAutocompleteComponent={(
              props: RenderedAutocompleteProps
            ) => {
              return (
                <Autocomplete
                  fullWidth
                  loading={props.loading}
                  filterOptions={(x) => x}
                  options={props.options}
                  onInputChange={(
                    _event: SyntheticEvent<Element, Event>,
                    search: string
                  ) => {
                    props.onInputChange(search)
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={t('common.select', 'Select')}
                    />
                  )}
                  noOptionsText={props.noOptionsText}
                  onChange={(_event, newValue) => {
                    onChange(newValue)
                  }}
                  {...fieldProps}
                />
              )
            }}
          />
        )}
      />
    </>
  )
}

export default TransportOrderDialogFormTransporter
