import { EmptyState } from 'src/stories/MUI/EmptyState'
import { Link } from 'react-router-dom'
import {
  Check,
  RequestQuoteOutlined,
  BookmarkAddOutlined,
  LocalShipping,
  DirectionsBoat,
} from '@mui/icons-material'
import { t } from 'i18next'
import { Trans } from 'react-i18next'

export const NoTransactionItems = ({ shipmentId }) => {
  return (
    <EmptyState
      data-testid="no-transaction-items"
      badgeColor="primary"
      badgeIcon={RequestQuoteOutlined}
      title={t('shipment_costs.empty_state.title', 'No cost information')}
      description={
        <Trans
          i18nKey="shipment_costs.empty_state.helper_text"
          defaults="If there are any questions, contact us <0>via chat</0>."
          components={[
            <Link
              to={`/shipments/${shipmentId}/chats`}
              key="link-to-shipment-chat"
            />,
          ]}
        />
      }
    />
  )
}

export const NoUnreadNotifications = () => {
  return (
    <EmptyState
      button={{
        link: '/account/notifications',
        text: t(
          'top_navigation.notifications.empty_state.settings_button.text',
          'Go to notification settings'
        ),
      }}
      badgeColor="primary"
      badgeIcon={Check}
      title={t(
        'top_navigation.notifications.empty_state.title',
        'No unread notifications'
      )}
      description={t(
        'top_navigation.notifications.empty_state.description',
        'You can let us know what type of notifications you would like to receive by editing your preferences in your account settings.'
      )}
    />
  )
}

export const NoSavedMessages = () => {
  return (
    <EmptyState
      data-testid="no-saved-messages"
      badgeColor="primary"
      badgeIcon={BookmarkAddOutlined}
      title={t(
        'top_navigation.saved_messages.empty_state.title',
        'No saved messages'
      )}
      description={t(
        'top_navigation.saved_messages.empty_state.description',
        'You can save any message in a chat for later'
      )}
    />
  )
}

export const NoPickupDeliveriesScheduled = () => (
  <EmptyState
    badgeColor="primary"
    badgeIcon={LocalShipping}
    title={t(
      'common.empty_states.no_pickup_deliveries_scheduled.title',
      "Don't start the engine just yet"
    )}
    button={{
      text: t(
        'common.empty_states.no_pickup_deliveries_scheduled.button',
        'Book shipment'
      ),
      link: '/search',
    }}
    description={t(
      'common.empty_states.no_pickup_deliveries_scheduled.description',
      'There are no pickups and deliveries scheduled over the next week.'
    )}
  />
)

export const NoTasks = () => (
  <EmptyState
    badgeColor="primary"
    badgeIcon={Check}
    title={t('common.empty_states.no_tasks.title', "You've earned a slice")}
    button={{
      text: t('common.empty_states.no_tasks.button', 'Go to task settings'),
      link: '/account',
    }}
    description={t(
      'common.empty_states.no_tasks.description',
      'There are no tasks to show. Take a well-deserved break, have some cake or change your task settings for more.'
    )}
  />
)

export const NoShipments = () => (
  <EmptyState
    badgeColor="primary"
    badgeIcon={DirectionsBoat}
    title={t('common.empty_states.no_shipments.title', 'The horizon is clear')}
    button={{
      text: t('common.empty_states.no_shipments.button', 'Search & book'),
      link: '/search',
    }}
    description={t(
      'common.empty_states.no_shipments.description',
      'There are no shipments to show. You can create one in the Search & book page or enjoy the silence!'
    )}
  />
)
