import { useEffect } from 'react'
import Grid from '@mui/material/Grid2'
import { Typography, Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'
import DeliveryInfo from 'src/components/Common/DeliveryInfo'
import TransportOrderDialogFormStatus from './TransportOrderDialogFormStatus'
import TransportOrderDialogFormModality from './TransportOrderDialogFormModality'
import TransportOrderDialogFormAddresses from './TransportOrderDialogFormAddresses'
import TransportOrderDialogFormTransporter from './TransportOrderDialogFormTransporter'
import { getFormAddressDataFromInlandTransportAddressData } from './utils'

const RowActionMenuItemsTransportOrderDialogForm = ({
  service,
  inlandTransport,
}) => {
  const { t } = useTranslation()
  const { reset } = useFormContext()

  const setFormDefaultValues = () => {
    const addresses = getFormAddressDataFromInlandTransportAddressData(
      inlandTransport?.inland_transport_addresses ?? []
    )
    const defaultValues = {
      addresses,
      status: inlandTransport?.status ?? '',
      modality: inlandTransport?.modality ?? '',
      transporter: inlandTransport?.transporter_organization
        ? {
            value: inlandTransport.transporter_organization.id,
            label: inlandTransport.transporter_organization.name,
          }
        : null,
    }
    reset({ ...defaultValues })
  }

  useEffect(() => {
    setFormDefaultValues()
  }, [inlandTransport])

  return (
    <div>
      <Grid container spacing={3}>
        <Grid size="grow">
          <TransportOrderDialogFormTransporter />
        </Grid>
        <Grid size="grow">
          <TransportOrderDialogFormModality />
        </Grid>
        <Grid size="grow">
          <TransportOrderDialogFormStatus />
        </Grid>
      </Grid>
      {service === 'delivery' && (
        <Box mt={2}>
          <DeliveryInfo
            showDivider={false}
            modality={inlandTransport?.shipment.modality}
            loadType={inlandTransport?.shipment.load_type}
            deliveryAvailableFrom={
              inlandTransport?.shipment?.delivery_available_from
            }
            actualFirstDayOfDemurrage={
              inlandTransport?.container?.actual_first_day_of_demurrage
            }
            destinationDemurrageStartingFrom={
              inlandTransport?.shipment?.destination_demurrage_starting_from
            }
          />
        </Box>
      )}
      <Typography
        mt={3}
        variant="h6"
        children={t('transport_order_form.headers.addresses', 'Addresses')}
      />
      <Typography
        mt={1}
        children={t(
          'transport_order_form.helper_texts.addresses',
          'Pickup and delivery addresses are mandatory. Drag and drop to order addresses.'
        )}
      />
      <TransportOrderDialogFormAddresses
        service={service}
        shipmentOwnerOrganization={
          inlandTransport?.shipment.owner_organization.id
        }
      />
    </div>
  )
}

export default RowActionMenuItemsTransportOrderDialogForm
