import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Paper from '@mui/material/Paper'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import { ModalityEnum } from 'src/config/constants'
import { TimelinePortDate } from '../TimelinePortDate'
import { TimelineFlightNumber } from '../TimelineFlightNumber'
import TimelineActivePoint from '../TimelineActivePoint'
import TimelineProgressSection from '../TimelineProgressSection'

import {
  TimelineLegsProps,
  TimelineLegsLegDetailProps,
} from './TimelineLegs.props'
import { getLegItems } from './TimelineLegs.utils'

const detailItems: TimelineLegsLegDetailProps[] = [
  { key: 'eta', type: 'arrival' },
  { key: 'etd', type: 'departure' },
]

const TimelineLegs: React.FC<TimelineLegsProps> = ({
  shipment,
  totalPhaseCount,
  percentagePerPhase,
  percentageForFirstPhase,
  publicShipmentLegs,
}) => {
  const {
    modality,
    shipment_legs: shipmentLegs,
    status_sequence: statusSequence,
  } = shipment

  const { t } = useTranslation()

  const legs = shipmentLegs || publicShipmentLegs
  const legItems = getLegItems(legs, legs.length)

  const portPrefix = modality === ModalityEnum.Sea ? 'Port of ' : ''

  return (
    <>
      {legItems.map((leg, index) => {
        const threshold =
          percentageForFirstPhase + percentagePerPhase * (index + 1)
        return (
          <div key={leg.id} className="shipment-timeline--phases-item">
            <div className="shipment-timeline--phases-item--progress-background" />
            <TimelineProgressSection
              totalPhaseCount={totalPhaseCount}
              percentage={shipment.progress_percent}
              threshold={threshold}
            />
            <div
              className={`shipment-timeline--phases-item--point ${
                shipment.progress_percent > threshold ? 'completed' : ''
              }`}
            ></div>
            <TimelineActivePoint
              type={shipment.shipment_type}
              percentage={shipment.progress_percent}
              threshold={threshold}
              status={shipment.humanized_status}
              vessel={shipment.vessel}
              totalPhaseCount={totalPhaseCount}
              isPublicShipment={!!publicShipmentLegs}
            />
            <div className="shipment-timeline--phases-item-title">
              <Typography children={`${portPrefix}${leg.port}`} variant="h6" />
              <Paper variant="elevation">
                <Stack
                  p={2}
                  spacing={1}
                  divider={<Divider orientation="horizontal" flexItem />}
                  className="shipment-timeline--phases-item-wrapper"
                >
                  {detailItems.map(
                    (item: TimelineLegsLegDetailProps, currentIndex) => (
                      <Box
                        key={item.key}
                        className="shipment-timeline--phases-item-leg-details"
                      >
                        <TimelinePortDate
                          modality={modality}
                          date={leg[item.key]}
                          showInfoTooltip={currentIndex === 0}
                          label={t(
                            `shipment.timeline.port_date.${item.key}`,
                            item.key.toUpperCase()
                          )}
                          statusSequence={statusSequence}
                          shipment={shipment}
                        />
                        <TimelineFlightNumber
                          modality={modality}
                          flightNumber={leg[`${item.type}FlightNumber`]}
                        />
                      </Box>
                    )
                  )}
                </Stack>
              </Paper>
            </div>
          </div>
        )
      })}
    </>
  )
}

export default TimelineLegs
