import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

const ShipmentInfoBlock = ({ instruction }) => {
  const { t } = useTranslation()
  return (
    <>
      <Typography variant="h6" gutterBottom>
        {t(
          'shipping_instructions.headers.shipment_information',
          'Shipment information'
        )}
      </Typography>
      <Box
        sx={{
          border: '1px solid var(--grey-200)',
          p: 2,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box>
          {t('shipping_instructions.sub_headers.pol', 'Port of loading:')}{' '}
          {instruction.port_of_loading}
        </Box>
        <Box>
          {t('shipping_instructions.sub_headers.vessel', 'Vessel:')}{' '}
          {instruction.vessel_name}
        </Box>
        <Box>
          {t('shipping_instructions.sub_headers.voyage', 'Voyage:')}{' '}
          {instruction.voyage_flight_no}
        </Box>
        <Box>
          {t('shipping_instructions.sub_headers.pod', 'Port of discharge:')}{' '}
          {instruction.port_of_discharge}
        </Box>
      </Box>
    </>
  )
}

export default ShipmentInfoBlock
