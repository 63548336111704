import { createReducer, Reducer } from 'redux-create-reducer'

import { AnyAction } from 'redux'
import uuidv4 from 'uuid/v4'
import * as actions from '../actions/shipments'

export const initialCargoItem = (bookings) => ({
  id: uuidv4(),
  volume: 1,
  total_weight_kg: '',
  total: 1,
  quantity: 1,
  goods_description: '',
  marks_and_numbers: null,
  total_volume_weight: null,
  total_volume_cbm: 0,
  packageType: { id: 9, code: 'PK', name: 'Package' },
  new: true,
  edited: false,
  length: 0,
  width: 0,
  height: 0,
  booking_id: bookings.length === 1 ? bookings[0].id : null,
})

const defaultFilters = {
  initial: true,
  scope: 'booked',
  shipmentsPerPage: '20',
  page: '1',
  my_shipments: window.localStorage.getItem('shipmentsMyShipments') === 'true',
  sortBy: 'newest',
}

export const initialFilters: IShipmentFilters = {
  ...defaultFilters,
  search: null,
  consignees: [],
  shippers: [],
  contacts: [],
  pods: [],
  pols: [],
  types: [],
  modalities: [],
  missing_information: [],
  track_trace_statuses: [],
  customers: [],
  agents: [],
  statuses: [],
  suppliers: [],
  arrivalDateStart: null,
  arrivalDateEnd: null,
  departureDateStart: null,
  departureDateEnd: null,
  visibility_only: [],
  users: [],
}

export const convertToProceed = (cargo: IInitialCargoItem) => ({
  id: cargo.id,
  quantity: cargo.quantity,
  length_mm: (cargo.length || 0) * 10,
  width_mm: (cargo.width || 0) * 10,
  height_mm: (cargo.height || 0) * 10,
  total_weight_kg: cargo.total_weight_kg,
  total_volume_weight: cargo.total_volume_weight,
  goods_description: cargo.goods_description,
  volume_cbm: cargo.total_volume_cbm,
  marks_and_numbers: cargo.marks_and_numbers,
  package_type_id: cargo.packageType.id,
  stackable: cargo.stackable || false,
  booking_id: cargo.booking_id,
})

export const convertToNewFormat = (cargo) => ({
  id: cargo.id,
  created_at: cargo.created_at,
  quantity: cargo.quantity,
  stackable: cargo.stackable || false,
  length: cargo.length_mm / 10,
  lengthError: '',
  width: cargo.width_mm / 10,
  widthError: '',
  height: cargo.height_mm / 10,
  heightError: '',
  total_weight_kg: cargo.total_weight_kg,
  total_volume_cbm: cargo.volume_cbm,
  weightError: '',
  packageType: cargo.package_type,
  goods_description: cargo.goods_description,
  isCargoDisabled: cargo.isCargoDisabled as boolean,
  booking_id: cargo.booking_id,
})

export const initialQuickFilter: ShipmentQuickFilter = {
  id: null,
  name: '',
  page: 'shipments',
  filter: initialFilters,
  user_id: undefined,
}

export const initialShipmentsFilterOptions: IShipmentFilterOptions = {
  pols: [],
  pods: [],
  carriers: [],
  statuses: [],
  types: [],
  consignees: [],
  shippers: [],
  contacts: [],
  notify_parties: [],
  modalities: [],
  missing_information: [],
  customers: [],
  track_trace_statuses: [],
  agents: [],
  suppliers: [],
  scope_statuses: {
    booked: [],
    in_transit: [],
    delivered: [],
    all: [],
    cancelled: [],
    assignable: [],
  },
  saved_filters: [],
  users: [],
  isLoading: true,
}

export const initialShipmentsState: IShipmentsState = {
  shipmentStatuses: [],
  shipments: [],
  carriers: [],
  shipmentsToAssignPO: null,
  isFetchingShipmentsToAssign: false,
  countsPerScope: {
    booked: 0,
    in_transit: 0,
    delivered: 0,
    all: 0,
    cancelled: 0,
    flagged: 0,
  },
  filterOptions: initialShipmentsFilterOptions,
  filters: initialFilters,
  isLoading: true,
  totalCount: 0,
  scopeParams: null,
  exportFile: null,
  timeline: null,
  shipmentParties: [],
  tabClicks: 0,
  connections: [],
  singleConnection: {
    last_seen: null,
    logo: null,
    name: '',
    organization_id: 0,
    role: '',
    role_code: '',
    url: '',
    country: null,
    users: [],
    manageable: false,
    addresses: [],
    is_preferred_forwarder: false,
  },
  isFetchingConnections: false,
  organizationRoles: [],
  singleVisibilityShipment: null,
  visibilityShipmentForm: {
    container_number: '',
    booking_number: '',
    bl_number: '',
    shared_reference: '',
    carrier: null,
    customer: null,
  },
  visibilityShipmentFormErrors: {},
}

const retrieveShipmentData: Reducer<IShipmentsState, AnyAction> = (
  state,
  { payload }
) => ({
  ...state,
  shipments: payload.shipments,
  totalCount: payload.total_count,
  isLoading: false,
})

const retrieveShipmentMetaData: Reducer<IShipmentsState, AnyAction> = (
  state,
  { payload }
) => ({
  ...state,
  countsPerScope: payload.scope_counts,
  exportFile: payload.export,
})

const requestExcel: Reducer<IShipmentsState, AnyAction> = (
  state,
  { payload }
) => ({
  ...state,
  exportFile: payload.export,
})

const getVisibilityShipmentCarriers: Reducer<IShipmentsState, AnyAction> = (
  state,
  action
) => ({
  ...state,
  carriers: action.payload,
})

const setExportFile: Reducer<IShipmentsState, AnyAction> = (state, action) => ({
  ...state,
  exportFile: action.payload,
})

const changeScopeParams: Reducer<IShipmentsState, AnyAction> = (
  state,
  action
) => ({
  ...state,
  scopeParams: action.payload,
})

const retrieveShipmentToAssignPO: Reducer<IShipmentsState, AnyAction> = (
  state,
  { payload }
) => ({
  ...state,
  shipmentsToAssignPO: payload.shipments,
  isFetchingShipmentsToAssign: false,
})

const receiveShipmentParties: Reducer<IShipmentsState, AnyAction> = (
  state,
  { payload }
) => ({
  ...state,
  shipmentParties: payload,
})

const receiveOrganizationRoles: Reducer<IShipmentsState, AnyAction> = (
  state,
  { payload }
) => ({
  ...state,
  organizationRoles: payload,
})

const receiveShipmentTimeline: Reducer<IShipmentsState, AnyAction> = (
  state,
  { payload }
) => ({
  ...state,
  timeline: payload,
})

const clearShipmentTimeline: Reducer<IShipmentsState, AnyAction> = (state) => ({
  ...state,
  timeline: null,
})

const cleanShipmentToAssignPO: Reducer<IShipmentsState, AnyAction> = (
  state
) => ({
  ...state,
  shipmentsToAssignPO: null,
})

const setFetchingShipmentsToAssign: Reducer<IShipmentsState, AnyAction> = (
  state
) => ({
  ...state,
  isFetchingShipmentsToAssign: true,
})

const forceClickTab: Reducer<IShipmentsState, AnyAction> = (state) => ({
  ...state,
  tabClicks: state.tabClicks + 1,
})

const retrieveShipmentStatuses: Reducer<IShipmentsState, AnyAction> = (
  state,
  { payload }
) => ({
  ...state,
  shipmentStatuses: payload,
})

const updateFilters: Reducer<IShipmentsState, AnyAction> = (state, action) => ({
  ...state,
  filters: {
    ...action.payload,
    initial: false,
  },
  isLoading: true,
})

const setFiltersInitial: Reducer<IShipmentsState, AnyAction> = (state) => ({
  ...state,
  filters: { ...initialFilters },
})

const setSingleVisibilityShipment: Reducer<IShipmentsState, AnyAction> = (
  state,
  action
) => ({
  ...state,
  singleVisibilityShipment: action.payload,
})

const updateShipmentForm: Reducer<IShipmentsState, AnyAction> = (
  state,
  action
) => ({
  ...state,
  visibilityShipmentForm: {
    ...state.visibilityShipmentForm,
    ...action.payload,
  },
})

const updateShipmentFormErrors: Reducer<IShipmentsState, AnyAction> = (
  state,
  action
) => ({
  ...state,
  visibilityShipmentFormErrors: {
    ...state.visibilityShipmentFormErrors,
    ...action.payload,
  },
})

export default createReducer(initialShipmentsState, {
  [actions.GET_SHIPMENT_STATUSES_SUCCESS]: retrieveShipmentStatuses,
  [actions.SHIPMENTS_GET_META_DATA_SUCCESS]: retrieveShipmentMetaData,
  [actions.SHIPMENTS_GET_DATA_SUCCESS]: retrieveShipmentData,
  [actions.SCOPE_PARAMS_CHANGE]: changeScopeParams,
  [actions.SET_EXPORT_FILE]: setExportFile,
  [actions.SHIPMENTS_TO_ASSIGN_PO_GET]: setFetchingShipmentsToAssign,
  [actions.SHIPMENTS_TO_ASSIGN_PO_CLEAN]: cleanShipmentToAssignPO,
  [actions.SHIPMENTS_TO_ASSIGN_PO_GET_SUCCESS]: retrieveShipmentToAssignPO,
  [actions.SHIPMENTS_GET_PARTIES_SUCCESS]: receiveShipmentParties,
  [actions.SHIPMENTS_GET_ORGANIZATION_ROLES_SUCCESS]: receiveOrganizationRoles,
  [actions.SHIPMENTS_GET_TIMELINE_SUCCESS]: receiveShipmentTimeline,
  [actions.SHIPMENTS_CLEAR_TIMELINE]: clearShipmentTimeline,
  [actions.SHIPMENTS_FORCE_CLICK_TAB]: forceClickTab,
  [actions.SHIPMENTS_UPDATE_FILTERS]: updateFilters,
  [actions.SHIPMENTS_SET_FILTERS_INITIAL]: setFiltersInitial,
  [actions.SHIPMENTS_REQUEST_EXCEL_SUCCESS]: requestExcel,
  [actions.SHIPMENTS_UPDATE_SHIPMENT_FORM]: updateShipmentForm,
  [actions.SHIPMENTS_UPDATE_SHIPMENT_FORM_ERRORS]: updateShipmentFormErrors,
  [actions.GET_VISIBILITY_SHIPMENT_CARRIERS_SUCCESS]: getVisibilityShipmentCarriers,
  [actions.CREATE_VISIBILITY_SHIPMENT_SUCCESS]: setSingleVisibilityShipment,
  [actions.SET_VISIBILITY_SHIPMENT]: setSingleVisibilityShipment,
})
