import { FunctionComponent, useState, useEffect } from 'react'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { updateUrl } from 'src/components/Filters/utils'
import { Typography } from '@mui/material'
import { useHistory } from 'react-router'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { useTranslation } from 'react-i18next'
import FilterListIcon from '@mui/icons-material/FilterList'
import { shipmentsUpdateFilters } from 'src/stores/actionCreators'
import ShipmentVisibilityModal from 'src/components/ShipmentVisibilityModal'
import ShipmentServicesWindow from 'src/components/ShipmentTimeline/ShipmentServicesWindow'
import Buttons from 'src/components/Common/Buttons'
import WatchShipmentsToggler from 'src/components/Common/WatchShipmentsToggler'
import { permissionTo } from '../../../utils'
import '../styles.scss'

const ShipmentsHeader: FunctionComponent<{
  onFiltersVisibilityToggle: () => void
  showAllFilters: boolean
}> = ({ onFiltersVisibilityToggle, showAllFilters }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const [showServices, setShowServices] = useState<boolean>(false)

  const { visibilityTrial, myShipments, singleShipment, filters } = useSelector(
    (state: IGlobalState) => ({
      visibilityTrial: state.user.organization_visibility_trial,
      myShipments: state.shipments.filters.my_shipments,
      singleShipment: state.shipments.singleVisibilityShipment,
      filters: state.shipments.filters,
    }),
    shallowEqual
  )

  useEffect(() => {
    if (
      singleShipment &&
      !JSON.parse(localStorage.getItem('hideServiceWindow') || 'false')
    ) {
      setShowServices(true)
    }
  }, [singleShipment, localStorage])

  const isShipmentCreationActive = window.location.pathname.includes(
    '/shipments/new'
  )

  const createShipmentModalOpen = visibilityTrial && isShipmentCreationActive

  const [modalOpen, setModalOpen] = useState(createShipmentModalOpen)

  const handleOpenModal = () => setModalOpen(true)

  const handleWatchedShipments = (newValue: boolean): void => {
    localStorage.setItem('shipmentsMyShipments', JSON.stringify(newValue))
    dispatch(shipmentsUpdateFilters({ ...filters, my_shipments: newValue }))
    updateUrl('shipments', { ...filters, my_shipments: newValue })
  }

  const hideServicesWindow = () => {
    setShowServices(false)
  }

  const handleFilterVisibilityChange = () => {
    onFiltersVisibilityToggle()
  }

  const onModalOpen = (state) => {
    if (!state && isShipmentCreationActive) {
      history.push({
        pathname: '/shipments',
      })
    }
    setModalOpen(state)
  }

  return (
    <header className="shipments-page__header" data-testid="shipments-header">
      <div className="shipments-page__header-left-group">
        <Typography variant="h3" children={t('shipments.title', 'Shipments')} />
      </div>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          height: '100%',
        }}
        className="shipments-page__button-group"
      >
        <Box mr={1}>
          <WatchShipmentsToggler
            value={`${myShipments}` === 'true'}
            action={handleWatchedShipments}
          />
        </Box>
        {permissionTo('your_shipments.export.all') && (
          <Buttons.PopupButton
            bodyType="export"
            buttonText={t('common.buttons.download', 'Download')}
            data-testid="shipment-export"
          />
        )}
        <Button
          variant={showAllFilters ? 'contained' : 'outlined'}
          onClick={handleFilterVisibilityChange}
          startIcon={<FilterListIcon />}
          sx={{ verticalAlign: 'text-bottom', ml: 1 }}
        >
          {t('common.buttons.filter', 'Filter')}
        </Button>
        {permissionTo('search_and_book.all') && visibilityTrial && (
          <Box ml={1}>
            <Button variant="contained" size="large" onClick={handleOpenModal}>
              {t('common.buttons.add_shipment', 'Add shipment')}
            </Button>
          </Box>
        )}
      </Box>
      {modalOpen && (
        <ShipmentVisibilityModal
          open={modalOpen}
          handleModalOpen={onModalOpen}
        />
      )}
      <ShipmentServicesWindow
        open={showServices}
        shipmentId={singleShipment?.id}
        onClose={hideServicesWindow}
        defaultServices={[]}
        title={t('add_shipment_services.title')}
        header={t('add_shipment_services.description', {
          shipment: singleShipment?.title,
        })}
        showSwitch={true}
      />
    </header>
  )
}

export default ShipmentsHeader
