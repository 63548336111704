import {
  useGetServiceDepartmentsAsync,
  serviceDepartmentsResponse,
} from 'src/services/Api/serviceDepartments'
import AutocompleteAsyncBase from './AutocompleteAsyncBase'

const ServiceDepartmentAutocompleteAsync = ({ token, onDelete }) => {
  const getOptions = (data: serviceDepartmentsResponse) => {
    return (
      data?.map((sd) => ({
        label: sd[0],
        value: sd[1],
      })) ?? []
    )
  }
  return (
    <AutocompleteAsyncBase
      token={token}
      onDelete={onDelete}
      formatter={getOptions}
      fetchFunction={useGetServiceDepartmentsAsync}
    />
  )
}

export default ServiceDepartmentAutocompleteAsync
