import { Button, Tooltip } from '@mui/material'
import { SyntheticEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useUpdateInlandTransport } from 'src/services/Api/overview'
import BaseAutocompleteWrapper from 'src/components/Common/AutocompleteWrappers'
import { RenderedAutocompleteProps } from 'src/components/Common/AutocompleteWrappers/types'
import AutocompleteBasePopper from 'src/components/Overview/Search/FormElements/AutocompleteBasePopper'
import { customFields, tableCellMutationKey } from '../../constants'

const BulkEditBarAsyncSelect = ({
  label,
  formatter,
  fetchParams,
  fetchFunction,
  updateParams,
  containerIds,
  optionKey = 'label',
  updateField = 'transporter_organization_id',
  buttonDisabled = false,
  buttonTooltip = '',
}) => {
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const id = open ? 'bulk-edit-async-select' : undefined

  const {
    mutateAsync: updateInlandTransport,
    isLoading: isUpdating,
  } = useUpdateInlandTransport({
    retry: 0,
  })

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    if (anchorEl) {
      anchorEl.focus()
    }
    setAnchorEl(null)
  }

  const handleChange = (_event, newValue) => {
    handleClose()
    updateInlandTransport({
      mutationKey: [tableCellMutationKey],
      params: {
        custom_fields: customFields,
        inland_transports: containerIds.map((id: string) => ({
          container_id: id,
          ...updateParams,
          [updateField]: newValue?.value,
        })),
      },
    })
  }

  return (
    <>
      <Tooltip title={buttonDisabled ? buttonTooltip : null} placement="top">
        <span>
          <Button
            disableRipple
            loading={isUpdating}
            aria-describedby={id}
            onClick={handleClick}
            disabled={buttonDisabled}
            data-testid="bulk-edit-async-select-button"
          >
            {label}
          </Button>
        </span>
      </Tooltip>
      <BaseAutocompleteWrapper
        formatter={formatter}
        fetchFunction={fetchFunction}
        defaultValue={[]}
        fetchOnMount={true}
        params={fetchParams}
        open={open}
        WrappedAutocompleteComponent={(props: RenderedAutocompleteProps) => {
          return (
            <AutocompleteBasePopper
              id={id}
              open={open}
              onClose={handleClose}
              value={null}
              anchorEl={anchorEl}
              multiple={false}
              popperMaxWidth={300}
              onSelectChange={handleChange}
              loading={props.loading}
              onInputChange={(
                _event: SyntheticEvent<Element, Event>,
                search: string
              ) => {
                props.onInputChange(search)
              }}
              noOptionsText={props.noOptionsText}
              options={props.options}
              optionKey={optionKey}
              asynchronous={true}
              selectAll={false}
              inputPlaceholder={t(
                'common.select.type_to_search',
                'Type to find'
              )}
            />
          )
        }}
      />
    </>
  )
}

export default BulkEditBarAsyncSelect
