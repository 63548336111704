import { useState } from 'react'
import delay from 'lodash/delay'
import Stack from '@mui/material/Stack'
import { IconButton, Zoom } from '@mui/material'
import DoneIcon from '@mui/icons-material/Done'
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined'
import OverviewTableShipmentLink from './OverviewTableShipmentLink'

const OverviewTableContainerNumber = ({ text, id }) => {
  const [isCopied, setIsCopied] = useState(false)
  const [isHovered, setIsHovered] = useState(false)

  const onClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation()
    navigator.clipboard?.writeText(text ?? '').then(() => {
      setIsCopied(true)
    })
    delay(() => {
      setIsCopied(false)
    }, 2000)
  }

  const onMouseEnter = () => {
    setIsHovered(true)
  }

  const onMouseLeave = () => {
    setIsHovered(false)
  }

  return (
    <Stack
      spacing={1}
      direction="row"
      onClick={onClick}
      alignItems="center"
      justifyContent="start"
      data-testid="container-number"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      sx={{ width: '100%' }}
    >
      <OverviewTableShipmentLink id={id} text={text} />
      {text && (
        <Zoom in={isHovered}>
          <IconButton
            size="small"
            data-testid="copy-container-button"
            color="primary"
          >
            {isCopied && (
              <Zoom in={isCopied}>
                <DoneIcon data-testid="copy-container-button-done-icon" />
              </Zoom>
            )}
            {!isCopied && (
              <Zoom in={!isCopied}>
                <FileCopyOutlinedIcon data-testid="copy-container-button-file-icon" />
              </Zoom>
            )}
          </IconButton>
        </Zoom>
      )}
    </Stack>
  )
}

export default OverviewTableContainerNumber
