import { useState } from 'react'
import Stack from '@mui/material/Stack'
import { useTranslation } from 'react-i18next'
import { IconButton, Zoom } from '@mui/material'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { permissionTo } from 'src/utils'
import OverviewTableShipmentLink from './OverviewTableShipmentLink'

const OverviewTableShipmentNumber = ({ text, id }) => {
  const { t } = useTranslation()
  const [isHovered, setIsHovered] = useState(false)

  const onMouseEnter = () => {
    setIsHovered(true)
  }

  const onMouseLeave = () => {
    setIsHovered(false)
  }

  return (
    <Stack
      spacing={1}
      direction="row"
      alignItems="center"
      justifyContent="start"
      sx={{ width: '100%' }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      data-testid="shipment-number"
    >
      <OverviewTableShipmentLink id={id} text={text} />
      {text && permissionTo('pickups_deliveries.shypple_admin') && (
        <Zoom in={isHovered}>
          <IconButton
            size="small"
            color="primary"
            target="_blank"
            data-testid="redirect-to-back-office-button"
            title={t('common.tooltips.view_in_cortana', 'View in Cortana')}
            href={`${window.shyppleConfig.apiUrl}/admin/shipments/${id}/organizations?autoscope=true`}
          >
            <OpenInNewIcon data-testid="redirect-to-back-office-icon" />
          </IconButton>
        </Zoom>
      )}
    </Stack>
  )
}

export default OverviewTableShipmentNumber
